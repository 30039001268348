<template>
    <ModalBasic
        :title="getFromDictionary(`list.modal.type.remove`, 'title')"
        :modal-open="state.modals.removeFromList.isOpen"
        @close-modal="state.modals.removeFromList.isOpen = false"
    >
        <div>
            <div class="px-5 py-4">
                <div class="text-sm">
                    <div class="mb-3">
                        <div class="my-4">
                            <p>
                                Are you sure you want to remove the
                                <span class="font-semibold">{{ $lodash.size(selectedCreators) }}</span> creators from
                                this list?
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="state.modals.removeFromList.isOpen = false"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-rose-500 text-white hover:bg-rose-600" @click="onRemoveFromList">
                        Remove
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>

    <div class="rounded-sm border border-slate-200 bg-white">
        <header class="relative h-14 border-b border-slate-200 px-3">
            <div v-show="!$lodash.size(selectedCreators)" class="flex items-center gap-2 py-2.5">
                <div class="relative mt-auto flex grow gap-2">
                    <DropdownMultiSelect
                        :options="columns"
                        :selected-ids="enabledColumns"
                        text-field="header.label"
                        @selection-change="onColumnChange"
                    >
                        <div class="flex">
                            <div
                                class="btn flex cursor-pointer overflow-hidden rounded border-slate-200 bg-slate-50 uppercase shadow-none hover:border-slate-300 hover:bg-slate-100"
                            >
                                <div class="flex">
                                    <font-awesome-icon :icon="['fas', 'line-columns']" class="my-auto h-3.5 w-3.5" />
                                    <div class="ml-2 flex text-xs font-semibold">
                                        <span v-if="$lodash.size(columns)"
                                            >{{ $lodash.size(enabledColumns) }} columns</span
                                        >
                                        <span v-else>Custom columns</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DropdownMultiSelect>

                    <DropdownMultiSort :sorts="paging.sort" :columns="columns" @new-sorts="onNewSort">
                        <div class="flex">
                            <div
                                class="btn flex cursor-pointer overflow-hidden rounded border-slate-200 bg-slate-50 uppercase shadow-none hover:border-slate-300 hover:bg-slate-100"
                            >
                                <div class="flex">
                                    <font-awesome-icon
                                        :icon="['fas', 'arrow-up-arrow-down']"
                                        class="my-auto h-3.5 w-3.5"
                                    />
                                    <div class="ml-1.5 flex text-xs font-semibold">
                                        <span v-if="$lodash.size(paging.sort)"
                                            >{{ $lodash.size(paging.sort) }} sorts</span
                                        >
                                        <span v-else>Custom Sort</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DropdownMultiSort>
                </div>

                <slot name="header-toolbar"> </slot>

                <!-- <div
                    v-if="isDevModeEnabled"
                    :class="{
                        'pointer-events-none opacity-50': !creators || creators.length === 0,
                    }"
                >
                    <button class="btn-sm rounded bg-indigo-500 text-white hover:bg-indigo-600 py-1.5" @click="exportCreators">
                        <span v-if="!paging.totalRecords"> Export Top 1,000 </span>
                        <span v-else>
                            Export
                            {{
                                paging.totalRecords > 5000
                                    ? $numeral(5000).format('0,0')
                                    : $numeral(paging.totalRecords).format('0,0')
                            }}
                            creators
                        </span>
                    </button>
                </div> -->
            </div>

            <div v-if="$lodash.size(selectedCreators) > 0">
                <div class="my-auto flex gap-2 px-2 py-3.5">
                    <div class="mr-1 flex cursor-pointer text-slate-500 hover:text-slate-900" @click="onOptionsClose">
                        <font-awesome-icon :icon="['fas', 'xmark']" class="my-auto h-4 w-4 shrink-0 fill-current" />
                    </div>

                    <template v-if="selfActiveOrganization">
                        <button
                            class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="
                                $store.commit('modal:setData', {
                                    modal_id: 'add_creator_to_modal',
                                    data: {
                                        is_open: true,
                                        platform_id: platformId,
                                        superviews: $lodash.map(selectedCreators, superviewPath),
                                    },
                                })
                            "
                        >
                            Add selected to... ({{ $lodash.size(selectedCreators) }})
                        </button>
                        <button
                            v-if="listId"
                            class="btn btn-sm bg-red-500 text-white hover:bg-red-600"
                            @click="state.modals.removeFromList.isOpen = true"
                        >
                            Remove Selected from list ({{ $lodash.size(selectedCreators) }})
                        </button>

                        <button
                            v-if="listId && checkPermission('module.messages')"
                            class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="onSendMessages"
                        >
                            Send message to {{ $lodash.size(selectedCreators) }} creators
                        </button>
                    </template>
                </div>
            </div>
        </header>

        <div>
            <div
                ref="tableWrapper"
                class="overflow-auto overflow-y-auto scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-300 scrollbar-corner-slate-100 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-400 active:scrollbar-thumb-slate-500 md:max-h-[calc(100vh-64px-32px-58px-48px-40px)]"
            >
                <table class="w-full table-auto">
                    <thead
                        class="sticky left-0 top-0 z-20 rounded-sm bg-slate-50 text-left"
                        :class="{
                            'pointer-events-none cursor-default': isLoading === true,
                        }"
                    >
                        <tr class="h-12">
                            <th
                                v-if="isSelectable"
                                class="sticky left-0 top-0 z-10 w-10 min-w-10 bg-slate-50 first:pl-5 last:pr-5"
                            >
                                <div v-if="isLoading === false" class="flex w-full">
                                    <input
                                        type="checkbox"
                                        class="form-checkbox my-auto"
                                        :checked="isAllPageCheckboxSelected"
                                        @click="onAllPageCheckboxClick"
                                    />
                                </div>
                            </th>
                            <th
                                v-for="column in visibleColumns"
                                :key="`header-column-${column.key}`"
                                class="group relative whitespace-nowrap p-0"
                                :class="{
                                    'sticky left-10 top-0 z-10 bg-slate-50': column?.is_sticky,
                                    [column?.header?.class]: !!column?.header?.class,
                                    'cursor-pointer hover:bg-slate-200':
                                        column.header.is_sortable === true || column.header.is_hideable !== false,
                                }"
                            >
                                <DropdownTableHeader
                                    :is-sortable="column.header.is_sortable"
                                    :is-hideable="column.header.is_hideable"
                                    :column-id="column.id"
                                    :sort-direction="sortLookup[column.id]"
                                    @action="onHeaderColumnAction"
                                >
                                    <div class="flex flex-grow whitespace-nowrap p-2">
                                        <Tooltip v-if="column.header.tooltip" class="my-auto mr-2 box-content">
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-4 w-4 text-slate-400 transition duration-200 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="font-bold">
                                                    <h5 class="text-lg text-white">
                                                        {{ column.header.tooltip.title }}
                                                    </h5>
                                                    <div
                                                        v-if="column.header.sub_label"
                                                        class="mb-3 text-xs font-bold leading-none text-slate-200"
                                                    >
                                                        {{ column.header.tooltip.sub_title }}
                                                    </div>
                                                    <div class="space-y-2">
                                                        <p
                                                            v-for="(paragraph, index) in column.header.tooltip.content"
                                                            :key="`paragraph-${index}`"
                                                            class="text-xs text-slate-300"
                                                            :class="{
                                                                [paragraph.class]: !!paragraph.class,
                                                            }"
                                                        >
                                                            {{ paragraph.text }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <div class="my-auto flex flex-col">
                                            <span class="text-xs font-semibold uppercase leading-none text-slate-500">
                                                {{ column.header.label }}
                                            </span>
                                            <span
                                                v-if="column.header.sub_label"
                                                class="text-[10px] font-bold leading-none text-slate-400"
                                            >
                                                {{ column.header.sub_label }}
                                            </span>
                                        </div>

                                        <div v-if="column.header.is_sortable" class="ml-auto flex">
                                            <div class="my-auto ml-2 flex flex-col">
                                                <font-awesome-icon
                                                    :icon="['fas', 'chevron-up']"
                                                    class="-mb-1 h-2.5 w-2.5 opacity-25"
                                                    :class="{
                                                        'text-indigo-600 !opacity-100':
                                                            sortLookup[column.id] && sortLookup[column.id] === 'asc',
                                                        'group-hover:text-indigo-600 group-hover:!opacity-100':
                                                            sortLookup[column.id] && sortLookup[column.id] === 'desc',
                                                    }"
                                                />

                                                <font-awesome-icon
                                                    :icon="['fas', 'chevron-down']"
                                                    class="h-2.5 w-2.5 opacity-25 group-hover:text-slate-600 group-hover:!opacity-100"
                                                    :class="{
                                                        'text-indigo-600 !opacity-100':
                                                            sortLookup[column.id] && sortLookup[column.id] === 'desc',
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DropdownTableHeader>
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        v-if="isLoading === false"
                        class="divide-y divide-slate-200 text-sm"
                        :class="{
                            'pointer-events-none opacity-50': isBusy === true,
                        }"
                    >
                        <tr v-for="creator in creators" :key="creator[idProp]" class="group h-12">
                            <td
                                v-if="isSelectable"
                                class="sticky left-0 top-0 z-10 w-10 min-w-10 bg-white first:pl-5 last:pr-5"
                            >
                                <input
                                    :checked="selectedCreators[creator[idProp]]"
                                    type="checkbox"
                                    class="form-checkbox"
                                    :value="creator"
                                    @input="onSelectCreator(creator, $event)"
                                />
                            </td>

                            <td
                                v-for="column in visibleColumns"
                                :key="`body-column-${column.key}`"
                                class="whitespace-nowrap bg-white px-2 first:pl-5 last:pr-5"
                                :class="{
                                    'sticky left-10 top-0 z-10': column?.is_sticky,
                                    [column?.row?.class]: !!column?.row?.class,
                                }"
                            >
                                <div class="flex">
                                    <template v-if="column?.row?.slot">
                                        <!-- If we have a slot transformer, apply it to the input, if not just fetch the value at key -->
                                        <slot
                                            :name="column.row.slot"
                                            v-bind="{
                                                item: creator,
                                                field: column.row.slotTransformer
                                                    ? column.row.slotTransformer(creator)
                                                    : $lodash.get(creator, column.key),
                                                column,
                                            }"
                                        />
                                    </template>

                                    <template v-else-if="column.row.formatter">
                                        {{
                                            column.row.formatter({
                                                item: creator,
                                                field: $lodash.get(creator, column.key),
                                                column,
                                            })
                                        }}
                                    </template>

                                    <template v-else>
                                        {{ $lodash.get(creator, column.key) }}
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody v-else class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-for="index in paging.perPage" :key="index" class="h-12">
                            <!-- <td v-if="isSelectable" class="whitespace-nowrap px-2">
                                <div class="h-5 w-full animate-pulse rounded bg-slate-200" />
                            </td> -->

                            <td v-for="column in enabledColumns" :key="column.id" class="whitespace-nowrap px-2">
                                <div class="h-5 w-full animate-pulse rounded bg-slate-200" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="!creators.length && !isLoading && paging.currentPage <= 1" class="w-full p-4 text-center">
                    <p class="text-sm">There are no creators that fit the selected filters</p>
                </div>
            </div>

            <div
                :class="{
                    'pointer-events-none opacity-25': !creators.length && !isLoading && paging.currentPage <= 1,
                }"
                class="border-t border-slate-200 px-3 py-2.5"
            >
                <DiscoveryPagination :paging="paging" @paging-changed="onPagingChange" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { nextTick } from 'vue'
import { mapGetters } from 'vuex'
import Tooltip from '@/components/Tooltip.vue'
import DropdownTableHeader from '@/components/dropdowns/DropdownTableHeader.vue'
import DropdownMultiSort from '@/components/dropdowns/DropdownMultiSort.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import DropdownMultiSelect from '@/components/dropdowns/DropdownMultiSelect.vue'
import ModalBasic from '@/components/ModalBasic.vue'

export default {
    name: 'DiscoverTable',

    components: {
        Tooltip,
        DropdownTableHeader,
        DropdownMultiSort,
        DiscoveryPagination,
        DropdownMultiSelect,
        ModalBasic,
    },

    props: {
        creators: {
            type: Object,
        },
        type: {
            type: String,
        },
        isLoading: {
            type: Boolean,
        },
        isBusy: {
            type: Boolean,
        },
        isSelectable: {
            type: Boolean,
            default: () => false,
        },
        paging: {
            type: Object,
        },
        columns: {
            type: Array,
        },
        enabledColumns: {
            type: Array,
        },
        platformId: {
            type: String,
        },
        idProp: {
            type: String,
            default: () => '_id',
        },
        superviewPath: {
            type: String,
            default: () => 'superview',
        },
        listId: {
            type: String,
            default: () => null,
        },
    },

    emits: [
        'search-creators',
        'platform-select',
        'add-to-list',
        'add-to-campaign',
        'remove-from-list',
        'filter-change',
        'paging-change',
        'export-creators',
        'enabled-column-change',
    ],

    data() {
        return {
            search: {
                text: '',
                platformId: 'twitch',
                platforms: [
                    {
                        id: 'twitch',
                        name: 'Twitch',
                    },
                    {
                        id: 'youtube',
                        name: 'YouTube',
                    },
                ],
            },

            fields: [],

            values: {
                searchText: '',
            },

            selectedCreators: {},

            state: {
                modals: {
                    addToListOrCampaign: {
                        isOpen: false,
                    },
                    removeFromList: {
                        isOpen: false,
                    },
                },
            },
        }
    },

    watch: {
        listId() {
            this.selectedCreators = {}
        },
    },

    computed: {
        ...mapGetters([
            'getLanguageFromCode',
            'selfActiveOrganization',
            'isSfStaff',
            'isDevModeEnabled',
            'getFromDictionary',
            'checkPermission',
        ]),

        isInputValid() {
            if (!this.search.text) return false
            if (typeof this.search.text !== 'string') return false
            const trimmedSearchText = this.search.text.trim()
            if (!trimmedSearchText) return false
            if (trimmedSearchText.length < 2) return false
            return true
        },

        sortLookup() {
            return _.reduce(
                this.paging.sort,
                (result, sortEntry) => {
                    // eslint-disable-next-line no-param-reassign
                    result[sortEntry.field] = sortEntry.direction
                    return result
                },
                {},
            )
        },

        isAllPageCheckboxSelected() {
            if (this?.creators?.length === 0) return false

            return _.every(this.creators, (creator) => {
                return this.selectedCreators[creator[this.idProp]]
            })
        },

        visibleColumns() {
            return _.filter(this.columns, (column) => this.enabledColumns.includes(column.id))
        },
    },

    methods: {
        scrollTableToTop() {
            this.$refs?.tableWrapper?.scrollTo({
                top: 0,
            })
        },

        onSelectCreator(creator) {
            if (!this.selectedCreators[creator[this.idProp]]) {
                this.selectedCreators[creator[this.idProp]] = creator
            } else {
                this.selectedCreators = _.omitBy(this.selectedCreators, (selectedCreator) => {
                    return selectedCreator[this.idProp] === creator[this.idProp]
                })
            }
        },

        onAllPageCheckboxClick() {
            const visibleCreatorsLookup = _.keyBy(this.creators, this.idProp)
            if (this.isAllPageCheckboxSelected === true) {
                this.selectedCreators = _.omitBy(this.selectedCreators, (selectedCreator) => {
                    const matchingCreator = visibleCreatorsLookup[selectedCreator[this.idProp]]
                    return matchingCreator
                })
            } else {
                _.forEach(this.creators, (creator) => {
                    const matchingCreator = this.selectedCreators[creator[this.idProp]]

                    if (!matchingCreator) {
                        this.selectedCreators[creator[this.idProp]] = creator
                    }
                })
            }
        },

        onHeaderColumnAction(columnId, actionId, actionData) {
            if (['sort-asc', 'sort-desc', 'sort-clear'].includes(actionId)) {
                // eslint-disable-next-line vue/no-mutating-props
                this.paging.sort = [
                    {
                        field: columnId,
                        direction: actionData,
                        source: 'user',
                    },
                ]

                this.$emit('filter-change')
            } else if (actionId === 'column-hide') {
                this.$emit(
                    'enabled-column-change',
                    _.filter(this.enabledColumns, (item) => item !== columnId),
                )
            }
        },

        // This is a full override of current sorting, coming from multi-sort
        onNewSort(newSort) {
            // eslint-disable-next-line vue/no-mutating-props
            this.paging.sort = newSort
            this.$emit('filter-change', newSort)
        },

        isColumnEnabled(column) {
            const isColumnInList = _.find(this.columns, (item) => item.key === column.key)
            return !!isColumnInList
        },

        debounceSearch: _.debounce(function () {
            nextTick(() => {
                if (this.isInputValid) {
                    this.$emit('search-creators', {
                        search: this.search.text,
                    })
                }
            })
        }, 500),

        onOptionsClose() {
            this.selectedCreators = {}
        },

        exportCreators() {
            this.$emit('export-creators', {})
        },

        onPagingChange(newPagingProperties) {
            this.$emit('paging-change', newPagingProperties)
            this.scrollTableToTop()
        },

        onColumnChange(newEnabledColumns) {
            this.$emit('enabled-column-change', newEnabledColumns)
        },

        onRemoveFromList() {
            this.$emit('remove-from-list', this.selectedCreators)
            this.state.modals.removeFromList.isOpen = false
            this.selectedCreators = {}
        },

        onSendMessages() {
            if (this.listId) {
                this.$store.commit('modal:setData', {
                    modal_id: 'send_messages',
                    data: {
                        options: this.createListMessageOptions(),
                    },
                })

                this.$store.commit('modal:open', 'send_messages')
            }
        },

        createListMessageOptions() {
            const creatorsToMessage = _.map(this.selectedCreators, (selectedCreator) => {
                return {
                    platform_id: selectedCreator.platform_id,
                    platform_user_id: selectedCreator.id,
                    platform_user_logo:
                        selectedCreator?.superview?.normalized.avatar_url ||
                        selectedCreator?.partialview?.normalized.avatar_url,
                    platform_user_name:
                        selectedCreator?.superview?.normalized.name || selectedCreator?.partialview?.normalized.name,
                    platform_user_display_name:
                        selectedCreator?.superview?.normalized.display_name ||
                        selectedCreator?.partialview?.normalized.display_name,
                    email: selectedCreator.metadata.email,
                }
            })

            return {
                messageType: 'email',
                entityType: 'list',
                entityId: this.listId,
                recipients: creatorsToMessage,
            }
        },
    },
}
</script>
