<template>
    <div class="h-full w-full overflow-x-hidden">
        <transition
            enter-active-class="transition ease-in duration-50"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class=""
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-if="state.isBackfilling === true">
                <div class="opacity-50">
                    <SkeletonApprovalSubpage />
                </div>

                <div class="absolute inset-0 flex h-full w-full">
                    <div class="m-auto text-center text-slate-700">
                        <h4 class="text-2xl font-semibold">Refreshing Data</h4>
                        <p>Please wait while we fetch the freshest data for this creator.</p>
                    </div>
                </div>
            </div>

            <div v-else-if="state.isFailed === true" class="flex h-full w-full">
                <div class="m-auto py-64 text-center text-slate-700">
                    <h4 class="text-2xl font-semibold">Failed to Fetch Profile</h4>
                    <p>There was an error loading that creator's profile. Please try again later.</p>
                </div>
            </div>

            <div v-else-if="state.isMissing" class="flex h-full w-full py-56">
                <div class="m-auto">
                    <div class="text-center">
                        <h4 class="text-2xl font-semibold text-slate-700">Profile Not Found</h4>
                        <p>It seems we don't have a profile for that creator.</p>
                    </div>

                    <div class="mx-auto mt-10 w-96 rounded-lg border border-slate-200 p-5">
                        <h4 class="text-lg font-medium text-slate-700">Why is that?</h4>
                        <div class="space-y-2 text-sm">
                            <p>
                                Streamforge collects data on all content creators, depending on the platform and the
                                reach of the creator, they might fall below the minimum threshold that was set on that
                                platform or we might have simply missed them. Please let our team know if you keep
                                running into this issue.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div v-else-if="superview" class="relative h-full rounded">
                <div class="flex h-12 w-full rounded-t border-b border-slate-800/50 bg-cinder-900">
                    <div v-if="biography?.features?.primary_classification" class="mx-auto flex px-2">
                        <div class="my-auto flex space-x-1">
                            <!-- <div class="bg-white/5 rounded p-1.5 my-auto leading-none">📱</div> -->
                            <div class="flex grow rounded py-[3.5px] pl-2 pr-2">
                                <div class="flex space-x-2.5">
                                    <!-- <div class="text-[18px]">📱</div> -->
                                    <div class="my-auto grow">
                                        <h1 class="text-[14px] font-medium leading-none text-slate-200">
                                            {{ biography?.features?.primary_classification?.topic }}
                                        </h1>
                                        <div class="mt-[1px] text-[11px] leading-none text-slate-400">Industry</div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex grow rounded px-1.5 py-1.5">
                                <div class="my-auto text-sm leading-none text-slate-200">
                                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="h-3 w-3" />
                                </div>
                            </div>
                            <div class="flex grow rounded py-[3.5px] pl-2.5 pr-2.5">
                                <div class="flex space-x-2.5">
                                    <div class="my-auto grow">
                                        <h1 class="text-[14px] font-medium leading-none text-slate-200">
                                            {{ biography?.features?.primary_classification?.category }}
                                        </h1>
                                        <div class="mt-[1px] text-[11px] leading-none text-slate-400">Category</div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex grow rounded px-1.5 py-1.5">
                                <div class="my-auto text-sm leading-none text-slate-200">
                                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="h-3 w-3" />
                                </div>
                            </div>
                            <div class="flex grow rounded py-[3.5px] pl-2.5 pr-2.5">
                                <div class="flex space-x-2.5">
                                    <div class="my-auto grow">
                                        <h1 class="text-[14px] font-medium leading-none text-slate-200">
                                            {{ biography?.features?.primary_classification?.subcategory }}
                                        </h1>
                                        <div class="mt-[1px] text-[11px] leading-none text-slate-400">Niche</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Profile background -->
                <div class="relative flex h-44 w-full border-b border-slate-100 bg-sf_background">
                    <CreatorBanner
                        class="absolute inset-0"
                        :srcs="[
                            creator?.banner_image_url,
                            superview?.normalized?.banner_url,
                            `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${selectedPlatformId}/banners/high/${superview._id}`,
                        ]"
                    />
                    <div
                        class="absolute inset-0 h-full w-full bg-gradient-to-b from-cinder-900 via-transparent to-transparent opacity-25"
                    ></div>

                    <div v-if="state.overlay.isActive === false" class="relative ml-auto mt-auto flex space-x-2 p-2.5">
                        <button
                            v-if="checkPermission('module.lists') || checkPermission('module.campaigns')"
                            class="btn flex border-slate-200 bg-white text-slate-700 hover:border-slate-300"
                            @click="
                                $store.commit('modal:setData', {
                                    modal_id: 'add_creator_to_modal',
                                    data: {
                                        is_open: true,
                                        platform_id: selectedPlatformId,
                                        superviews: [superview],
                                    },
                                })
                            "
                        >
                            <font-awesome-icon
                                :icon="['fas', 'plus']"
                                class="my-auto h-4 w-4 shrink-0 fill-current text-slate-500"
                            />
                            <span class="ml-2 hidden xs:block">Add to...</span>
                        </button>

                        <button
                            class="btn flex border-slate-200 bg-white text-slate-700 hover:border-slate-300"
                            @click="state.overlay.isActive = true"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'pen-to-square']"
                                class="my-auto h-4 w-4 shrink-0 fill-current text-slate-500"
                            />
                            <span class="ml-2 hidden xs:block">Edit Profile</span>
                        </button>

                        <Tooltip
                            v-if="
                                isSfStaff && ['youtube', 'tiktok', 'twitter', 'instagram'].includes(selectedPlatformId)
                            "
                        >
                            <template #trigger>
                                <button
                                    class="btn flex border-slate-200 bg-white py-2.5 text-slate-700 hover:border-slate-300"
                                    @click="postBackfillSuperview(selectedPlatformId, platformUserId, true)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'arrows-rotate']"
                                        class="my-auto h-4 w-4 shrink-0 fill-current text-slate-500"
                                    />
                                    <!-- <span class="ml-2 hidden xs:block">Impersonate User</span> -->
                                </button>
                            </template>
                            <template #default>
                                <div class="whitespace-normal text-xs">
                                    Clicking this will trigger a manual refresh of this creator's data.
                                </div>
                            </template>
                        </Tooltip>

                        <Tooltip v-if="isSfStaff">
                            <template #trigger>
                                <button
                                    class="btn flex border-slate-200 bg-white py-2.5 text-slate-700 hover:border-slate-300"
                                    @click="impersonate"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'user-secret']"
                                        class="my-auto h-4 w-4 shrink-0 fill-current text-slate-500"
                                    />
                                    <!-- <span class="ml-2 hidden xs:block">Impersonate User</span> -->
                                </button>
                            </template>
                            <template #default>
                                <div class="whitespace-normal text-xs">
                                    Connect to this creator's account to view their profile as if you were them on the
                                    creator dashboard.
                                </div>
                            </template>
                        </Tooltip>
                    </div>
                </div>

                <div class="rounded-b bg-white">
                    <div v-if="state.overlay.isActive && state.overlay.type === 'override'">
                        <EditUserProfileModal
                            :on-cancel="
                                () => {
                                    state.overlay.isActive = false
                                }
                            "
                            :on-saved="
                                async (updates) => {
                                    onUpdateCreator(updates)
                                }
                            "
                            :linkable-users="linkableUsers"
                            :on-link-user="
                                async (platformUserId) => {
                                    onLinkUser(platformUserId)
                                }
                            "
                            :on-unlink-user="
                                async () => {
                                    onUnlinkUser()
                                }
                            "
                            :platform-id="selectedPlatformId"
                            :superview="superview"
                        />
                    </div>

                    <div v-else class="px-4 pb-8 sm:px-6">
                        <!-- Pre-header -->
                        <div class="flex justify-between">
                            <div>
                                <Avatar
                                    class="relative -mt-16 bg-slate-100 text-slate-400 shadow-[0_0px_0px_4px_white]"
                                    :srcs="[
                                        superview?.normalized?.avatar_url.replace('normal', '400x400'),
                                        `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${selectedPlatformId}/avatars/${
                                            ['youtube', 'instagram'].includes(selectedPlatformId) ? 'high' : 'medium'
                                        }/${superview._id}`,
                                    ]"
                                    :username="superview?.normalized?.display_name"
                                    :size="128"
                                />
                            </div>
                            <div class="ml-2 flex h-full space-x-1 self-center">
                                <ExternalSocialLinks v-if="fullSocialLinks" :social-links="fullSocialLinks" />
                            </div>
                        </div>

                        <div class="mb-3 mt-5 inline-flex rounded border border-slate-100">
                            <div class="flex py-2.5 pl-5 pr-7">
                                <div class="mr-2.5 flex">
                                    <font-awesome-icon
                                        :icon="['fab', selectedPlatformId]"
                                        class="my-auto h-7 w-7"
                                        :class="`text-${selectedPlatformId}`"
                                    />
                                </div>
                                <div class="flex flex-col">
                                    <h1 class="text-xl font-bold leading-none text-slate-800">
                                        {{ superview.normalized?.display_name }}
                                    </h1>
                                    <h4 class="mt-0.5 text-xs leading-none text-slate-500">
                                        @{{ superview.normalized?.name }}
                                    </h4>
                                </div>
                            </div>

                            <div class="flex">
                                <button
                                    v-for="profileOption in profileSelector.others"
                                    :key="profileOption.platform_id"
                                    role="tab"
                                    :data-state="profileOption.is_enabled ? 'active' : 'inactive'"
                                    :disabled="
                                        !profileOption.is_enabled && profileOption.platform_id !== selectedPlatformId
                                    "
                                    class="group flex cursor-pointer border-l border-slate-100 px-5 transition transition-all duration-200 hover:bg-slate-50 disabled:pointer-events-none"
                                    @click="
                                        () =>
                                            handlePlatformIdTabClick(
                                                profileOption.platform_id,
                                                profileOption?.primary?.id,
                                            )
                                    "
                                >
                                    <font-awesome-icon
                                        :icon="['fab', profileOption.platform_id]"
                                        class="my-auto h-5 w-5 shrink-0 fill-current group-disabled:opacity-10"
                                        :class="`text-${profileOption.platform_id}`"
                                    />
                                </button>
                            </div>
                        </div>

                        <div v-if="isSfStaff && isDevModeEnabled" class="mb-6">
                            <h2 class="mb-3 font-semibold text-slate-800">Debug Info</h2>
                            <div class="mb-4 flex divide-x rounded border-slate-100 bg-slate-50 text-sm">
                                <div class="grow px-4 py-3">
                                    <div class="text-xs font-medium text-slate-500">Platform User ID</div>
                                    <div class="font-semibold text-slate-700">{{ superview._id }}</div>
                                </div>
                                <div class="grow px-4 py-3">
                                    <div class="text-xs font-medium text-slate-500">Last Updated At</div>
                                    <div class="font-semibold text-slate-700">
                                        {{ $filters.moment(superview._updated_at).format('MMM Do YYYY, h:mm A') }}
                                    </div>
                                </div>
                                <div class="grow px-4 py-3">
                                    <div class="text-xs font-medium text-slate-500">Version</div>
                                    <div class="font-semibold text-slate-700">{{ superview._version }}</div>
                                </div>
                            </div>
                        </div>

                        <!-- Tabs -->
                        <div v-if="$lodash.size(visiblePrimaryTabs) > 1" class="relative mb-3">
                            <div class="absolute bottom-0 h-px w-full bg-slate-200" aria-hidden="true" />

                            <ul
                                class="no-scrollbar relative -mx-4 flex flex-nowrap text-sm font-medium sm:-mx-6 lg:-mx-8"
                            >
                                <li
                                    v-for="tab in visiblePrimaryTabs"
                                    :key="tab.key"
                                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                >
                                    <a
                                        class="block cursor-pointer whitespace-nowrap py-3"
                                        :class="{
                                            'border-b-2 border-indigo-500 text-indigo-500':
                                                state.tabs.primary.activeTab === tab.key,
                                        }"
                                        @click="handleTabClick('primary', tab.key)"
                                        >{{ tab.label }}</a
                                    >
                                </li>
                            </ul>
                        </div>

                        <template v-if="state.tabs.primary.activeTab === 'profile'">
                            <!-- Header -->
                            <header class="mb-5 text-center sm:text-left">
                                <!-- Meta -->
                                <div class="flex space-x-4 text-xs sm:justify-between">
                                    <div class="flex flex-wrap">
                                        <div
                                            v-if="superview?.normalized?.language"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'language']" class="h-5 w-5 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap text-xs font-medium">
                                                {{ getLanguageFromCode(superview?.normalized?.language) }}
                                            </span>
                                        </div>

                                        <div
                                            v-else-if="biography?.features?.primary_language"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'language']" class="h-4 w-4 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap text-xs font-medium">
                                                {{ getLanguageFromCode(biography?.features?.primary_language) }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="superview?.normalized?.country"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'language']" class="h-5 w-5 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap text-xs font-medium">
                                                {{ getCountryFromCode(superview.normalized.country) }}
                                            </span>
                                        </div>

                                        <div
                                            v-else-if="biography?.features?.country_code"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'language']" class="h-5 w-5 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap text-xs font-medium">
                                                {{ getCountryFromCode(biography?.features?.country_code) }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="superview?.normalized?.type"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'users-viewfinder']"
                                                class="h-4 w-4 shrink-0"
                                            />
                                            <span class="ml-1.5 whitespace-nowrap font-medium">
                                                <span class="capitalize">
                                                    {{ superview?.normalized?.type?.replace('_', ' ') }}
                                                </span>
                                            </span>
                                        </div>

                                        <div
                                            v-else-if="biography?.features?.channel_type"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'users-viewfinder']"
                                                class="h-4 w-4 shrink-0"
                                            />
                                            <span class="ml-1.5 whitespace-nowrap font-medium">
                                                <span class="capitalize">{{
                                                    biography?.features?.channel_type?.replace('_', ' ')
                                                }}</span></span
                                            >
                                        </div>

                                        <div
                                            v-else
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'user']" class="h-4 w-4 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap font-medium">
                                                {{
                                                    getFromDictionary(
                                                        `superview.type.${superview?.normalized?.type}`,
                                                        'label',
                                                    )
                                                }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="selectedPlatformId === 'twitch' && superview.channel?.is_mature"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'family']" class="h-4 w-4 shrink-0" />
                                            <span class="ml-1.5 whitespace-nowrap font-medium">Mature Content</span>
                                        </div>

                                        <Tooltip
                                            v-if="
                                                selectedPlatformId === 'twitch' &&
                                                superview.stream?.day_90.accumulation?.games?.class
                                            "
                                            max-width="400"
                                        >
                                            <template #trigger>
                                                <div
                                                    class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'sparkles']"
                                                        class="h-4 w-4 shrink-0"
                                                    />
                                                    <span class="ml-1.5 whitespace-nowrap font-medium">{{
                                                        getFromDictionary(
                                                            `superview.class.${superview.stream?.day_90?.accumulation?.games?.class}`,
                                                            'label',
                                                        )
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template #default>
                                                <div class="mb-1">
                                                    <h4 class="font-semibold capitalize text-white">Content Focus</h4>
                                                    <div class="text-xs">Metadata</div>
                                                </div>
                                                <div class="whitespace-normal text-xs">
                                                    {{
                                                        getFromDictionary(
                                                            `superview.class.${superview.stream?.day_90?.accumulation?.games?.class}`,
                                                            'description',
                                                        )
                                                    }}
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <Tooltip
                                            v-if="
                                                selectedPlatformId === 'youtube' &&
                                                superview.video?.day_90.accumulation?.games?.class
                                            "
                                            max-width="400"
                                        >
                                            <template #trigger>
                                                <div
                                                    class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'sparkles']"
                                                        class="h-4 w-4 shrink-0"
                                                    />
                                                    <span class="ml-1.5 whitespace-nowrap font-medium">{{
                                                        getFromDictionary(
                                                            `superview.class.${superview.video?.day_90?.accumulation?.games?.class}`,
                                                            'label',
                                                        )
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template #default>
                                                <div class="mb-1">
                                                    <h4 class="font-semibold capitalize text-white">Content Focus</h4>
                                                    <div class="text-xs">Metadata</div>
                                                </div>

                                                <div class="whitespace-normal text-xs">
                                                    {{
                                                        getFromDictionary(
                                                            `superview.class.${superview.video?.day_90?.accumulation?.games?.class}`,
                                                            'description',
                                                        )
                                                    }}
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <Tooltip v-if="isSfStaff && creator?.metadata?.is_blacklisted">
                                            <template #trigger>
                                                <div
                                                    class="my-1 mr-2 flex items-center rounded bg-rose-100 px-3 py-1.5"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'xmark']"
                                                        class="h-4 w-4 text-rose-500"
                                                    />
                                                    <span class="ml-1.5 whitespace-nowrap font-medium text-rose-500">
                                                        Do Not Hire
                                                    </span>
                                                </div>
                                            </template>
                                            <template #default>
                                                <div class="whitespace-normal text-xs">
                                                    Creator's that are marked as blacklisted are to be avoided for
                                                    campaigns. This could be due to a breach of contract, abnormal
                                                    campaign results, or for brand safety reasons.
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <template v-if="superview?.normalized?.type === 'individual'">
                                            <Tooltip v-if="superview?.normalized?.age_range">
                                                <template #trigger>
                                                    <div
                                                        class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fas', 'cake-candles']"
                                                            class="h-4 w-4 shrink-0"
                                                        />
                                                        <span class="ml-1.5 whitespace-nowrap font-medium"
                                                            >Age:
                                                            <span class="capitalize">{{
                                                                superview?.normalized?.age_range
                                                            }}</span></span
                                                        >
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div class="mb-1">
                                                        <h4 class="font-semibold capitalize text-white">Age Range</h4>
                                                        <div class="text-xs">AI Metadata</div>
                                                    </div>

                                                    <div class="whitespace-normal text-xs">
                                                        An estimation of the age range of the creator based on AI.
                                                    </div>
                                                </template>
                                            </Tooltip>
                                            <Tooltip v-else-if="biography?.features?.age_range">
                                                <template #trigger>
                                                    <div
                                                        class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fas', 'cake-candles']"
                                                            class="h-4 w-4 shrink-0"
                                                        />
                                                        <span class="ml-1.5 whitespace-nowrap font-medium"
                                                            >Age:
                                                            <span class="capitalize">{{
                                                                biography?.features?.age_range
                                                            }}</span></span
                                                        >
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div class="mb-1">
                                                        <h4 class="font-semibold capitalize text-white">Age Range</h4>
                                                        <div class="text-xs">AI Metadata</div>
                                                    </div>

                                                    <div class="whitespace-normal text-xs">
                                                        An estimation of the age range of the creator based on AI.
                                                    </div>
                                                </template>
                                            </Tooltip>
                                        </template>

                                        <template v-if="superview?.normalized?.type === 'individual'">
                                            <Tooltip v-if="superview?.normalized?.gender">
                                                <template #trigger>
                                                    <div
                                                        class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fas', 'venus-mars']"
                                                            class="h-4 w-4 shrink-0"
                                                        />
                                                        <span class="ml-1.5 whitespace-nowrap font-medium">
                                                            <span class="capitalize">{{
                                                                superview?.normalized?.gender
                                                            }}</span></span
                                                        >
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div class="mb-1">
                                                        <h4 class="font-semibold capitalize text-white">Gender</h4>
                                                        <div class="text-xs">AI Metadata</div>
                                                    </div>

                                                    <div class="whitespace-normal text-xs">
                                                        An estimation of the gender of the creator based on AI.
                                                    </div>
                                                </template>
                                            </Tooltip>

                                            <Tooltip v-else-if="biography?.features?.gender">
                                                <template #trigger>
                                                    <div
                                                        class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fas', 'venus-mars']"
                                                            class="h-4 w-4 shrink-0"
                                                        />
                                                        <span class="ml-1.5 whitespace-nowrap font-medium">
                                                            <span class="capitalize">{{
                                                                biography?.features?.gender
                                                            }}</span></span
                                                        >
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div class="mb-1">
                                                        <h4 class="font-semibold capitalize text-white">Gender</h4>
                                                        <div class="text-xs">AI Metadata</div>
                                                    </div>

                                                    <div class="whitespace-normal text-xs">
                                                        An estimation of the gender of the creator based on AI.
                                                    </div>
                                                </template>
                                            </Tooltip>
                                        </template>

                                        <Tooltip v-if="biography?.features?.mpaa">
                                            <template #trigger>
                                                <div
                                                    class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'shield-halved']"
                                                        class="h-4 w-4 shrink-0"
                                                    />
                                                    <span class="ml-1.5 whitespace-nowrap font-medium">
                                                        <span class="capitalize">{{
                                                            biography?.features?.mpaa.rating
                                                        }}</span></span
                                                    >
                                                </div>
                                            </template>
                                            <template #default>
                                                <div class="mb-1">
                                                    <h4 class="font-semibold capitalize text-white">
                                                        MPAA Content Rating
                                                    </h4>
                                                    <div class="text-xs">AI Metadata</div>
                                                </div>

                                                <div class="whitespace-normal text-xs">
                                                    {{ biography?.features?.mpaa.reason }}
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <Tooltip v-if="biography?.features?.camera_presence">
                                            <template #trigger>
                                                <div
                                                    class="my-1 mr-2 flex items-center rounded bg-slate-50 px-3 py-1.5"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'camera']"
                                                        class="h-4 w-4 shrink-0"
                                                    />
                                                    <span class="ml-1.5 whitespace-nowrap font-medium capitalize">{{
                                                        biography?.features?.camera_presence?.value?.replace('_', ' ')
                                                    }}</span>
                                                </div>
                                            </template>
                                            <template #default>
                                                <div class="whitespace-normal text-xs">
                                                    <div class="mb-2">
                                                        <h4 class="font-semibold capitalize text-white">
                                                            Camera Prescence
                                                        </h4>
                                                        <div class="text-xs">AI Metadata</div>
                                                    </div>

                                                    <ul class="mb-2 list-disc px-5">
                                                        <li>
                                                            <span class="font-medium">Face Camera</span> - creator
                                                            presents their real face in their content
                                                        </li>
                                                        <li>
                                                            <span class="font-medium">No Camera</span> - creator does
                                                            not show their face in their content
                                                        </li>
                                                        <li>
                                                            <span class="font-medium">V-Tuber</span> - creator uses a
                                                            virtual avatar to represent themself
                                                        </li>
                                                    </ul>
                                                </div>
                                            </template>
                                        </Tooltip>

                                        <div
                                            v-if="selectedPlatformId === 'twitch' && superview._last_live_at"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'signal-stream']"
                                                class="h-5 w-5 shrink-0"
                                            />
                                            <span class="ml-1.5 whitespace-nowrap font-medium"
                                                >Live {{ $moment.utc(superview._last_live_at).fromNow() }}</span
                                            >
                                        </div>

                                        <div
                                            v-if="selectedPlatformId === 'youtube' && superview._last_video_at"
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'signal-stream']"
                                                class="h-5 w-5 shrink-0"
                                            />
                                            <span class="ml-1.5 whitespace-nowrap font-medium"
                                                >Last posted {{ $moment.utc(superview._last_video_at).fromNow() }}</span
                                            >
                                        </div>

                                        <div
                                            v-if="
                                                ['twitter', 'instagram'].includes(selectedPlatformId) &&
                                                superview._last_post_at
                                            "
                                            class="my-1 mr-2 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'signal-stream']"
                                                class="h-5 w-5 shrink-0"
                                            />
                                            <span class="ml-1.5 whitespace-nowrap font-medium"
                                                >Last posted {{ $moment.utc(superview._last_post_at).fromNow() }}</span
                                            >
                                        </div>
                                    </div>

                                    <div class="flex">
                                        <div
                                            v-if="superview?.normalized?.email"
                                            class="my-1 flex h-8 items-center rounded bg-slate-50 px-3 py-1.5"
                                        >
                                            <font-awesome-icon :icon="['fas', 'envelope']" class="h-5 w-5 shrink-0" />
                                            <span
                                                v-if="checkPermission('feature.global.emails')"
                                                class="ml-1.5 whitespace-nowrap font-medium lowercase"
                                            >
                                                {{ superview?.normalized?.email || 'No Email' }}
                                            </span>
                                            <template v-else>
                                                <Tooltip>
                                                    <template #trigger>
                                                        <div
                                                            class="ml-2 h-3.5 w-28 rounded bg-slate-200 transition duration-200 hover:bg-slate-300"
                                                        ></div>
                                                    </template>
                                                    <template #default>
                                                        <div class="whitespace-normal text-xs">
                                                            Feature Unavailable - Account upgrade required for access to
                                                            creator emails.
                                                        </div>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <!-- Bio -->
                                <div v-if="checkPermission('feature.ai.bio')" class="mt-3">
                                    <div class="mr-auto flex space-x-1">
                                        <span
                                            class="flex h-[26px] rounded-tl rounded-tr bg-slate-100 px-3 py-1 text-xs font-semibold text-slate-600"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'wand-magic-sparkles']"
                                                class="my-auto mr-1 h-3 w-3 text-slate-600"
                                            />
                                            AI Biography
                                        </span>

                                        <Tooltip v-if="isSfStaff">
                                            <template #trigger>
                                                <button
                                                    class="flex h-[26px] items-center rounded-tl rounded-tr bg-slate-100 px-3 py-1.5 text-xs font-semibold text-slate-600 hover:text-slate-800"
                                                    @click="
                                                        getCreatorBiography(selectedPlatformId, platformUserId, true)
                                                    "
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fas', 'arrows-rotate']"
                                                        class="my-auto h-3 w-3 shrink-0 fill-current"
                                                    />
                                                </button>
                                            </template>
                                            <template #default>
                                                <div class="whitespace-normal text-xs">
                                                    Clicking this will trigger a manual refresh of the AI analysis.
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                    <div
                                        class="flex h-[120px] overflow-y-scroll whitespace-pre-wrap rounded-bl rounded-br rounded-tr border border-slate-100 px-3 py-2 pr-2 text-sm md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
                                    >
                                        <template v-if="state.biography.isLoading">
                                            <div class="space-between my-auto flex w-full flex-col">
                                                <div
                                                    v-for="tab in 4"
                                                    :key="tab"
                                                    class="flex rounded py-1 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                >
                                                    <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else-if="biography.text">
                                            <div>
                                                {{ biography.animatedText }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="my-1 flex h-[80px] w-full rounded bg-slate-100">
                                                <p class="m-auto italic">
                                                    Not enough data to generate insights on this creator.
                                                </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </header>

                            <div>
                                <template v-if="metrics?.byGroup?.viewership?.length">
                                    <div class="mb-3 flex justify-between">
                                        <div class="flex">
                                            <h2 class="my-auto font-semibold leading-none text-slate-800">
                                                Key Metrics
                                            </h2>
                                        </div>

                                        <div class="ml-auto space-x-2">
                                            <div
                                                v-if="validContentTypes?.length > 1"
                                                class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                role="group"
                                            >
                                                <button
                                                    v-for="tab in validContentTypes"
                                                    :key="tab"
                                                    class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                    :class="{
                                                        'bg-white': state.contentType === tab,
                                                    }"
                                                    @click.prevent="state.contentType = tab"
                                                >
                                                    <span class="capitalize">{{
                                                        getFromDictionary(
                                                            `superview.contentType.switcher.${selectedPlatformId}.${tab}`,
                                                        )
                                                    }}</span>
                                                </button>
                                            </div>

                                            <div
                                                v-if="validMetricWindows?.length > 1"
                                                class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                role="group"
                                            >
                                                <button
                                                    v-for="metricWindow in validMetricWindows"
                                                    :key="metricWindow"
                                                    class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                    :class="{
                                                        'bg-white': state.metricWindow === metricWindow,
                                                    }"
                                                    @click.prevent="state.metricWindow = metricWindow"
                                                >
                                                    <span class="capitalize">{{
                                                        getFromDictionary(`superview.metricWindow.${metricWindow}`)
                                                    }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid grid-cols-4 gap-3">
                                        <div
                                            v-for="metric in metrics.byGroup.viewership"
                                            :key="metric.title"
                                            class="relative rounded bg-slate-50 px-4 py-3"
                                        >
                                            <div class="text-xs font-medium text-slate-500">{{ metric.title }}</div>
                                            <div class="text-xl font-semibold text-slate-700">
                                                {{ metric.formattedValue }}
                                            </div>

                                            <div v-if="metric.deltaValue" class="absolute right-0 top-0 z-10 p-2">
                                                <div
                                                    class="flex rounded py-1 pl-1 pr-2 text-xs font-medium"
                                                    :class="[metric.deltaValue.textClass, metric.deltaValue.bgClass]"
                                                >
                                                    <font-awesome-icon
                                                        :icon="metric.deltaValue.icon"
                                                        class="my-auto ml-1 mr-1 h-3 w-3 shrink-0 fill-current"
                                                    />
                                                    <span>{{ metric.deltaValue.absoluteValue }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-if="metrics?.byGroup?.engagement?.length">
                                    <!-- <div class="mb-3 flex justify-between mt-4">
                                        <div class="flex">
                                            <h2 class="my-auto font-semibold leading-none text-slate-800">Engagement Metrics</h2>
                                        </div>
                                    </div> -->

                                    <div class="mt-3 grid grid-cols-4 gap-3">
                                        <div
                                            v-for="metric in metrics.byGroup.engagement"
                                            :key="metric.title"
                                            class="relative rounded bg-slate-50 px-4 py-3"
                                        >
                                            <div class="text-xs font-medium text-slate-500">{{ metric.title }}</div>
                                            <div class="text-xl font-semibold text-slate-700">
                                                {{ metric.formattedValue }}
                                            </div>

                                            <div v-if="metric.deltaValue" class="absolute right-0 top-0 z-10 p-2">
                                                <div
                                                    class="flex rounded py-1 pl-1 pr-2 text-xs font-medium"
                                                    :class="[metric.deltaValue.textClass, metric.deltaValue.bgClass]"
                                                >
                                                    <font-awesome-icon
                                                        :icon="metric.deltaValue.icon"
                                                        class="my-auto ml-1 mr-1 h-3 w-3 shrink-0 fill-current"
                                                    />
                                                    <span>{{ metric.deltaValue.absoluteValue }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <div class="mt-4 h-[340px] w-full">
                                    <div
                                        v-if="state.timeseries.isLoaded === true"
                                        class="h-full rounded bg-slate-50 px-5 pb-3 pt-7"
                                    >
                                        <ContentTimeseriesChart
                                            :series-data="contentTimeseries"
                                            :primary="state.timeseries.configuration.primary"
                                            :secondary="state.timeseries.configuration.secondary"
                                        />
                                    </div>

                                    <!-- Skeleton Component -->
                                    <div v-else class="h-full w-full animate-pulse rounded bg-slate-200" />
                                </div>

                                <template v-if="metrics?.byGroup?.rates?.length">
                                    <div class="mb-3 mt-5 flex justify-between">
                                        <div class="flex">
                                            <h2 class="my-auto font-semibold leading-none text-slate-800">AD Value</h2>
                                        </div>
                                    </div>

                                    <div class="mt-4 grid grid-cols-4 gap-3">
                                        <div
                                            v-for="metric in metrics.byGroup.rates"
                                            :key="metric.name"
                                            class="relative rounded bg-slate-50 px-4 py-3"
                                        >
                                            <div v-if="metric.tooltip" class="absolute right-0 top-0 p-3">
                                                <Tooltip placement="top" max-width="300" class="h-full w-full">
                                                    <template #trigger>
                                                        <div
                                                            class="cursor-pointer rounded bg-slate-200 p-1 transition duration-200 hover:bg-slate-300"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="['fas', 'question']"
                                                                class="h-2.5 w-2.5 text-slate-700"
                                                            />
                                                        </div>
                                                    </template>
                                                    <template #default>
                                                        <div>
                                                            <h4 class="font-semibold text-white">
                                                                {{ metric.tooltip.label }}
                                                            </h4>
                                                            <p v-html="metric.tooltip.description"></p>
                                                        </div>
                                                    </template>
                                                </Tooltip>
                                            </div>

                                            <div class="text-xs font-medium text-slate-500">{{ metric.title }}</div>
                                            <div class="text-xl font-semibold text-slate-700">
                                                {{ metric.formattedValue }}
                                            </div>

                                            <div v-if="metric.deltaValue" class="absolute right-0 top-0 z-10 p-2">
                                                <div
                                                    class="flex rounded py-1 pl-1 pr-2 text-xs font-medium"
                                                    :class="[metric.deltaValue.textClass, metric.deltaValue.bgClass]"
                                                >
                                                    <font-awesome-icon
                                                        :icon="metric.deltaValue.icon"
                                                        class="my-auto ml-1 mr-1 h-3 w-3 shrink-0 fill-current"
                                                    />
                                                    <span>{{ metric.deltaValue.absoluteValue }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template
                                    v-if="
                                        (metrics?.byGroup?.conversion?.length || metrics?.byGroup?.outreach) &&
                                        isSfStaff
                                    "
                                >
                                    <div class="mb-5 mt-5 grid grid-cols-2 gap-3">
                                        <div>
                                            <div class="flex justify-between">
                                                <div class="flex">
                                                    <h2 class="my-auto font-semibold leading-none text-slate-800">
                                                        Conversion Rates
                                                    </h2>
                                                </div>
                                            </div>

                                            <div class="mt-4 grid grid-cols-2 gap-3">
                                                <div
                                                    v-for="metric in metrics.byGroup.conversion"
                                                    :key="metric.name"
                                                    class="relative overflow-hidden rounded bg-slate-50 px-4 py-3"
                                                >
                                                    <div v-if="metric.tooltip" class="absolute right-0 top-0 p-3">
                                                        <Tooltip placement="top" max-width="300" class="h-full w-full">
                                                            <template #trigger>
                                                                <div
                                                                    class="cursor-pointer rounded bg-slate-200 p-1 transition duration-200 hover:bg-slate-300"
                                                                >
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'question']"
                                                                        class="h-2.5 w-2.5 text-slate-700"
                                                                    />
                                                                </div>
                                                            </template>
                                                            <template #default>
                                                                <div>
                                                                    <h4 class="font-semibold text-white">
                                                                        {{ metric.tooltip.label }}
                                                                    </h4>
                                                                    <p v-html="metric.tooltip.description"></p>
                                                                </div>
                                                            </template>
                                                        </Tooltip>
                                                    </div>

                                                    <div class="text-xs font-medium text-slate-500">
                                                        {{ metric.title }}
                                                    </div>
                                                    <div class="text-xl font-semibold text-slate-700">
                                                        {{ metric.formattedValue }}
                                                    </div>

                                                    <div
                                                        v-if="metric.deltaValue"
                                                        class="absolute right-0 top-0 z-10 p-2"
                                                    >
                                                        <div
                                                            class="flex rounded py-1 pl-1 pr-2 text-xs font-medium"
                                                            :class="[
                                                                metric.deltaValue.textClass,
                                                                metric.deltaValue.bgClass,
                                                            ]"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="metric.deltaValue.icon"
                                                                class="my-auto ml-1 mr-1 h-3 w-3 shrink-0 fill-current"
                                                            />
                                                            <span>{{ metric.deltaValue.absoluteValue }}</span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="absolute bottom-0 left-0 h-1 w-full"
                                                        :class="{
                                                            'bg-emerald-400': metric.value >= 0.75,
                                                            'bg-amber-400': metric.value < 0.75 && metric.value >= 0.25,
                                                            'bg-rose-400': metric.value < 0.25,
                                                        }"
                                                        :style="{
                                                            width: `${metric.value * 100}%`,
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="justify-between">
                                                <div class="flex">
                                                    <h2 class="my-auto font-semibold leading-none text-slate-800">
                                                        Response Rates
                                                    </h2>
                                                </div>
                                            </div>

                                            <div class="mt-4 grid grid-cols-2 gap-3">
                                                <div
                                                    v-for="metric in metrics.byGroup.outreach"
                                                    :key="metric.name"
                                                    class="relative overflow-hidden rounded bg-slate-50 px-4 py-3"
                                                >
                                                    <div v-if="metric.tooltip" class="absolute right-0 top-0 p-3">
                                                        <Tooltip placement="top" max-width="300" class="h-full w-full">
                                                            <template #trigger>
                                                                <div
                                                                    class="cursor-pointer rounded bg-slate-200 p-1 transition duration-200 hover:bg-slate-300"
                                                                >
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'question']"
                                                                        class="h-2.5 w-2.5 text-slate-700"
                                                                    />
                                                                </div>
                                                            </template>
                                                            <template #default>
                                                                <div>
                                                                    <h4 class="font-semibold text-white">
                                                                        {{ metric.tooltip.label }}
                                                                    </h4>
                                                                    <p v-html="metric.tooltip.description"></p>
                                                                </div>
                                                            </template>
                                                        </Tooltip>
                                                    </div>

                                                    <div class="text-xs font-medium text-slate-500">
                                                        {{ metric.title }}
                                                    </div>
                                                    <div class="text-xl font-semibold text-slate-700">
                                                        {{ metric.formattedValue }}
                                                    </div>

                                                    <div
                                                        v-if="metric.deltaValue"
                                                        class="absolute right-0 top-0 z-10 p-2"
                                                    >
                                                        <div
                                                            class="flex rounded py-1 pl-1 pr-2 text-xs font-medium"
                                                            :class="[
                                                                metric.deltaValue.textClass,
                                                                metric.deltaValue.bgClass,
                                                            ]"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="metric.deltaValue.icon"
                                                                class="my-auto ml-1 mr-1 h-3 w-3 shrink-0 fill-current"
                                                            />
                                                            <span>{{ metric.deltaValue.absoluteValue }}</span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="absolute bottom-0 left-0 h-1 w-full"
                                                        :class="{
                                                            'bg-emerald-400': metric.value >= 0.5,
                                                            'bg-amber-400': metric.value < 0.5 && metric.value >= 0.25,
                                                            'bg-rose-400': metric.value < 0.25,
                                                        }"
                                                        :style="{
                                                            width: `${metric.value * 100}%`,
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div v-if="participant && $filters.lodash.size(mappedTasks) > 0" class="mt-5">
                                <h2 class="mb-3 font-semibold text-slate-800">Deliverables & Spend</h2>

                                <div class="grid grid-cols-12 gap-4">
                                    <div class="col-span-8">
                                        <div class="grid grid-cols-12 gap-4">
                                            <div
                                                v-for="task in mappedTasks"
                                                :key="task.id"
                                                class="col-span-12 rounded bg-slate-50 p-4 shadow-sm"
                                            >
                                                <div class="flex justify-between">
                                                    <div class="my-auto">
                                                        <h4 class="font-semibold">
                                                            {{ getFromDictionary(`task.${task.type}.title`) }}
                                                        </h4>
                                                        <div v-if="task.type === 'twitch-stream'" class="pt-1 text-sm">
                                                            <div v-if="task.due_at">
                                                                <span class="font-medium">Start Time:</span>
                                                                {{ $moment(task.due_at).format('MMM Do, YY @ h:mm A') }}
                                                            </div>
                                                            <div>
                                                                <span class="font-medium">Duration:</span>
                                                                {{
                                                                    $filters.humanizeDuration(
                                                                        task.payload.duration_seconds * 1000,
                                                                    )
                                                                }}
                                                            </div>
                                                            <div v-if="task.bid.expected_main_kpi">
                                                                <span class="font-medium">Expected ACV:</span>
                                                                {{ $numeral(task.bid.expected_main_kpi).format('0,0') }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else-if="task.type === 'youtube-video'"
                                                            class="pt-1 text-sm"
                                                        >
                                                            <div v-if="task.due_at">
                                                                Upload Time:
                                                                {{ $moment(task.due_at).format('MMM Do, YY @ h:mm A') }}
                                                            </div>
                                                            <div v-if="task.bid.expected_main_kpi">
                                                                Expected Views:
                                                                {{ $numeral(task.bid.expected_main_kpi).format('0,0') }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else-if="task.type === 'tiktok-video'"
                                                            class="pt-1 text-sm"
                                                        >
                                                            <div v-if="task.due_at">
                                                                Post Time:
                                                                {{ $moment(task.due_at).format('MMM Do, YY @ h:mm A') }}
                                                            </div>
                                                            <div v-if="task.bid.expected_main_kpi">
                                                                Expected Views:
                                                                {{ $numeral(task.bid.expected_main_kpi).format('0,0') }}
                                                            </div>
                                                            <div v-if="task?.payload?.video_count">
                                                                Videos created target:
                                                                {{ $numeral(task?.payload?.video_count).format('0,0') }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="my-auto flex">
                                                        <div v-if="task.payout_amount" class="text-right font-semibold">
                                                            <Tooltip
                                                                placement="top"
                                                                max-width="400"
                                                                class="h-full w-full"
                                                            >
                                                                <template #trigger>
                                                                    <div
                                                                        class="cursor-pointer rounded bg-slate-100 px-4 py-3"
                                                                    >
                                                                        <div class="text-lg text-slate-700">
                                                                            ${{
                                                                                $filters
                                                                                    .numeral(task.payout_amount)
                                                                                    .format('0,0.00')
                                                                            }}
                                                                            <template
                                                                                v-if="task?.payload?.video_count > 1"
                                                                            >
                                                                                per video
                                                                            </template>
                                                                        </div>

                                                                        <div
                                                                            v-if="
                                                                                task.total_payout_amount !==
                                                                                    task.payout_amount &&
                                                                                task?.payload?.video_count > 1
                                                                            "
                                                                            class="text-sm text-slate-700"
                                                                        >
                                                                            (${{
                                                                                $numeral(
                                                                                    task.total_payout_amount,
                                                                                ).format('0,0.00')
                                                                            }}
                                                                            total)
                                                                        </div>

                                                                        <div
                                                                            class="mt-1 flex justify-center rounded bg-emerald-100 px-2 py-1 text-center text-xs text-emerald-500"
                                                                            :class="{
                                                                                '!bg-emerald-100 !text-emerald-500':
                                                                                    task.bid.quality < -0.01,
                                                                                '!bg-rose-100 !text-rose-500':
                                                                                    task.bid.quality > 0.25,
                                                                            }"
                                                                        >
                                                                            <template v-if="task.bid.quality > 0.25">
                                                                                <span class="mr-1"
                                                                                    >{{
                                                                                        $numeral(
                                                                                            Math.abs(
                                                                                                task.bid.quality + 1,
                                                                                            ),
                                                                                        ).format('0.0')
                                                                                    }}x</span
                                                                                >
                                                                                <span>market rate</span>
                                                                            </template>
                                                                            <template
                                                                                v-else-if="task.bid.quality < -0.01"
                                                                            >
                                                                                <span class="mr-1">{{
                                                                                    $numeral(
                                                                                        Math.abs(task.bid.quality),
                                                                                    ).format('0%')
                                                                                }}</span>
                                                                                <span>below market</span>
                                                                            </template>
                                                                            <template v-else>
                                                                                <div class="mx-auto flex">
                                                                                    <font-awesome-icon
                                                                                        :icon="['fas', 'check']"
                                                                                        class="my-auto mr-1 h-3 w-3"
                                                                                    />
                                                                                    <span>market rate</span>
                                                                                </div>
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                                <template #default>
                                                                    <div class="whitespace-nowrap text-xs">
                                                                        <div
                                                                            class="grid grid-cols-2 border-b border-slate-400 pb-1"
                                                                        >
                                                                            <template
                                                                                v-if="selectedPlatformId === 'twitch'"
                                                                            >
                                                                                <!-- <div>Expected ACV</div>
                                                                                <div class="text-right text-white font-semibold">
                                                                                    {{
                                                                                        $numeral(
                                                                                            task.bid.expected_main_kpi
                                                                                        ).format('0,0')
                                                                                    }}
                                                                                </div> -->

                                                                                <div>Market Rate</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{ task.bid.market_rate }} per ACV
                                                                                </div>

                                                                                <div>Market Bid</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{
                                                                                        $numeral(
                                                                                            task.bid.market_bid,
                                                                                        ).format('0,0.00')
                                                                                    }}
                                                                                </div>
                                                                            </template>

                                                                            <template
                                                                                v-else-if="
                                                                                    selectedPlatformId === 'youtube'
                                                                                "
                                                                            >
                                                                                <!-- <div>Expected Views</div>
                                                                                <div class="text-right text-white font-semibold">
                                                                                    {{
                                                                                        $numeral(task.bid.expected_main_kpi).format('0,0')
                                                                                    }}
                                                                                </div> -->

                                                                                <div>Market Rate</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{ task.bid.market_rate }} per 1k
                                                                                    views
                                                                                </div>

                                                                                <div>Market Bid</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{
                                                                                        $numeral(
                                                                                            task.bid.market_bid,
                                                                                        ).format('0,0.00')
                                                                                    }}
                                                                                </div>
                                                                            </template>

                                                                            <template
                                                                                v-else-if="
                                                                                    selectedPlatformId === 'tiktok'
                                                                                "
                                                                            >
                                                                                <div>Market Rate</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{ task.bid.market_rate }} per 1k
                                                                                    views
                                                                                </div>

                                                                                <div>Market Bid</div>
                                                                                <div
                                                                                    class="text-right font-semibold text-white"
                                                                                >
                                                                                    ~${{
                                                                                        $numeral(
                                                                                            task.bid.market_bid,
                                                                                        ).format('0,0.00')
                                                                                    }}
                                                                                </div>
                                                                            </template>
                                                                        </div>
                                                                        <div class="grid grid-cols-2 pt-1">
                                                                            <div v-if="selectedPlatformId === 'twitch'">
                                                                                Cost per ACV
                                                                            </div>
                                                                            <div
                                                                                v-if="selectedPlatformId === 'youtube'"
                                                                            >
                                                                                CPM Cost
                                                                            </div>
                                                                            <div v-if="selectedPlatformId === 'tiktok'">
                                                                                CPM Cost
                                                                            </div>
                                                                            <div
                                                                                class="text-right font-semibold text-white"
                                                                            >
                                                                                ${{
                                                                                    $filters
                                                                                        .numeral(task.bid.main_kpi_rate)
                                                                                        .format('0,0.00')
                                                                                }}
                                                                            </div>

                                                                            <div>Creator Bid</div>
                                                                            <div
                                                                                class="text-right font-semibold text-emerald-300"
                                                                            >
                                                                                ${{
                                                                                    $filters
                                                                                        .numeral(task.payout_amount)
                                                                                        .format('0,0.00')
                                                                                }}
                                                                            </div>
                                                                            <!-- <div>Costs</div>
                                                                            <div class="text-right font-semibold text-yellow-300">${{ $filters.numeral(task.adjusted_payout_amount * task.commission).format('0,0.00') }}</div> -->
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-span-4">
                                        <div class="bg-slate-50 p-4 rounded shadow-sm">
                                            <div class="font-semibold">Total Payout</div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>

                            <!-- Tabs -->
                            <div class="relative mb-5 mt-5">
                                <div class="absolute bottom-0 h-px w-full bg-slate-200" aria-hidden="true" />

                                <ul
                                    class="no-scrollbar relative -mx-4 flex flex-nowrap text-sm font-medium sm:-mx-6 lg:-mx-8"
                                >
                                    <li
                                        v-for="tab in visibleSecondaryTabs"
                                        :key="tab.key"
                                        class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                                    >
                                        <a
                                            class="block cursor-pointer whitespace-nowrap py-3"
                                            :class="{
                                                'border-b-2 border-indigo-500 text-indigo-500':
                                                    state.tabs.secondary.activeTab === tab.key,
                                            }"
                                            @click="handleTabClick('secondary', tab.key)"
                                        >
                                            {{ tab.label }}
                                            <span
                                                v-if="tab.has_ai_label"
                                                class="ml-1 inline-flex rounded bg-slate-100 px-1.5 py-0.5 text-xs font-medium"
                                            >
                                                <span>AI</span>
                                                <font-awesome-icon
                                                    :icon="['fas', 'wand-magic-sparkles']"
                                                    class="my-auto ml-1 h-3 w-3"
                                                />
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'targeting'">
                                <div v-if="mappedTargetingGroups.length > 0">
                                    <div class="col-span-12 md:col-span-8">
                                        <div>
                                            <div class="mt-8 flex justify-between space-x-10">
                                                <div class="shrink">
                                                    <h2 class="font-semibold text-slate-800">Targeted Games</h2>
                                                    <p class="text-sm">
                                                        These are games the creator makes content for that fall within
                                                        each targeting group configured for the campaign.
                                                    </p>
                                                </div>

                                                <div class="ml-auto shrink-0 grow space-x-2">
                                                    <div
                                                        v-if="validContentTypes?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="tab in validContentTypes"
                                                            :key="tab"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.contentType === tab,
                                                            }"
                                                            @click.prevent="state.contentType = tab"
                                                        >
                                                            <span class="capitalize">{{ tab }}</span>
                                                        </button>
                                                    </div>

                                                    <div
                                                        v-if="validMetricWindows?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="metricWindow in validMetricWindows"
                                                            :key="metricWindow"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.metricWindow === metricWindow,
                                                            }"
                                                            @click.prevent="state.metricWindow = metricWindow"
                                                        >
                                                            <span class="capitalize">{{
                                                                getFromDictionary(
                                                                    `superview.metricWindow.${metricWindow}`,
                                                                )
                                                            }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-if="$filters.lodash.size(mappedTargetingGroups) > 0"
                                                class="mb-4 mt-4 flex space-x-2"
                                            >
                                                <div
                                                    v-for="(group, groupIndex) in mappedTargetingGroups"
                                                    :key="group.id"
                                                    class="cursor-pointer rounded bg-slate-100 px-4 py-2 text-sm font-semibold hover:bg-slate-200"
                                                    @click="state.selectedGroupIndex = groupIndex"
                                                >
                                                    <span
                                                        >{{ group.name }} ({{
                                                            $lodash.size(group.inGroup.games)
                                                        }})</span
                                                    >
                                                </div>
                                            </div>

                                            <table class="mt-4 w-full table-auto">
                                                <!-- Table header -->
                                                <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                                                    <tr>
                                                        <th
                                                            class="w-1/2 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                                        >
                                                            <div class="text-left font-semibold">Game</div>
                                                        </th>
                                                        <th
                                                            class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                                        >
                                                            <div
                                                                v-if="selectedPlatformId === 'twitch'"
                                                                class="text-left font-semibold"
                                                            >
                                                                Creator<br />Streamed
                                                            </div>
                                                            <div
                                                                v-if="selectedPlatformId === 'youtube'"
                                                                class="text-left font-semibold"
                                                            >
                                                                Videos<br />Created
                                                            </div>
                                                        </th>
                                                        <th
                                                            class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                                        >
                                                            <div
                                                                v-if="selectedPlatformId === 'twitch'"
                                                                class="text-left font-semibold"
                                                            >
                                                                AVG<br />Viewers
                                                            </div>
                                                            <div
                                                                v-if="selectedPlatformId === 'youtube'"
                                                                class="text-left font-semibold"
                                                            >
                                                                AVG<br />Views
                                                            </div>
                                                        </th>
                                                        <th
                                                            class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                                        >
                                                            <div class="text-left font-semibold">
                                                                Audience<br />Reception
                                                            </div>
                                                        </th>
                                                        <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/6">
                                                        <div class="font-semibold text-left">Global AVG<br />Streamed</div>
                                                    </th> -->
                                                        <th
                                                            class="w-1/6 whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5"
                                                        >
                                                            <div class="font-semibold">Affinity</div>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody class="divide-y divide-slate-200 text-sm">
                                                    <tr
                                                        v-for="game in selectedGroup.inGroup.games"
                                                        :key="game.game_id"
                                                        class="group"
                                                    >
                                                        <td
                                                            class="flex-col items-center px-2 py-1.5 first:pl-5 last:pr-5"
                                                        >
                                                            <div class="flex">
                                                                <div
                                                                    class="relative my-auto flex h-8 w-16 shrink-0 overflow-hidden rounded bg-slate-200 bg-cover bg-center"
                                                                    :style="{
                                                                        'background-image': `url(${`https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${selectedPlatformId}/games/thumbnails/default/${game.game_id}`})`,
                                                                    }"
                                                                >
                                                                    <Tooltip
                                                                        v-if="
                                                                            game?.creator_accumulation
                                                                                ?.sponsored_count > 0
                                                                        "
                                                                        placement="top"
                                                                        max-width="400"
                                                                        class="h-full w-full"
                                                                    >
                                                                        <template #trigger>
                                                                            <div
                                                                                class="absolute right-0 top-0 flex h-full w-full bg-gradient-to-b from-transparent to-slate-900 pb-1"
                                                                            >
                                                                                <font-awesome-icon
                                                                                    :icon="['fas', 'dollar-sign']"
                                                                                    class="mx-auto my-auto h-4 w-4 text-green-300 drop-shadow-lg"
                                                                                />
                                                                            </div>

                                                                            <div
                                                                                class="absolute bottom-0 left-0 h-1 w-full rounded-bl rounded-br bg-green-300"
                                                                            ></div>
                                                                        </template>
                                                                        <template #default>
                                                                            <div class="whitespace-nowrap text-xs">
                                                                                Creator had a
                                                                                <span class="font-semibold text-white"
                                                                                    >#sponsored</span
                                                                                >
                                                                                indicator in their title
                                                                                <span class="font-semibold text-white"
                                                                                    >{{
                                                                                        game.creator_accumulation
                                                                                            .sponsored_count
                                                                                    }}
                                                                                    times</span
                                                                                >
                                                                            </div>
                                                                        </template>
                                                                    </Tooltip>
                                                                </div>

                                                                <div class="my-auto ml-3 flex-col leading-none">
                                                                    <div
                                                                        class="text-md line-clamp-1 whitespace-normal font-semibold"
                                                                        :title="
                                                                            gameSuperviewsById[game.game_id]
                                                                                ._display_name
                                                                        "
                                                                    >
                                                                        {{
                                                                            gameSuperviewsById[game.game_id]
                                                                                ._display_name
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        v-if="selectedPlatformId === 'twitch'"
                                                                        class="text-xs"
                                                                    >
                                                                        <div>
                                                                            Last streamed
                                                                            {{
                                                                                $filters
                                                                                    .moment(
                                                                                        game.creator_accumulation
                                                                                            .last_stream_ended_at,
                                                                                    )
                                                                                    .format('MMM Do, YYYY')
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        v-else-if="selectedPlatformId === 'youtube'"
                                                                        class="flex text-xs"
                                                                    >
                                                                        <div>
                                                                            Last video at
                                                                            {{
                                                                                $filters
                                                                                    .moment(
                                                                                        game.creator_accumulation
                                                                                            .last_video_at,
                                                                                    )
                                                                                    .format('MMM Do, YYYY')
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <div
                                                                v-if="
                                                                    selectedPlatformId === 'twitch' &&
                                                                    game.creator_accumulation.duration_seconds
                                                                "
                                                                class="flex"
                                                            >
                                                                <div class="my-auto leading-none">
                                                                    {{
                                                                        $filters
                                                                            .numeral(
                                                                                game.creator_accumulation
                                                                                    .duration_seconds /
                                                                                    (60 * 60),
                                                                            )
                                                                            .format('0,0')
                                                                    }}h
                                                                </div>
                                                                <div
                                                                    class="my-auto ml-1 text-xs leading-none"
                                                                    :class="{
                                                                        'text-rose-500':
                                                                            game.raw_difference_percent < 0,
                                                                        'text-emerald-500':
                                                                            game.raw_difference_percent > 0,
                                                                    }"
                                                                >
                                                                    ({{
                                                                        $filters
                                                                            .numeral(game.raw_difference_percent)
                                                                            .format('+0,0%')
                                                                    }})
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else-if="
                                                                    selectedPlatformId === 'youtube' &&
                                                                    game.creator_accumulation.count
                                                                "
                                                                class="flex"
                                                            >
                                                                <div class="my-auto leading-none">
                                                                    {{
                                                                        $filters
                                                                            .numeral(game.creator_accumulation.count)
                                                                            .format('0,0')
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="my-auto ml-1 text-xs leading-none"
                                                                    :class="{
                                                                        'text-rose-500':
                                                                            game.raw_difference_percent < 0,
                                                                        'text-emerald-500':
                                                                            game.raw_difference_percent > 0,
                                                                    }"
                                                                >
                                                                    ({{
                                                                        $filters
                                                                            .numeral(game.raw_difference_percent)
                                                                            .format('+0,0%')
                                                                    }})
                                                                </div>
                                                            </div>
                                                            <div v-else>---</div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <div
                                                                v-if="
                                                                    selectedPlatformId === 'twitch' &&
                                                                    game.creator_accumulation.avg_viewers
                                                                "
                                                                class="flex"
                                                            >
                                                                <div class="my-auto leading-none">
                                                                    {{
                                                                        $filters
                                                                            .numeral(
                                                                                game.creator_accumulation.avg_viewers,
                                                                            )
                                                                            .format('0,0')
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else-if="
                                                                    selectedPlatformId === 'youtube' &&
                                                                    game.creator_accumulation.count
                                                                "
                                                                class="flex"
                                                            >
                                                                <div class="my-auto leading-none">
                                                                    {{
                                                                        $filters
                                                                            .numeral(
                                                                                game.creator_accumulation.avg_views,
                                                                            )
                                                                            .format('0,0')
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div v-else>---</div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <!-- <span
                                                            :class="{
                                                                'text-rose-500': game.creator_accumulation.avg_acv_performance < 0,
                                                                'text-emerald-500': game.creator_accumulation.avg_acv_performance > 0
                                                            }"
                                                        >
                                                            {{ $filters.numeral(game.creator_accumulation.avg_acv_performance).format('+0,0%') }}
                                                        </span> -->

                                                            <AudienceReceptionPlate
                                                                :viewership-change="game.audience_reception"
                                                            />
                                                        </td>
                                                        <!-- <td class="px-2 first:pl-5 last:pr-5 py-3.5 whitespace-nowrap">
                                                        {{
                                                            $filters.humanizeDurationShort(
                                                                Math.round(game.game_superview.stream.day_365.avg_sum_duration_per_channel) * 1000
                                                            )
                                                        }}
                                                    </td> -->
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <Tooltip
                                                                placement="top"
                                                                max-width="400"
                                                                class="h-full w-full cursor-pointer"
                                                            >
                                                                <template #trigger>
                                                                    <AffinityPlate :affinity-percent="game.affinity" />
                                                                </template>
                                                                <template #default>
                                                                    <div class="text-xs">
                                                                        <div class="font-semibold text-white">
                                                                            Affinity
                                                                        </div>
                                                                        <p>
                                                                            Affinity is a representation of how good a
                                                                            game is for a creator's channel. The formula
                                                                            is a combination of the creator and their
                                                                            audience's preferences.
                                                                        </p>

                                                                        <div class="mt-3 font-semibold text-white">
                                                                            Creator Preference
                                                                        </div>
                                                                        <p v-if="selectedPlatformId === 'twitch'">
                                                                            The creator streamed
                                                                            <span class="font-semibold text-white">{{
                                                                                game.creator_accumulation.name
                                                                            }}</span>
                                                                            for
                                                                            <span class="font-semibold text-white"
                                                                                >{{
                                                                                    $numeral(
                                                                                        game.creator_accumulation
                                                                                            .duration_seconds /
                                                                                            (60 * 60),
                                                                                    ).format('0,0')
                                                                                }}
                                                                                hours
                                                                            </span>
                                                                            on their channel. This is
                                                                            <span class="font-semibold text-white"
                                                                                >{{
                                                                                    $numeral(
                                                                                        Math.abs(
                                                                                            game.raw_difference_percent,
                                                                                        ),
                                                                                    ).format('0,0%')
                                                                                }}
                                                                                {{
                                                                                    game.raw_difference_percent >= 0
                                                                                        ? 'more'
                                                                                        : 'less'
                                                                                }}</span
                                                                            >
                                                                            than an average creator would stream it for.
                                                                        </p>
                                                                        <p v-else-if="selectedPlatformId === 'youtube'">
                                                                            The creator made
                                                                            <span class="font-semibold text-white">{{
                                                                                game.creator_accumulation.count
                                                                            }}</span>
                                                                            videos showcasing
                                                                            <span class="font-semibold text-white">{{
                                                                                game.creator_accumulation.name
                                                                            }}</span>
                                                                            on their channel. This is
                                                                            <span class="font-semibold text-white"
                                                                                >{{
                                                                                    $numeral(
                                                                                        Math.abs(
                                                                                            game.raw_difference_percent,
                                                                                        ),
                                                                                    ).format('0,0%')
                                                                                }}
                                                                                {{
                                                                                    game.raw_difference_percent >= 0
                                                                                        ? 'more'
                                                                                        : 'less'
                                                                                }}</span
                                                                            >
                                                                            videos than an average creator would make
                                                                            for this game.
                                                                        </p>

                                                                        <div class="mt-3 font-semibold text-white">
                                                                            Audience Preference
                                                                        </div>
                                                                        <p>
                                                                            Compared to their main niche of content,
                                                                            when the creator makes content about
                                                                            <span class="font-semibold text-white">{{
                                                                                game.creator_accumulation.name
                                                                            }}</span>
                                                                            their viewership
                                                                            <span class="font-semibold text-white"
                                                                                >{{
                                                                                    game.audience_reception > 0
                                                                                        ? 'increases'
                                                                                        : 'decreases'
                                                                                }}
                                                                                by
                                                                                {{
                                                                                    $numeral(
                                                                                        Math.abs(
                                                                                            game.audience_reception,
                                                                                        ),
                                                                                    ).format('0,0%')
                                                                                }}</span
                                                                            >.
                                                                        </p>
                                                                    </div>
                                                                </template>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>

                                                    <tr
                                                        v-if="
                                                            selectedGroup.inGroup.games &&
                                                            selectedGroup.inGroup.games.length === 0
                                                        "
                                                    >
                                                        <td colspan="100" class="py-3 text-center">
                                                            <p class="py-3 text-center">
                                                                No games in the defined targeting group
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <PaginationDiscover
                                                v-if="
                                                    selectedGroup &&
                                                    selectedGroup.inGroup.games_count >
                                                        state.games['inGroup'].itemsPerPage
                                                "
                                                class="mt-4"
                                                :total-pages="
                                                    Math.ceil(
                                                        selectedGroup.inGroup.games_count /
                                                            state.games['inGroup'].itemsPerPage,
                                                    )
                                                "
                                                :per-page="state.games['inGroup'].itemsPerPage"
                                                :current-page="state.games['inGroup'].pageIndex + 1"
                                                @pagechanged="
                                                    (newPageIndex) =>
                                                        (state.games['inGroup'].pageIndex = newPageIndex - 1)
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'all_interests'">
                                <div>
                                    <div class="col-span-12 md:col-span-8">
                                        <div>
                                            <div class="mt-8 flex justify-between space-x-10">
                                                <div class="shrink">
                                                    <h2 class="font-semibold text-slate-800">Creator Interests</h2>
                                                    <p class="text-sm">
                                                        These are all interests the creator has and their affinities to
                                                        those interests.
                                                    </p>
                                                </div>
                                            </div>

                                            <div v-if="biography?.features?.interests?.length" class="mt-4">
                                                <CreatorInterestsTable
                                                    :platform-id="selectedPlatformId"
                                                    :data="biography.features.interests"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'all_content'">
                                <div>
                                    <div class="col-span-12 md:col-span-8">
                                        <div>
                                            <div class="mt-8 flex justify-between space-x-10">
                                                <div class="shrink">
                                                    <h2 class="font-semibold text-slate-800">All Content</h2>
                                                    <p class="text-sm">
                                                        This is all the recent content the creator has posted in the
                                                        last year.
                                                    </p>
                                                </div>

                                                <div class="ml-auto shrink-0 space-x-2">
                                                    <div
                                                        v-if="validContentTypes?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="tab in validContentTypes"
                                                            :key="tab"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.contentType === tab,
                                                            }"
                                                            @click.prevent="state.contentType = tab"
                                                        >
                                                            <span class="capitalize">{{ tab }}</span>
                                                        </button>
                                                    </div>

                                                    <div
                                                        v-if="validMetricWindows?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="metricWindow in validMetricWindows"
                                                            :key="metricWindow"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.metricWindow === metricWindow,
                                                            }"
                                                            @click.prevent="state.metricWindow = metricWindow"
                                                        >
                                                            <span class="capitalize">{{
                                                                getFromDictionary(
                                                                    `superview.metricWindow.${metricWindow}`,
                                                                )
                                                            }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4">
                                                <CreatorContentTable
                                                    :platform-id="selectedPlatformId"
                                                    :platform-user-id="selectedPlatformUserId"
                                                    :platform-user-name="superview.normalized.name"
                                                    :content-type="state.contentType"
                                                    :day-range="state.metricWindow"
                                                    :games="
                                                        superview[state.contentType]?.[state.metricWindow]?.accumulation
                                                            ?.games?.lookup
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'all_tags'">
                                <div>
                                    <div class="col-span-12 md:col-span-8">
                                        <div>
                                            <div class="mt-8 flex justify-between space-x-10">
                                                <div class="shrink">
                                                    <h2 class="font-semibold text-slate-800">All Creator Hashtags</h2>
                                                    <p class="text-sm">
                                                        These are all tags the creator makes content for and their
                                                        affinities to those tags.
                                                    </p>
                                                </div>

                                                <div class="ml-auto shrink-0 space-x-2">
                                                    <div
                                                        v-if="validContentTypes?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="tab in validContentTypes"
                                                            :key="tab"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.contentType === tab,
                                                            }"
                                                            @click.prevent="state.contentType = tab"
                                                        >
                                                            <span class="capitalize">{{ tab }}</span>
                                                        </button>
                                                    </div>

                                                    <div
                                                        v-if="validMetricWindows?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="metricWindow in validMetricWindows"
                                                            :key="metricWindow"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.metricWindow === metricWindow,
                                                            }"
                                                            @click.prevent="state.metricWindow = metricWindow"
                                                        >
                                                            <span class="capitalize">{{
                                                                getFromDictionary(
                                                                    `superview.metricWindow.${metricWindow}`,
                                                                )
                                                            }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4">
                                                <CreatorHashtagsTable
                                                    :platform-id="selectedPlatformId"
                                                    :platform-user-id="selectedPlatformUserId"
                                                    :content-type="state.contentType"
                                                    :day-range="state.metricWindow"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'all_games'">
                                <div>
                                    <div class="col-span-12 md:col-span-8">
                                        <div>
                                            <div class="mt-8 flex justify-between space-x-10">
                                                <div class="shrink">
                                                    <h2 class="font-semibold text-slate-800">All Creator Games</h2>
                                                    <p class="text-sm">
                                                        These are all games the creator makes content for and their
                                                        affinities to those games.
                                                    </p>
                                                </div>

                                                <div class="ml-auto shrink-0 space-x-2">
                                                    <div
                                                        v-if="validContentTypes?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="tab in validContentTypes"
                                                            :key="tab"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.contentType === tab,
                                                            }"
                                                            @click.prevent="state.contentType = tab"
                                                        >
                                                            <span class="capitalize">{{ tab }}</span>
                                                        </button>
                                                    </div>

                                                    <div
                                                        v-if="validMetricWindows?.length > 1"
                                                        class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                                        role="group"
                                                    >
                                                        <button
                                                            v-for="metricWindow in validMetricWindows"
                                                            :key="metricWindow"
                                                            class="flex rounded px-3 py-0.5 text-xs font-semibold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                                            :class="{
                                                                'bg-white': state.metricWindow === metricWindow,
                                                            }"
                                                            @click.prevent="state.metricWindow = metricWindow"
                                                        >
                                                            <span class="capitalize">{{
                                                                getFromDictionary(
                                                                    `superview.metricWindow.${metricWindow}`,
                                                                )
                                                            }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-4">
                                                <CreatorGamesTable :platform-id="selectedPlatformId" :data="allGames" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'shared_audience'">
                                <div class="mt-8">
                                    <div>
                                        <h2 class="font-semibold text-slate-800">Shared Audiences</h2>
                                        <p class="text-sm">
                                            These are creators that share an audience with
                                            {{ superview.normalized.display_name }}.
                                        </p>
                                    </div>

                                    <template
                                        v-if="
                                            !state.relatedCreators.isLoading &&
                                            state.relatedCreators.records &&
                                            state.relatedCreators.records.length === 0
                                        "
                                    >
                                        <div class="mt-5 rounded bg-slate-50 p-5 text-center">
                                            <p class="leading-none">
                                                It seems we can't find any creators with a shared audience.
                                            </p>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <table class="mt-4 w-full table-auto">
                                            <!-- Table header -->
                                            <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                                                <tr>
                                                    <th class="w-1/2 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                        <div class="text-left font-semibold">Creator</div>
                                                    </th>
                                                    <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                        <div class="text-left font-semibold">Locale</div>
                                                    </th>
                                                    <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                        <div
                                                            v-if="selectedPlatformId === 'twitch'"
                                                            class="text-left font-semibold"
                                                        >
                                                            AVG Viewers
                                                        </div>
                                                        <div
                                                            v-if="selectedPlatformId === 'youtube'"
                                                            class="text-left font-semibold"
                                                        >
                                                            AVG Views
                                                        </div>
                                                    </th>
                                                    <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                        <div
                                                            v-if="selectedPlatformId === 'twitch'"
                                                            class="text-left font-semibold"
                                                        >
                                                            Followers
                                                        </div>
                                                        <div
                                                            v-if="selectedPlatformId === 'youtube'"
                                                            class="text-left font-semibold"
                                                        >
                                                            Subscribers
                                                        </div>
                                                    </th>
                                                    <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                        <div class="text-left font-semibold">Strength</div>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="divide-y divide-slate-200 text-sm">
                                                <template v-if="state.relatedCreators.isLoading">
                                                    <tr v-for="i in 10" :key="i">
                                                        <td v-for="j in 5" :key="j" class="px-2 py-2">
                                                            <div
                                                                class="h-5 w-full animate-pulse rounded bg-slate-100"
                                                            ></div>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template
                                                    v-else-if="
                                                        !state.relatedCreators.isLoading &&
                                                        state.relatedCreators.records &&
                                                        state.relatedCreators.records.length
                                                    "
                                                >
                                                    <tr
                                                        v-for="{ superview, relation } in visibleRelatedCreators"
                                                        :key="superview._id"
                                                        class="group"
                                                    >
                                                        <td
                                                            class="flex-col items-center whitespace-nowrap px-2 first:pl-5 last:pr-5"
                                                        >
                                                            <div class="relative flex w-full items-center">
                                                                <NamePlate
                                                                    class="my-auto"
                                                                    :logo="`https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/twitch/avatars/small/${superview._id}`"
                                                                    :name="superview.normalized.name"
                                                                    :display-name="superview.normalized.display_name"
                                                                    :platform-id="superview.platform_id"
                                                                    :platform-user-id="superview._id"
                                                                    :is-drawer-enabled="false"
                                                                />

                                                                <router-link
                                                                    class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                                    :to="{
                                                                        path: $route.path,
                                                                        query: {
                                                                            ...$route.query,
                                                                            modal: 'creator_affinity_breakdown',
                                                                            platform_id: superview.platform_id,
                                                                            platform_user_id: superview._id,
                                                                        },
                                                                    }"
                                                                >
                                                                    <div
                                                                        class="invisible right-0 top-0 my-auto flex h-full cursor-pointer rounded bg-slate-100 px-2 py-0.5 text-xs font-semibold transition duration-200 group-hover:visible hover:bg-slate-200"
                                                                    >
                                                                        <span class="my-auto flex uppercase">
                                                                            <font-awesome-icon
                                                                                :icon="['fas', 'rectangle-list']"
                                                                                class="my-auto mr-1 h-6 w-4"
                                                                            />
                                                                            <span class="my-auto leading-none"
                                                                                >Open</span
                                                                            >
                                                                        </span>
                                                                    </div>
                                                                </router-link>

                                                                <!-- <div
                                                                    class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                                    @click="
                                                                        $store.commit('modal:setData', {
                                                                            modal_id: 'creator',
                                                                            data: {
                                                                                is_open: true,
                                                                                platform_id: superview.platform_id,
                                                                                platform_user_id: superview._id,
                                                                            },
                                                                        })
                                                                    "
                                                                >
                                                                    <span class="my-auto flex uppercase">
                                                                        <font-awesome-icon
                                                                            :icon="['fas', 'rectangle-list']"
                                                                            class="my-auto mr-1 h-4 w-4"
                                                                        />
                                                                        <span class="my-auto leading-none">Open</span>
                                                                    </span>
                                                                </div> -->
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            {{ superview?.normalized?.language?.toUpperCase() }}
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <span v-if="superview.platform_id === 'twitch'">
                                                                {{
                                                                    $numeral(
                                                                        superview?.stream?.day_90?.aggregation?.viewers
                                                                            ?.avg,
                                                                    ).format('0,0')
                                                                }}
                                                            </span>
                                                            <span v-if="superview.platform_id === 'youtube'">
                                                                {{
                                                                    $numeral(
                                                                        superview?.video?.day_90?.aggregation?.views
                                                                            ?.avg,
                                                                    ).format('0,0')
                                                                }}
                                                            </span>
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            {{
                                                                $numeral(superview?.normalized?.followers).format('0,0')
                                                            }}
                                                        </td>
                                                        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                                            <div class="w-22 relative h-4 rounded bg-slate-100">
                                                                <div
                                                                    class="absolute inset-0 h-full rounded bg-indigo-400"
                                                                    :style="{
                                                                        width: `${
                                                                            (relation.rank / highestRelatedStrength) *
                                                                                50 +
                                                                            50
                                                                        }%`,
                                                                    }"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>

                                                <template
                                                    v-else-if="
                                                        !state.relatedCreators.isLoading &&
                                                        state.relatedCreators.records &&
                                                        !state.relatedCreators.records.length
                                                    "
                                                >
                                                    <tr>
                                                        <td colspan="100">
                                                            <p>
                                                                We were unable to find any creators that shared an
                                                                audience.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>

                                        <div class="ml-4 mr-4 mt-4">
                                            <DiscoveryPagination
                                                :paging="state.relatedCreators.paging"
                                                @paging-changed="onSharedCreatorsPagingChange"
                                            />
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'content_analysis'" class="mt-8">
                                <div>
                                    <h2 class="font-semibold text-slate-800">Content Analysis (Beta)</h2>
                                    <p class="text-sm">
                                        Explore AI-generated insights that offer a multi-faceted understanding of each
                                        content creator.
                                    </p>
                                </div>

                                <div v-if="state.contentAnalysis.isLoading" class="mt-4">
                                    <div class="mt-4 grid grid-cols-2 gap-4">
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                    </div>

                                    <div
                                        class="mt-4 w-full animate-pulse rounded bg-slate-100 fill-current p-3 text-center text-sm shadow-sm"
                                    >
                                        This could take up to 60 seconds...
                                    </div>

                                    <div class="mt-4 grid grid-cols-2 gap-4">
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                        <div
                                            class="col-span-1 h-16 w-full animate-pulse rounded bg-slate-100 fill-current shadow-sm"
                                        ></div>
                                    </div>
                                </div>

                                <!--  Demographics -->
                                <div v-else-if="!state.contentAnalysis.isLoading && contentAnalysis" class="mt-4">
                                    <div class="mt-10 grid grid-cols-12 gap-4 border-t border-slate-200 pt-8">
                                        <div
                                            v-if="contentAnalysis?.archetypes?.primary_archetype"
                                            class="col-span-6 mb-4"
                                        >
                                            <div class="mb-4">
                                                <h2 class="font-semibold text-slate-800">Archetype</h2>
                                                <p class="text-sm">
                                                    A creator archetype classifies a content creator by style, content,
                                                    and audience, offering a snapshot of their online persona.
                                                </p>
                                            </div>

                                            <div class="flex rounded bg-slate-50 p-3">
                                                <div class="flex">
                                                    <div class="m-auto rounded-full bg-slate-200 p-3">
                                                        <font-awesome-icon
                                                            v-if="
                                                                getFromDictionary(
                                                                    `superview.archetypes.${contentAnalysis?.archetypes?.primary_archetype?.name}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            :icon="
                                                                getFromDictionary(
                                                                    `superview.archetypes.${contentAnalysis?.archetypes?.primary_archetype?.name}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            class="h-6 w-6"
                                                        />
                                                        <font-awesome-icon
                                                            v-else
                                                            :icon="['fas', 'user']"
                                                            class="h-6 w-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="ml-4">
                                                    <div class="text-xl font-semibold">
                                                        {{ contentAnalysis?.archetypes?.primary_archetype?.name }}
                                                    </div>
                                                    <div class="flex flex-wrap">
                                                        <div
                                                            v-for="motivation in contentAnalysis?.motivations"
                                                            :key="motivation.name"
                                                            class="mb-1 mr-1 rounded bg-slate-100 px-1.5 py-0.5 text-xs font-medium"
                                                        >
                                                            {{ motivation.name }}
                                                        </div>
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            contentAnalysis?.archetypes?.primary_archetype
                                                                ?.how_you_know_this
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="contentAnalysis.safety_rating" class="col-span-6 mb-4">
                                            <div class="mb-4">
                                                <h2 class="font-semibold text-slate-800">Safety Rating</h2>
                                                <p class="text-sm">
                                                    Find out how appropriate the creator's content is for audiences,
                                                    based on the actual content instead of the creator's self
                                                    disclosure.
                                                </p>
                                            </div>

                                            <div class="flex rounded bg-slate-50 p-3">
                                                <div class="flex">
                                                    <div
                                                        class="m-auto flex h-12 w-12 rounded-full bg-slate-200 font-bold uppercase"
                                                    >
                                                        <span
                                                            v-if="contentAnalysis.safety_rating.rating === 'PG-13'"
                                                            class="m-auto text-center text-sm leading-none"
                                                        >
                                                            PG<br />13
                                                        </span>
                                                        <span v-else class="m-auto text-center leading-none">
                                                            {{ contentAnalysis.safety_rating.rating }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="ml-4">
                                                    <div class="font-semibold">
                                                        {{
                                                            getFromDictionary(
                                                                `superview.safetyRating.${contentAnalysis?.safety_rating?.rating}`,
                                                                'label',
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{ contentAnalysis?.safety_rating?.explanation }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-span-6 mb-4">
                                            <div class="mb-4">
                                                <h2 class="font-semibold text-slate-800">Metadata</h2>
                                                <p class="text-sm">
                                                    Personal and demographic details about the creator to get a fuller
                                                    picture of their identity in real life.
                                                </p>
                                            </div>

                                            <div class="rounded bg-slate-50 p-4">
                                                <div class="divide-y border-slate-100 text-sm">
                                                    <div class="flex justify-between py-1.5">
                                                        <div>Gender</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.gender?.answer || '---' }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>Age Range</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.age?.answer || '---' }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>First Name</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.real_life_first_name?.answer || '---' }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>Last Name</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.real_life_last_name?.answer || '---' }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>Country of Residence</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.country_of_residence?.answer || '---' }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>Province of Residence</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{
                                                                contentAnalysis?.province_of_residence?.answer || '---'
                                                            }}
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-between py-1.5">
                                                        <div>City of Residence</div>
                                                        <div class="font-medium text-slate-800">
                                                            {{ contentAnalysis?.city_of_residence?.answer || '---' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-6 border-t border-slate-200 pt-8">
                                        <h2 class="font-semibold text-slate-800">Personality Traits</h2>
                                        <p class="text-sm">This is an analysis of the creator's personality traits.</p>
                                    </div>

                                    <div class="mt-4 grid grid-cols-12 gap-4">
                                        <div
                                            v-for="trait in contentAnalysis.personality_traits"
                                            :key="trait.personality_trait"
                                            class="col-span-6"
                                        >
                                            <div class="flex rounded bg-slate-50 px-4 py-4 pr-6">
                                                <div class="flex">
                                                    <div class="m-auto rounded-full bg-slate-200 p-3">
                                                        <font-awesome-icon
                                                            v-if="
                                                                getFromDictionary(
                                                                    `superview.personalityTrait.${trait.personality_trait}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            :icon="
                                                                getFromDictionary(
                                                                    `superview.personalityTrait.${trait.personality_trait}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            class="h-6 w-6"
                                                        />
                                                        <font-awesome-icon
                                                            v-else
                                                            :icon="['fas', 'user']"
                                                            class="h-6 w-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="ml-4">
                                                    <div class="font-semibold">{{ trait.personality_trait }}</div>
                                                    <div class="text-sm">{{ trait.explanation }}</div>

                                                    <div class="mt-3 flex space-x-2">
                                                        <div
                                                            class="whitespace-nowrap rounded bg-indigo-400 px-2 py-0.5 text-xs font-semibold text-white"
                                                        >
                                                            {{ Math.round(trait.confidence_score / 10)
                                                            }}<span class="px-0.5">/</span>10
                                                        </div>
                                                        <div class="my-auto flex w-full space-x-1">
                                                            <div
                                                                v-for="index in 10"
                                                                :key="index"
                                                                class="h-2 w-full rounded-sm bg-slate-200"
                                                                :class="{
                                                                    '!bg-indigo-300':
                                                                        index <=
                                                                        Math.round(trait.confidence_score / 10),
                                                                }"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-10 border-t border-slate-200 pt-8">
                                        <h2 class="font-semibold text-slate-800">Content Styles</h2>
                                        <p class="text-sm">
                                            This is an analysis of what styles of content the creator makes on their
                                            channel.
                                        </p>
                                    </div>

                                    <div class="mt-4 grid grid-cols-12 gap-4">
                                        <div
                                            v-for="trait in contentAnalysis.content_styles"
                                            :key="trait.content_style"
                                            class="col-span-6"
                                        >
                                            <div class="flex rounded bg-slate-50 px-4 py-4 pr-6">
                                                <div class="flex">
                                                    <div class="m-auto rounded-full bg-slate-200 p-3">
                                                        <font-awesome-icon
                                                            v-if="
                                                                getFromDictionary(
                                                                    `superview.contentStyles.${trait.content_style_name}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            :icon="
                                                                getFromDictionary(
                                                                    `superview.contentStyles.${trait.content_style_name}`,
                                                                    'icon',
                                                                )
                                                            "
                                                            class="h-6 w-6"
                                                        />
                                                        <font-awesome-icon
                                                            v-else
                                                            :icon="['fas', 'user']"
                                                            class="h-6 w-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="ml-4">
                                                    <div class="font-semibold">{{ trait.content_style_name }}</div>
                                                    <div class="text-sm">{{ trait.explanation }}</div>

                                                    <div class="mt-3 flex space-x-2">
                                                        <div
                                                            class="whitespace-nowrap rounded bg-blue-400 px-2 py-0.5 text-xs font-semibold text-white"
                                                        >
                                                            {{ Math.round(trait.confidence_score / 10)
                                                            }}<span class="px-0.5">/</span>10
                                                        </div>
                                                        <div class="my-auto flex w-full space-x-1">
                                                            <div
                                                                v-for="index in 10"
                                                                :key="index"
                                                                class="h-2 w-full rounded-sm bg-slate-200"
                                                                :class="{
                                                                    '!bg-blue-300':
                                                                        index <=
                                                                        Math.round(trait.confidence_score / 10),
                                                                }"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-else-if="!state.contentAnalysis.isLoading && contentAnalysis === false"
                                    class="mt-4"
                                >
                                    <div class="rounded border border-slate-100 p-3">
                                        <p class="text-center text-sm">
                                            It seems we can't perform a content analysis for
                                            {{ superview.normalized.name }}.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'demographics'" class="mt-8">
                                <div v-if="!state.demographics.isLoading && demographics">
                                    <h2 class="font-semibold text-slate-800">Demographics</h2>
                                    <p class="text-sm">
                                        Here you can find demographic data about the audience of
                                        {{ superview.normalized.name }}.
                                    </p>
                                </div>

                                <div v-if="state.demographics.isLoading" class="mt-4">
                                    <SkeletonCreatorProfileDemographics />
                                </div>

                                <!--  Demographics -->
                                <div v-else-if="!state.demographics.isLoading && demographics" class="mt-4">
                                    <div class="grid grid-cols-12 gap-6">
                                        <!-- Country -->
                                        <div
                                            v-if="demographics?.byCountryCode"
                                            class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                                        >
                                            <header class="border-b border-slate-100 px-5 py-4">
                                                <h2 class="font-semibold text-slate-800">Countries</h2>
                                            </header>

                                            <div class="grow p-3">
                                                <div class="flex h-full flex-col">
                                                    <div class="grow">
                                                        <ul
                                                            class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                                        >
                                                            <li>Country</li>
                                                            <li>Percent</li>
                                                        </ul>
                                                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                                            <!-- Item -->
                                                            <li
                                                                v-for="item in demographics.byCountryCode"
                                                                :key="item.key"
                                                                class="relative px-2 py-1"
                                                            >
                                                                <template v-if="item.key !== 'undefined'">
                                                                    <div
                                                                        class="absolute inset-0 bg-indigo-100"
                                                                        aria-hidden="true"
                                                                        :style="{
                                                                            width: `${item.percent}%`,
                                                                        }"
                                                                    />
                                                                    <div
                                                                        class="relative flex justify-between space-x-2"
                                                                    >
                                                                        <div>
                                                                            <span
                                                                                v-if="item.key != 'OTHER'"
                                                                                class="font-semibold uppercase"
                                                                                >{{ item.key }}</span
                                                                            >
                                                                            <span class="ml-1">
                                                                                {{ item.label }}
                                                                            </span>
                                                                        </div>
                                                                        <div class="font-medium">
                                                                            {{
                                                                                $numeral(item.percent / 100).format(
                                                                                    '0.0%',
                                                                                )
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- City -->
                                        <div
                                            v-if="demographics.byCityCode && demographics.byCityCode.length > 0"
                                            class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                                        >
                                            <header class="border-b border-slate-100 px-5 py-4">
                                                <h2 class="font-semibold text-slate-800">Cities</h2>
                                            </header>

                                            <div class="grow p-3">
                                                <div class="flex h-full flex-col">
                                                    <div class="grow">
                                                        <ul
                                                            class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                                        >
                                                            <li>City</li>
                                                            <li>Percent</li>
                                                        </ul>
                                                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                                            <!-- Item -->
                                                            <li
                                                                v-for="item in demographics.byCityCode"
                                                                :key="item.key"
                                                                class="relative px-2 py-1"
                                                            >
                                                                <div
                                                                    class="absolute inset-0 bg-indigo-100"
                                                                    aria-hidden="true"
                                                                    :style="{
                                                                        width: `${item.percent}%`,
                                                                    }"
                                                                />
                                                                <div class="relative flex justify-between space-x-2">
                                                                    <div>
                                                                        <span class="font-semibold">{{
                                                                            item.label
                                                                        }}</span>
                                                                    </div>
                                                                    <div class="font-medium">
                                                                        {{
                                                                            $numeral(item.percent / 100).format('0.0%')
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Language -->
                                        <div
                                            v-if="demographics?.byLanguage"
                                            class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                                        >
                                            <header class="border-b border-slate-100 px-5 py-4">
                                                <h2 class="font-semibold text-slate-800">Languages</h2>
                                            </header>

                                            <div class="grow p-3">
                                                <div class="flex h-full flex-col">
                                                    <div class="grow">
                                                        <ul
                                                            class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                                        >
                                                            <li>Language</li>
                                                            <li>Percent</li>
                                                        </ul>
                                                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                                            <!-- Item -->
                                                            <li
                                                                v-for="item in demographics.byLanguage"
                                                                :key="item.key"
                                                                class="relative px-2 py-1"
                                                            >
                                                                <div
                                                                    class="absolute inset-0 bg-emerald-100"
                                                                    aria-hidden="true"
                                                                    :style="{
                                                                        width: `${item.percent}%`,
                                                                    }"
                                                                />
                                                                <div class="relative flex justify-between space-x-2">
                                                                    <div>
                                                                        <span
                                                                            v-if="item.key !== 'OTHER' && item.key"
                                                                            class="font-semibold uppercase"
                                                                            >{{ item.key }}</span
                                                                        >
                                                                        <span class="ml-1">{{ item.label }}</span>
                                                                    </div>
                                                                    <div class="font-medium">
                                                                        {{
                                                                            $numeral(item.percent / 100).format('0.0%')
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- OS -->
                                        <div
                                            v-if="demographics?.byOperatingSystem"
                                            class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                                        >
                                            <header class="border-b border-slate-100 px-5 py-4">
                                                <h2 class="font-semibold text-slate-800">Operating System</h2>
                                            </header>

                                            <div class="grow p-3">
                                                <div class="flex flex-col">
                                                    <div class="grow">
                                                        <ul
                                                            class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                                        >
                                                            <li>OS</li>
                                                            <li>Percent</li>
                                                        </ul>
                                                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                                            <!-- Item -->
                                                            <li
                                                                v-for="item in demographics.byOperatingSystem"
                                                                :key="item.key"
                                                                class="relative px-2 py-1"
                                                            >
                                                                <div
                                                                    class="absolute inset-0 bg-sky-100"
                                                                    aria-hidden="true"
                                                                    :style="{
                                                                        width: `${item.percent}%`,
                                                                    }"
                                                                />
                                                                <div class="relative flex justify-between space-x-2">
                                                                    <div>
                                                                        <span class="ml-1 capitalize">{{
                                                                            item.label ? item.label : item.key
                                                                        }}</span>
                                                                    </div>
                                                                    <div class="font-medium">
                                                                        {{
                                                                            $numeral(item.percent / 100).format('0.0%')
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Device -->
                                        <div
                                            v-if="demographics?.byDeviceType"
                                            class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                                        >
                                            <header class="border-b border-slate-100 px-5 py-4">
                                                <h2 class="font-semibold text-slate-800">Devices</h2>
                                            </header>

                                            <div class="grow p-3">
                                                <div class="flex flex-col">
                                                    <div class="grow">
                                                        <ul
                                                            class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                                        >
                                                            <li>Device</li>
                                                            <li>Percent</li>
                                                        </ul>
                                                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                                            <!-- Item -->
                                                            <li
                                                                v-for="item in demographics.byDeviceType"
                                                                :key="item.key"
                                                                class="relative px-2 py-1"
                                                            >
                                                                <div
                                                                    class="absolute inset-0 bg-sky-100"
                                                                    aria-hidden="true"
                                                                    :style="{
                                                                        width: `${item.percent}%`,
                                                                    }"
                                                                />
                                                                <div class="relative flex justify-between space-x-2">
                                                                    <div>
                                                                        <span class="ml-1 capitalize">{{
                                                                            item.label
                                                                        }}</span>
                                                                    </div>
                                                                    <div class="font-medium">
                                                                        {{
                                                                            $numeral(item.percent / 100).format('0.0%')
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="!state.demographics.isLoading && demographics === false" class="mt-4">
                                    <div class="rounded border border-slate-100 p-3">
                                        <div class="relative flex">
                                            <WorldMap class="p-10" />

                                            <div class="text-md absolute flex h-full w-full">
                                                <div class="m-auto font-semibold">
                                                    It seems we don't have any demographic data for
                                                    {{ superview.normalized.name }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'audience_analysis'" class="mt-8">
                                <AudienceTab
                                    :superview="superview"
                                    :platform-id="selectedPlatformId"
                                    :platform-user-id="selectedPlatformUserId"
                                />
                            </div>

                            <div v-if="state.tabs.secondary.activeTab === 'past_participants'">
                                <div class="mt-8">
                                    <h2 class="font-semibold text-slate-800">Other Campaigns</h2>
                                    <p class="text-sm">
                                        These are the campaigns that {{ superview.normalized.display_name }} has
                                        participated in.
                                    </p>
                                </div>

                                <table class="mt-4 w-full table-auto">
                                    <!-- Table header -->
                                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                                        <tr>
                                            <th class="w-1/2 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                <div class="text-left font-semibold">Campaign</div>
                                            </th>
                                            <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/6">
                                                <div class="font-semibold text-left">Date</div>
                                            </th> -->
                                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                <div class="text-left font-semibold">Stage</div>
                                            </th>
                                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                <div class="text-right font-semibold">Percentile</div>
                                            </th>
                                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                <div class="text-right font-semibold">Payout</div>
                                            </th>
                                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                                <div class="text-right font-semibold">CPC</div>
                                            </th>
                                            <th
                                                v-if="selectedPlatformId === 'twitch'"
                                                class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                            >
                                                <div class="text-right font-semibold">Overstreamed</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="divide-y divide-slate-200 text-sm">
                                        <template v-if="state.pastParticipants.isLoading">
                                            <tr v-for="i in 10" :key="i">
                                                <td v-for="j in 5" :key="j" class="px-2 py-2">
                                                    <div class="h-5 w-full animate-pulse rounded bg-slate-100"></div>
                                                </td>
                                            </tr>
                                        </template>

                                        <template
                                            v-else-if="
                                                !state.pastParticipants.isLoading &&
                                                state.pastParticipants.records &&
                                                state.pastParticipants.records.length
                                            "
                                        >
                                            <tr
                                                v-for="participant in visiblePastParticipants"
                                                :key="participant.id"
                                                class="group"
                                            >
                                                <td
                                                    class="flex-col items-center whitespace-nowrap px-2 first:pl-5 last:pr-5"
                                                >
                                                    <div class="relative flex w-full items-center">
                                                        <div class="flex">
                                                            <div
                                                                v-if="
                                                                    $filters.lodash.has(
                                                                        participant,
                                                                        'campaign.supercampaign.game.twitch_id',
                                                                    )
                                                                "
                                                                class="h-8 w-16 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                                                :style="{
                                                                    'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/${encodeURI(
                                                                        $filters.lodash.get(
                                                                            participant,
                                                                            'campaign.supercampaign.game.twitch_id',
                                                                        ),
                                                                    )}_IGDB-288x384.jpg)`,
                                                                }"
                                                            />

                                                            <div
                                                                v-else
                                                                class="h-12 w-24 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                                            />

                                                            <div class="my-auto ml-3 flex-col leading-none">
                                                                <div
                                                                    class="text-md line-clamp-1 whitespace-normal font-semibold"
                                                                >
                                                                    {{
                                                                        $filters.lodash.has(
                                                                            participant,
                                                                            'campaign.supercampaign.game.name',
                                                                        )
                                                                            ? participant?.campaign?.supercampaign?.game
                                                                                  ?.name
                                                                            : participant.campaign.title
                                                                    }}
                                                                </div>

                                                                <div class="flex text-xs">
                                                                    {{
                                                                        getFromDictionary(
                                                                            `campaign.type.${participant.campaign.type}`,
                                                                            'label',
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <router-link
                                                            :to="{
                                                                name: 'campaign-report',
                                                                params: {
                                                                    campaign_id: participant.campaign.id,
                                                                    supercampaign_id: participant.campaign.parent_id,
                                                                },
                                                            }"
                                                        >
                                                            <div
                                                                class="absolute right-0 top-0 hidden cursor-pointer rounded bg-slate-100 px-2 py-1.5 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                            >
                                                                <span class="my-auto flex uppercase">
                                                                    <font-awesome-icon
                                                                        :icon="['fas', 'rectangle-list']"
                                                                        class="my-auto mr-1 h-4 w-4"
                                                                    />
                                                                    <span class="my-auto leading-none">Open</span>
                                                                </span>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                </td>
                                                <!-- <td class="px-2 first:pl-5 last:pr-5 py-3.5 whitespace-nowrap">
                                                    <div :title="$moment(participant.campaign.started_at).calendar()">
                                                        {{
                                                            $moment(participant.campaign.started_at)
                                                                .fromNow()
                                                        }}
                                                    </div>
                                                </td> -->
                                                <td
                                                    class="whitespace-nowrap px-2 py-3.5 capitalize first:pl-5 last:pr-5"
                                                >
                                                    {{ participant.stage }}
                                                </td>
                                                <td
                                                    class="whitespace-nowrap px-2 py-3.5 capitalize first:pl-5 last:pr-5"
                                                >
                                                    <div class="flex flex-col text-right">
                                                        <template
                                                            v-if="
                                                                !isNaN(
                                                                    pastReportsByParticipantId[participant.id]
                                                                        ?.rank_percentile,
                                                                )
                                                            "
                                                        >
                                                            <div>
                                                                {{
                                                                    $numeral(
                                                                        pastReportsByParticipantId[participant.id]
                                                                            .rank_percentile,
                                                                    ).format('0o')
                                                                }}
                                                            </div>
                                                            <div
                                                                class="ml-auto mt-1 flex h-1 w-1/2 overflow-hidden rounded bg-slate-200"
                                                            >
                                                                <div
                                                                    class="h-full bg-amber-500"
                                                                    :style="{
                                                                        width: `${
                                                                            pastReportsByParticipantId[participant.id]
                                                                                .rank_percentile
                                                                        }%`,
                                                                    }"
                                                                    :class="{
                                                                        '!bg-emerald-500':
                                                                            pastReportsByParticipantId[participant.id]
                                                                                .rank_percentile > 66,
                                                                        '!bg-rose-500':
                                                                            pastReportsByParticipantId[participant.id]
                                                                                .rank_percentile < 33,
                                                                    }"
                                                                />
                                                            </div>
                                                        </template>
                                                        <template v-else> --- </template>
                                                    </div>
                                                </td>
                                                <td
                                                    class="whitespace-nowrap px-2 py-3.5 capitalize first:pl-5 last:pr-5"
                                                >
                                                    <div class="flex flex-col text-right">
                                                        <template v-if="participant.total_payout">
                                                            ${{ $numeral(participant.total_payout).format('0,0.00') }}
                                                        </template>
                                                        <template v-else> --- </template>
                                                    </div>
                                                </td>
                                                <td
                                                    class="whitespace-nowrap px-2 py-3.5 capitalize first:pl-5 last:pr-5"
                                                >
                                                    <div class="flex flex-col text-right">
                                                        <template
                                                            v-if="pastReportsByParticipantId[participant.id]?.cpc"
                                                        >
                                                            ${{
                                                                $numeral(
                                                                    pastReportsByParticipantId[participant.id].cpc,
                                                                ).format('0,0.00')
                                                            }}
                                                        </template>
                                                        <template v-else> --- </template>
                                                    </div>
                                                </td>
                                                <td
                                                    v-if="selectedPlatformId === 'twitch'"
                                                    class="whitespace-nowrap px-2 py-3.5 capitalize first:pl-5 last:pr-5"
                                                >
                                                    <div class="flex flex-col text-right">
                                                        <template
                                                            v-if="
                                                                pastReportsByParticipantId[participant.id]
                                                                    ?.over_streamed > 1
                                                            "
                                                        >
                                                            {{
                                                                $numeral(
                                                                    pastReportsByParticipantId[participant.id]
                                                                        .over_streamed,
                                                                ).format('0,0%')
                                                            }}
                                                        </template>
                                                        <template v-else> --- </template>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>

                                        <template
                                            v-else-if="
                                                !state.pastParticipants.isLoading &&
                                                state.pastParticipants &&
                                                !state.pastParticipants.length
                                            "
                                        >
                                            <tr>
                                                <td colspan="100" class="py-3 text-center">
                                                    <p>
                                                        {{ superview.normalized.display_name }} has not participated in
                                                        any campaigns recently.
                                                    </p>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div
                                    v-if="
                                        !state.pastParticipants.isLoading &&
                                        state.pastParticipants.records &&
                                        state.pastParticipants.records.length > 0
                                    "
                                    class="ml-4 mr-4 mt-4"
                                >
                                    <DiscoveryPagination
                                        :paging="state.pastParticipants.paging"
                                        record-name="campaigns"
                                        @paging-changed="onPastParticipantsPagingChange"
                                    />
                                </div>
                            </div>
                        </template>

                        <template v-if="state.tabs.primary.activeTab === 'workflow'">
                            <WorkflowSection :participants="participants" :selected-participant-id="participant?.id" />
                        </template>

                        <div v-if="state.tabs.primary.activeTab === 'notes'">
                            <Notes
                                :entity-type="
                                    participant ? 'participant' : selectedPlatformId ? selectedPlatformId : platformId
                                "
                                :entity-id="
                                    participant
                                        ? participant.id
                                        : selectedPlatformId
                                        ? selectedPlatformUserId
                                        : platformUserId
                                "
                                :related="relatedNoteProfiles"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <SkeletonApprovalSubpage />
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { nextTick } from 'vue'
import { transformFilters } from '@/utils/api/api.discovery'
import participantStagesAPI from '@/utils/api/api.campaign.participant.stages'
import supercampaignAPI from '@/utils/api/api.supercampaign'
import CreatorGamesTable from '@/partials/profiles/CreatorProfile/tables/CreatorGamesTable.vue'
import CreatorHashtagsTable from '@/partials/profiles/CreatorProfile/tables/CreatorHashtagsTable.vue'
import CreatorInterestsTable from '@/partials/profiles/CreatorProfile/tables/CreatorInterestsTable.vue'
import CreatorContentTable from '@/partials/profiles/CreatorProfile/tables/CreatorContentTable.vue'
import { getGradeAndColorsFromPercent } from '@/utils/affinity'

import AudienceTab from '@/partials/profiles/CreatorProfile/tabs/Audience.vue'
import WorldMap from '@/components/WorldMap.vue'
import Tooltip from '@/components/Tooltip.vue'
import Avatar from '@/components/Avatar.vue'
import CreatorBanner from '@/components/CreatorBanner.vue'
import AffinityPlate from '@/components/plates/AffinityPlate.vue'
import PaginationDiscover from '@/components/PaginationDiscover.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import AudienceReceptionPlate from '@/components/plates/AudienceReceptionPlate.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import SkeletonApprovalSubpage from '@/partials/skeletons/SkeletonApprovalSubpage.vue'
import SkeletonCreatorProfileDemographics from '@/partials/skeletons/SkeletonCreatorProfileDemographics.vue'
import ContentTimeseriesChart from '@/partials/charts/ContentTimeseriesChart.vue'
import reportsAPI from '@/utils/api/api.reports'
import participantAPI from '@/utils/api/api.campaign.participant'
import EditUserProfileModal from '@/partials/profiles/CreatorProfile/EditUserProfileModal.vue'
import ExternalSocialLinks from '@/partials/profiles/CreatorProfile/ExternalSocialLinks.vue'
import WorkflowSection from '@/partials/profiles/CreatorProfile/WorkflowSection.vue'

import Notes from '@/partials/widgets/Notes.vue'

export default {
    name: 'CreatorProfile',

    components: {
        ExternalSocialLinks,
        EditUserProfileModal,
        WorldMap,
        Tooltip,
        Avatar,
        CreatorBanner,
        AffinityPlate,
        PaginationDiscover,
        DiscoveryPagination,
        AudienceReceptionPlate,
        SkeletonApprovalSubpage,
        SkeletonCreatorProfileDemographics,
        NamePlate,
        ContentTimeseriesChart,
        CreatorGamesTable,
        CreatorHashtagsTable,
        CreatorInterestsTable,
        CreatorContentTable,
        AudienceTab,
        Notes,
        WorkflowSection,
    },

    props: {
        platformId: {
            type: String,
        },
        platformUserId: {
            type: String,
        },
        targetingGroups: {
            type: Array,
        },
        participant: {
            type: Object,
        },
        campaign: {
            type: Object,
        },
        bid_payout_amount: {
            type: Number,
        },
        items: {
            type: Array,
        },
        isInWorkflow: {
            type: Boolean,
        },
    },

    data() {
        return {
            superviewsByPlatformId: {},
            selectedPlatformId: null,
            selectedPlatformUserId: null,
            superview: null,
            creator: null,
            discoverByContentType: null,
            gameSuperviewsById: {},
            codesByType: {},
            allGames: [],
            allTags: [],
            conversion: null,
            socialLinks: [],
            participants: [],

            supercampaign: null,
            demographics: null,

            audienceAnalysis: null,

            biography: {
                text: null,
                animatedText: '',
                speed: 5, // in milliseconds
                cursor: 0,
                timeoutReference: null,
                features: {},
            },

            contentAnalysis: null,

            contentTimeseries: null,

            // Previous campaigns
            pastReportsByParticipantId: {},

            highestRelatedStrength: 1,

            linkableUsers: null,

            selectablePlatforms: ['youtube', 'twitch', 'tiktok', 'twitter', 'instagram'],

            contentTypes: {
                twitch: ['stream'],
                youtube: ['video', 'short', 'stream'],
                tiktok: ['video'],
                twitter: ['post'],
                instagram: ['post', 'carousel', 'video', 'image'],
            },

            metricWindows: ['day_30', 'day_90', 'day_365', 'day_1095'],

            state: {
                isMissing: false,
                isFailed: false,
                isBackfilling: false,
                backfillAttempts: 0,

                modals: {
                    addToListOrCampaign: {
                        isOpen: false,
                    },
                },

                tabs: {
                    primary: {
                        activeTab: 'profile',
                    },
                    secondary: {
                        activeTab: 'targeting',
                    },
                },

                dropdowns: {
                    platformSelect: {
                        isOpen: false,
                    },
                },

                overlay: {
                    isActive: false,
                    type: 'override',
                    isInvalid: null,
                    isSubmitting: false,
                },

                selectedGroupIndex: 0,

                games: {
                    inGroup: {
                        pageIndex: 0,
                        itemsPerPage: 10,
                    },
                    outGroup: {
                        pageIndex: 0,
                        itemsPerPage: 10,
                    },
                },

                demographics: {
                    isLoading: false,
                },

                contentAnalysis: {
                    isLoading: false,
                },

                relatedCreators: {
                    isLoading: false,
                    paging: {
                        perPage: 15,
                        currentPage: 1,
                        totalPages: 0,
                        totalRecords: 0,
                    },
                    records: null,
                },

                pastParticipants: {
                    isLoading: false,
                    paging: {
                        perPage: 15,
                        currentPage: 1,
                        totalPages: 0,
                        totalRecords: 0,
                    },
                    records: null,
                },

                stages: {
                    is_loaded: false,
                },

                metricWindow: 'day_365',
                contentType: 'stream',

                timeseries: {
                    isLoaded: false,
                    configuration: null,
                },

                audienceAnalysis: {
                    isLoaded: false,
                    progress: 0,
                },

                biography: {
                    isLoading: false,
                },
            },

            overwriteData: {},

            gameSearch: {
                lastText: '',
                text: '',
            },
        }
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
            'getLanguageFromCode',
            'getCountryFromCode',
            'getCodeFromCountry',
            'getCodeFromLanguage',
            'isSfStaff',
            'isDevModeEnabled',
            'checkPermission',
        ]),

        profileSelector() {
            if (!this.creator) return []

            // Grab the arbitrary platform list, place current active platform in front
            const platforms = _.filter(_.clone(this.selectablePlatforms), (item) => item !== this.selectedPlatformId)
            platforms.unshift(this.selectedPlatformId)

            const options = _.map(platforms, (platformId) => {
                // In the future we will sort this by follower count
                const profiles = _.orderBy(this.creator?.profiles?.[platformId], ['id'], ['asc'])

                return {
                    platform_id: platformId,
                    profiles,
                    is_enabled: profiles?.length > 0,
                    primary: _.first(profiles),
                }
            })

            return {
                active: options.shift(),
                others: options,
            }
        },

        validMetricWindows() {
            if (!this.superview) return []

            return _.filter(
                this.metricWindows,
                (metricWindowKey) =>
                    this.superview[this.state.contentType] && this.superview[this.state.contentType][metricWindowKey],
            )
        },

        validContentTypes() {
            if (!this.superview) return []

            return _.filter(
                this.contentTypes[this.selectedPlatformId],
                (contentTypeKey) =>
                    this.superview[contentTypeKey] && this.superview[contentTypeKey][this.state.metricWindow],
            )
        },

        platformTargetingGroups() {
            return _.filter(this.targetingGroups, (item) => item.filters.platform === this.selectedPlatformId)
        },

        selectedGroup() {
            if (!this.platformTargetingGroups || _.size(this.platformTargetingGroups) === 0) return null
            return this.mappedTargetingGroups[this.state.selectedGroupIndex]
        },

        canChangeDeliverableType() {
            return this.participant && ['prospect'].includes(this.participant.stage)
        },

        visiblePrimaryTabs() {
            const options = [
                {
                    key: 'profile',
                    label: 'Profile',
                    condition: () => true,
                },
                {
                    key: 'workflow',
                    label: 'Workflow',
                    condition: () => this.participants?.length > 0,
                },
                {
                    key: 'notes',
                    label: 'Notes',
                    condition: () => true,
                },

                // {
                //     key: 'bids',
                //     label: 'Bid',
                //     condition: () => !!this.participant && this.isInWorkflow === true,
                // },
                // {
                //     key: 'prospecting',
                //     label: 'Prospecting',
                //     condition: () => this.isSfStaff && !!this.participant,
                // },
                // {
                //     key: 'contract',
                //     label: 'Contracting',
                //     condition: () =>
                //         this.isSfStaff &&
                //         !!this.participant &&
                //         ['contracting', 'preparation', 'delivery'].includes(this.participant.stage),
                // },
                // {
                //     key: 'finance',
                //     label: 'Finance',
                //     condition: () =>
                //         this.isSfStaff &&
                //         !!this.participant &&
                //         ['contracting', 'preparation', 'delivery'].includes(this.participant.stage),
                // },
            ]

            return _.filter(options, (option) => option.condition() === true)
        },

        visibleSecondaryTabs() {
            const options = [
                {
                    key: 'targeting',
                    label: 'Targeted Games',
                    condition: () =>
                        this.mappedTargetingGroups.length > 0 &&
                        this.selectedGroup?.filters?.games?.values?.length > 0 &&
                        this.selectedGroup?.inGroup?.games &&
                        this.selectedGroup?.inGroup?.games?.length > 0,
                },
                {
                    key: 'all_interests',
                    label: 'Interests',
                    condition: () => this.biography?.features?.interests?.length > 0,
                },
                {
                    key: 'all_tags',
                    label: 'Hashtags',
                    condition: () => true, // this.allTags?.length > 0,
                },
                {
                    key: 'all_games',
                    label: 'Games',
                    condition: () =>
                        ['twitch', 'youtube'].includes(this.selectedPlatformId) && this.allGames?.length > 0,
                },
                {
                    key: 'all_content',
                    label: 'All Content',
                    condition: () => true,
                },
                // {
                //     key: 'demographics',
                //     label: 'Demographics',
                //     condition: () =>
                //         this.checkPermission('feature.audience.demographics') &&
                //         ['twitch', 'youtube'].includes(this.selectedPlatformId),
                // },
                {
                    key: 'audience_analysis',
                    label: 'Audience',
                    has_ai_label: true,
                    condition: () =>
                        (this.checkPermission('feature.audience.demographics') ||
                            this.checkPermission('feature.audience.interests')) &&
                        ['twitch', 'youtube', 'tiktok'].includes(this.selectedPlatformId),
                },
                // {
                //     key: 'content_analysis',
                //     label: 'Content Analysis',
                //     has_ai_label: true,
                //     condition: () =>
                //         ['youtube'].includes(this.selectedPlatformId) && this.checkPermission('feature.ai.profiles'),
                // },
                {
                    key: 'shared_audience',
                    label: 'Shared Audiences',
                    condition: () => ['twitch', 'youtube', 'tiktok'].includes(this.selectedPlatformId),
                },
                {
                    key: 'past_participants',
                    label: 'Campaigns',
                    condition: () => this.isSfStaff,
                },
            ]

            return _.filter(options, (option) => option.condition() === true)
        },

        metrics() {
            if (!this.superview) return []

            const windowLookback = {
                day_30: 'day_90',
                day_90: 'day_365',
                day_365: 'day_1095',
            }

            let fields = []

            if (this.selectedPlatformId === 'twitch') {
                const currentMetricWindow = _.get(this.superview, `stream.${this.state.metricWindow}`)
                const previousMetricWindow = _.get(this.superview, `stream.${windowLookback[this.state.metricWindow]}`)

                fields = [
                    {
                        name: 'avg_viewers',
                        group_id: 'viewership',
                        title: 'Avg. Viewers',
                        value: _.get(currentMetricWindow, `aggregation.viewers.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.viewers.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_viewers',
                        group_id: 'viewership',
                        title: 'Peak Viewers',
                        value: _.get(currentMetricWindow, `aggregation.viewers.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.viewers.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'avg_airtime',
                        group_id: 'viewership',
                        title: 'Avg. Airtime',
                        value: _.get(currentMetricWindow, `aggregation.live_seconds.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.live_seconds.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.$filters.humanizeDurationShort(Math.round(value) * 1000),
                    },
                    {
                        name: 'followers',
                        group_id: 'viewership',
                        title: 'Followers',
                        value: _.get(this.superview, `channel.followers`),
                        delta: () => null,
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                ]

                if (_.has(currentMetricWindow, 'aggregation.chatters.avg')) {
                    fields.push({
                        name: 'avg_chatters',
                        group_id: 'engagement',
                        title: 'Avg. Active Chatters',
                        value: _.get(currentMetricWindow, `aggregation.chatters.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.chatters.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    })

                    fields.push({
                        name: 'avg_messages',
                        group_id: 'engagement',
                        title: 'Avg. Messages per Hour',
                        value: _.get(currentMetricWindow, `aggregation.messages.avg`) * 6,
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.messages.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    })

                    fields.push({
                        name: 'engagement_rate',
                        group_id: 'engagement',
                        title: 'Engagement Rate',
                        value: _.get(currentMetricWindow, `aggregation.engagement_rate.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.engagement_rate.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => numeral(value).format('0,0.0%'),
                    })

                    fields.push({
                        name: 'impressions',
                        group_id: 'engagement',
                        title: 'Avg. Impressions',
                        value: _.get(currentMetricWindow, `aggregation.impressions.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.impressions.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    })
                }

                fields.push({
                    name: 'market_rate_lets_play_stream',
                    group_id: 'rates',
                    title: `Dedicated Stream Rate`,
                    value:
                        (1 + _.get(currentMetricWindow, `accumulation.games.performance.non_main.median`)) *
                        _.get(currentMetricWindow, `aggregation.viewers.avg`),
                    delta: () => null,
                    formatter: (value) => `$${numeral(value).format('0,0')}/hr`,
                    tooltip: {
                        label: `Dedicated Stream Rate`,
                        description: `This rate applies to dedicated hourly streams on Twitch, typically featuring gameplay and commentary. Ideal for showcasing games in a real-time, interactive environment, allowing viewers to experience the game through the creator's perspective.`,
                    },
                })

                // fields.push({
                //     name: 'market_rate_ad_integration',
                //     title: `Stream AD Integration Rate`,
                //     value:
                //         ((1 + _.get(currentMetricWindow, `accumulation.games.performance.non_main.median`)) *
                //             _.get(currentMetricWindow, `aggregation.viewers.avg`)) /
                //         60,
                //     delta: () => null,
                //     formatter: (value) => `$${numeral(value).format('0,0')}/min`,
                //     tooltip: {
                //         label: `Stream AD Integration Rate`,
                //         description: `This rate is for integrating your brand's message or product seamlessly into a Twitch stream. This could include in-game branding, verbal mentions, or showcasing products during the stream, offering an organic way to engage the audience.`,
                //     },
                // })

                fields.push({
                    name: 'viewership_stability',
                    group_id: 'rates',
                    title: 'Viewership Stability',
                    value: Math.min(
                        1 + _.get(currentMetricWindow, 'accumulation.games.performance.non_main.median'),
                        1,
                    ),
                    delta: () => null,
                    formatter: (value) => {
                        const formattedValue = numeral(value).format('0%')
                        if (value >= 0.9) return `${formattedValue} (High)`
                        if (value >= 0.7) return `${formattedValue} (Medium)`
                        if (value >= 0.5) return `${formattedValue} (Low)`
                        return `${formattedValue} (Very Low)`
                    },
                    tooltip: {
                        label: 'Viewership Stability',
                        description: `When the creator makes content that is not related to their main niche, how much of their audience will they retain.`,
                    },
                })

                fields.push({
                    name: 'sponsorship_saturation',
                    group_id: 'rates',
                    title: 'Sponsorship Saturation',
                    value: _.get(currentMetricWindow, 'accumulation.chapters_sponsored.percent'),
                    delta: () => null,
                    formatter: (value) => {
                        const formattedValue = numeral(value).format('0%')
                        if (value >= 0.1) return `${formattedValue} (High)`
                        if (value >= 0.05) return `${formattedValue} (Medium)`
                        if (value >= 0.025) return `${formattedValue} (Low)`
                        return `${formattedValue} (Very Low)`
                    },
                    tooltip: {
                        label: 'Sponsorship Saturation',
                        description: `The percentage of the content this creator makes that is sponsored. Creators that oversaturate their content with sponsorships should be avoided.`,
                    },
                })
            }

            if (this.selectedPlatformId === 'youtube') {
                const currentMetricWindow = _.get(
                    this.superview,
                    `${this.state.contentType}.${this.state.metricWindow}`,
                )
                const previousMetricWindow = _.get(
                    this.superview,
                    `${this.state.contentType}.${windowLookback[this.state.metricWindow]}`,
                )

                if (!currentMetricWindow) {
                    return []
                }

                fields = [
                    {
                        name: 'avg_view',
                        group_id: 'viewership',
                        title: 'Avg. Views',
                        value: _.get(currentMetricWindow, `aggregation.views.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_views',
                        group_id: 'viewership',
                        title: 'Peak Views',
                        value: _.get(currentMetricWindow, `aggregation.views.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'subscribers',
                        group_id: 'viewership',
                        title: 'Subscribers',
                        value: _.get(this.superview, `channel.statistics.subscriberCount`),
                        delta: () => null,
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'video_count',
                        group_id: 'viewership',
                        title: 'Videos Created',
                        value: _.get(currentMetricWindow, `accumulation.videos.count`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `accumulation.videos.count`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                ]

                fields.push({
                    name: 'avg_comments',
                    group_id: 'engagement',
                    title: 'Avg. Comments per video',
                    value: _.get(currentMetricWindow, `aggregation.comments.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.comments.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'avg_likes',
                    group_id: 'engagement',
                    title: 'Avg. Likes per video',
                    value: _.get(currentMetricWindow, `aggregation.likes.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.likes.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'engagement_rate',
                    group_id: 'engagement',
                    title: 'Engagement Rate',
                    value:
                        (_.get(currentMetricWindow, `aggregation.comments.avg`) +
                            _.get(currentMetricWindow, `aggregation.likes.avg`)) /
                        _.get(currentMetricWindow, `aggregation.views.avg`),
                    delta: (currentValue) => {
                        const previousValue =
                            (_.get(previousMetricWindow, `aggregation.comments.avg`) +
                                _.get(previousMetricWindow, `aggregation.likes.avg`)) /
                            _.get(previousMetricWindow, `aggregation.views.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => numeral(value).format('0,0.0%'),
                })
                fields.push({
                    name: 'sum_views',
                    group_id: 'engagement',
                    title: 'Sum Views',
                    value: _.get(currentMetricWindow, `aggregation.views.sum`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.views.sum`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, true, '---'),
                })

                if (this.superview?.video && this.superview?.video[this.state.metricWindow]) {
                    fields.push({
                        name: 'market_rate_lets_play_video',
                        group_id: 'rates',
                        title: `Dedicated Video Rate`,
                        value:
                            (_.get(this.superview, `video.${this.state.metricWindow}.aggregation.views.avg`) / 1000) *
                            50,
                        delta: () => null,
                        formatter: (value) => `$${numeral(value).format('0,0')}/video`,
                        tooltip: {
                            label: `Dedicated Video Rate`,
                            description: `A fixed rate for exclusive video on YouTube. This format offers a focused showcase of your brand, game or product, engaging viewers with a start-to-finish experience.`,
                        },
                    })

                    fields.push({
                        name: 'market_rate_ad_integration',
                        group_id: 'rates',
                        title: `AD Integration Rate`,
                        value:
                            (_.get(this.superview, `video.${this.state.metricWindow}.aggregation.views.avg`) / 1000) *
                            (50 / 3),
                        delta: () => null,
                        formatter: (value) => `$${numeral(value).format('0,0')}/ad`,
                        tooltip: {
                            label: `AD Integration Rate`,
                            description: `This rate covers the integration of a 60-second advertisement within a regular YouTube video. It's a way to capture the audience's attention mid-content with a dedicated ad segment.`,
                        },
                    })
                }

                if (this.superview?.short && this.superview?.short[this.state.metricWindow]) {
                    fields.push({
                        name: 'market_rate_dedicated_short',
                        group_id: 'rates',
                        title: `Dedicated Short`,
                        value:
                            (((1 +
                                _.get(
                                    this.superview,
                                    `short.${this.state.metricWindow}.accumulation.games.performance.non_main.median`,
                                )) *
                                _.get(this.superview, `short.${this.state.metricWindow}.aggregation.views.avg`)) /
                                1000) *
                            5,
                        delta: () => null,
                        formatter: (value) => `$${numeral(value).format('0,0')}/short`,
                        tooltip: {
                            label: `Dedicated YouTube Short Rate`,
                            description: `Pricing for creating YouTube Shorts - short, engaging videos aimed at quickly capturing audience attention. Perfect for highlighting game features or announcements in a concise, shareable format.`,
                        },
                    })
                }

                fields.push({
                    name: 'viewership_stability',
                    group_id: 'rates',
                    title: 'Viewership Stability',
                    value: Math.min(
                        1 + _.get(currentMetricWindow, 'accumulation.games.performance.non_main.median'),
                        1,
                    ),
                    delta: () => null,
                    formatter: (value) => {
                        const formattedValue = value ? numeral(value).format('0%') : ''
                        if (value >= 0.9) return `${formattedValue} (High)`
                        if (value >= 0.7) return `${formattedValue} (Medium)`
                        if (value >= 0.5) return `${formattedValue} (Low)`
                        return `${formattedValue} (Very Low)`
                    },
                    tooltip: {
                        label: 'Viewership Stability',
                        description: `When the creator makes content that is not related to their main niche, how much of their audience will they retain.`,
                    },
                })

                fields.push({
                    name: 'sponsorship_saturation',
                    group_id: 'rates',
                    title: 'Sponsorship Saturation',
                    value: _.get(currentMetricWindow, 'accumulation.videos_sponsored.percent'),
                    delta: () => null,
                    formatter: (value) => {
                        const formattedValue = numeral(value).format('0%')
                        if (value >= 0.1) return `${formattedValue} (High)`
                        if (value >= 0.05) return `${formattedValue} (Medium)`
                        if (value >= 0.025) return `${formattedValue} (Low)`
                        return `${formattedValue} (Very Low)`
                    },
                    tooltip: {
                        label: 'Sponsorship Saturation',
                        description: `The percentage of the content this creator makes that is sponsored. Creators that oversaturate their content with sponsorships should be avoided.`,
                    },
                })
            }

            if (this.selectedPlatformId === 'tiktok') {
                const currentMetricWindow = _.get(this.superview, `video.${this.state.metricWindow}`)
                const previousMetricWindow = _.get(this.superview, `video.${windowLookback[this.state.metricWindow]}`)

                fields = [
                    {
                        name: 'avg_view',
                        group_id: 'viewership',
                        title: 'Avg. Views',
                        value: _.get(currentMetricWindow, `aggregation.views.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_views',
                        group_id: 'viewership',
                        title: 'Peak Views',
                        value: _.get(currentMetricWindow, `aggregation.views.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'followers',
                        group_id: 'viewership',
                        title: 'Followers',
                        value: _.get(this.superview, `channel.statistics.followers`),
                        delta: () => null,
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'video_count',
                        group_id: 'viewership',
                        title: 'Videos Created',
                        value: _.get(currentMetricWindow, `accumulation.videos.count`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `accumulation.videos.count`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'market_rate',
                        group_id: 'rates',
                        title: `Creator's Market Rate`,
                        value: _.get(this.superview, `trueprice.rate_per_video`),
                        delta: () => null,
                        formatter: (value) => `$${numeral(value).format('0,0')}/video`,
                        tooltip: {
                            label: `Creator's Market Rate`,
                            description: `Rate for unique short-form videos on TikTok, focusing on creative and engaging content that highlights your game or brand. Ideal for viral marketing and reaching a broad, mobile-first audience.`,
                        },
                    },
                    // {
                    //     name: 'viewership_stability',
                    //     title: 'Viewership Stability',
                    //     value: Math.min((1 + _.get(currentMetricWindow, 'accumulation.games.performance.non_main.median')), 1),
                    //     delta: () => null,
                    //     formatter: (value) => {
                    //         const formattedValue = value ? numeral(value).format('0%') : ''
                    //         if (value >= 0.9) return `${formattedValue} (High)`
                    //         if (value >= 0.7) return `${formattedValue} (Medium)`
                    //         if (value >= 0.5) return `${formattedValue} (Low)`
                    //         return `${formattedValue} (Very Low)`
                    //     },
                    //     tooltip: {
                    //         label: 'Viewership Stability',
                    //         description: `This indictates how much viewership a creator will lose when making content that is not related to their main niche.`,
                    //     },
                    // },
                ]

                fields.push({
                    name: 'avg_comments',
                    group_id: 'engagement',
                    title: 'Avg. Comments per video',
                    value: _.get(currentMetricWindow, `aggregation.comments.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.comments.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'avg_likes',
                    group_id: 'engagement',
                    title: 'Avg. Likes per video',
                    value: _.get(currentMetricWindow, `aggregation.likes.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.likes.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'avg_shares',
                    group_id: 'engagement',
                    title: 'Avg. Shares per video',
                    value: _.get(currentMetricWindow, `aggregation.shares.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.shares.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'engagement_rate',
                    group_id: 'engagement',
                    title: 'Engagement Rate',
                    value:
                        (_.get(currentMetricWindow, `aggregation.comments.avg`) +
                            _.get(currentMetricWindow, `aggregation.likes.avg`) +
                            _.get(currentMetricWindow, `aggregation.shares.avg`)) /
                        _.get(currentMetricWindow, `aggregation.views.avg`),
                    delta: (currentValue) => {
                        const previousValue =
                            (_.get(previousMetricWindow, `aggregation.comments.avg`) +
                                _.get(previousMetricWindow, `aggregation.likes.avg`)) /
                            _.get(previousMetricWindow, `aggregation.views.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => numeral(value).format('0,0.0%'),
                })
            }

            if (this.selectedPlatformId === 'twitter') {
                const currentMetricWindow = _.get(this.superview, `post.${this.state.metricWindow}`)
                const previousMetricWindow = _.get(this.superview, `post.${windowLookback[this.state.metricWindow]}`)

                fields = [
                    {
                        name: 'avg_views',
                        group_id: 'viewership',
                        title: 'Avg. Views',
                        value: _.get(currentMetricWindow, `aggregation.views.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_views',
                        group_id: 'viewership',
                        title: 'Peak Views',
                        value: _.get(currentMetricWindow, `aggregation.views.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'followers',
                        group_id: 'viewership',
                        title: 'Followers',
                        value: _.get(this.superview, `channel.followers_count`),
                        delta: () => null,
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'post_count',
                        group_id: 'viewership',
                        title: 'Posts Created',
                        value: _.get(currentMetricWindow, `accumulation.posts.count`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `accumulation.posts.count`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    // {
                    //     name: 'market_rate',
                    //     title: `Creator's Market Rate`,
                    //     value:
                    //         (_.get(this.superview, `post.${this.state.metricWindow}.aggregation.views.avg`) / 1000) * 1,
                    //     delta: () => null,
                    //     formatter: (value) => `$${numeral(value).format('0,0')}/post`,
                    //     tooltip: {
                    //         label: `Creator's Market Rate`,
                    //         description: `Rate for a text/image/video based post on Twitter.`,
                    //     },
                    // },
                    // {
                    //     name: 'viewership_stability',
                    //     title: 'Viewership Stability',
                    //     value: Math.min((1 + _.get(currentMetricWindow, 'accumulation.games.performance.non_main.median')), 1),
                    //     delta: () => null,
                    //     formatter: (value) => {
                    //         const formattedValue = value ? numeral(value).format('0%') : ''
                    //         if (value >= 0.9) return `${formattedValue} (High)`
                    //         if (value >= 0.7) return `${formattedValue} (Medium)`
                    //         if (value >= 0.5) return `${formattedValue} (Low)`
                    //         return `${formattedValue} (Very Low)`
                    //     },
                    //     tooltip: {
                    //         label: 'Viewership Stability',
                    //         description: `This indictates how much viewership a creator will lose when making content that is not related to their main niche.`,
                    //     },
                    // },
                ]

                fields.push({
                    name: 'avg_replies',
                    group_id: 'engagement',
                    title: 'Avg. Comments',
                    value: _.get(currentMetricWindow, `aggregation.replies.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.replies.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'avg_likes',
                    group_id: 'engagement',
                    title: 'Avg. Likes',
                    value: _.get(currentMetricWindow, `aggregation.likes.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.likes.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'avg_retweets',
                    group_id: 'engagement',
                    title: 'Avg. Retweets',
                    value:
                        _.get(currentMetricWindow, `aggregation.retweets.avg`) +
                        _.get(currentMetricWindow, `aggregation.quotes.avg`),
                    delta: (currentValue) => {
                        const previousValue =
                            _.get(previousMetricWindow, `aggregation.retweets.avg`) +
                            _.get(previousMetricWindow, `aggregation.quotes.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'engagement_rate',
                    group_id: 'engagement',
                    title: 'Engagement Rate',
                    value:
                        _.sum([
                            _.get(currentMetricWindow, `aggregation.replies.avg`),
                            _.get(currentMetricWindow, `aggregation.likes.avg`),
                            _.get(currentMetricWindow, `aggregation.retweets.avg`),
                            _.get(currentMetricWindow, `aggregation.quotes.avg`),
                        ]) / _.get(currentMetricWindow, `aggregation.views.avg`),
                    delta: (currentValue) => {
                        const previousValue =
                            _.sum([
                                _.get(previousMetricWindow, `aggregation.replies.avg`),
                                _.get(previousMetricWindow, `aggregation.likes.avg`),
                                _.get(previousMetricWindow, `aggregation.retweets.avg`),
                                _.get(previousMetricWindow, `aggregation.quotes.avg`),
                            ]) / _.get(previousMetricWindow, `aggregation.views.avg`)

                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => numeral(value).format('0,0.0%'),
                })
            }

            if (this.selectedPlatformId === 'instagram') {
                const currentMetricWindow = _.get(
                    this.superview,
                    `${this.state.contentType}.${this.state.metricWindow}`,
                )
                const previousMetricWindow = _.get(
                    this.superview,
                    `${this.state.contentType}.${windowLookback[this.state.metricWindow]}`,
                )

                fields = [
                    {
                        name: 'avg_views',
                        group_id: 'viewership',
                        title: 'Avg. Views',
                        value: _.get(currentMetricWindow, `aggregation.views.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_views',
                        group_id: 'viewership',
                        title: 'Peak Views',
                        value: _.get(currentMetricWindow, `aggregation.views.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.views.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'followers',
                        group_id: 'viewership',
                        title: 'Followers',
                        value: _.get(this.superview, `channel._followers_count`),
                        delta: () => null,
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'post_count',
                        group_id: 'viewership',
                        title: 'Posts Created',
                        value: _.get(currentMetricWindow, `accumulation.posts.count`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `accumulation.posts.count`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    {
                        name: 'avg_likes',
                        group_id: 'engagement',
                        title: 'Avg. Likes',
                        value: _.get(currentMetricWindow, `aggregation.likes.avg`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.likes.avg`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, true, '---'),
                    },
                    {
                        name: 'peak_likes',
                        group_id: 'engagement',
                        title: 'Peak Likes',
                        value: _.get(currentMetricWindow, `aggregation.likes.peak.value`),
                        delta: (currentValue) => {
                            const previousValue = _.get(previousMetricWindow, `aggregation.likes.peak.value`)
                            return this.getDelta(currentValue, previousValue)
                        },
                        formatter: (value) => this.formatMetric(value, true, false, '---'),
                    },
                    // {
                    //     name: 'market_rate',
                    //     title: `Creator's Market Rate`,
                    //     value:
                    //         (_.get(this.superview, `video.${this.state.metricWindow}.aggregation.video_views.avg`) /
                    //             1000) *
                    //         5,
                    //     delta: () => null,
                    //     formatter: (value) => `$${numeral(value).format('0,0')}/post`,
                    //     tooltip: {
                    //         label: `Creator's Market Rate`,
                    //         description: `Rate for a text/image/video based post on Twitter.`,
                    //     },
                    // },
                    // {
                    //     name: 'viewership_stability',
                    //     title: 'Viewership Stability',
                    //     value: Math.min((1 + _.get(currentMetricWindow, 'accumulation.games.performance.non_main.median')), 1),
                    //     delta: () => null,
                    //     formatter: (value) => {
                    //         const formattedValue = value ? numeral(value).format('0%') : ''
                    //         if (value >= 0.9) return `${formattedValue} (High)`
                    //         if (value >= 0.7) return `${formattedValue} (Medium)`
                    //         if (value >= 0.5) return `${formattedValue} (Low)`
                    //         return `${formattedValue} (Very Low)`
                    //     },
                    //     tooltip: {
                    //         label: 'Viewership Stability',
                    //         description: `This indictates how much viewership a creator will lose when making content that is not related to their main niche.`,
                    //     },
                    // },
                ]

                fields.push({
                    name: 'avg_comments',
                    group_id: 'engagement',
                    title: 'Avg. Comments',
                    value: _.get(currentMetricWindow, `aggregation.comments.avg`),
                    delta: (currentValue) => {
                        const previousValue = _.get(previousMetricWindow, `aggregation.comments.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => this.formatMetric(value, true, false, '---'),
                })
                fields.push({
                    name: 'engagement_rate',
                    group_id: 'engagement',
                    title: 'Engagement Rate',
                    value:
                        _.get(currentMetricWindow, `aggregation.comments.avg`) /
                        _.get(currentMetricWindow, `aggregation.likes.avg`),
                    delta: (currentValue) => {
                        const previousValue =
                            _.get(currentMetricWindow, `aggregation.comments.avg`) /
                            _.get(currentMetricWindow, `aggregation.likes.avg`)
                        return this.getDelta(currentValue, previousValue)
                    },
                    formatter: (value) => (value > 0 ? numeral(value).format('0,0.0%') : '---'),
                })
            }

            fields.push({
                name: 'conversion_cpc',
                group_id: 'conversion',
                title: 'CPC Ranking',
                value: _.get(this.campaignMetrics, 'conversion.cpc'),
                delta: () => null,
                formatter: (value) => {
                    if (!_.isNumber(value)) return '---'

                    if (value >= 0.99) return `Top 1%`
                    if (value >= 0.95) return `Top 5%`
                    if (value >= 0.9) return `Top 10%`
                    if (value >= 0.75) return `Top 25%`
                    if (value >= 0.5) return `Top 50%`
                    if (value >= 0.25) return `Bottom 50%`
                    if (value >= 0.1) return `Bottom 25%`
                    if (value >= 0.5) return `Bottom 10%`
                    if (value >= 0.01) return `Bottom 5%`
                    if (value >= 0) return `Bottom 1%`
                    return '---'
                },
                tooltip: {
                    label: 'CPC (Cost Per Click) Ranking',
                    description: `The average cost you pay each time a viewer clicks on a sponsored post or link shared by an influencer. This metric helps assess the direct engagement cost from influencer-driven traffic. The number we display here tells you how the creator places on average for campaigns run on our platform.`,
                },
            })

            fields.push({
                name: 'conversion_cpm',
                group_id: 'conversion',
                title: 'CPM Ranking',
                value: _.get(this.campaignMetrics, 'conversion.cpm'),
                delta: () => null,
                formatter: (value) => {
                    if (!_.isNumber(value)) return '---'

                    if (value >= 0.99) return `Top 1%`
                    if (value >= 0.95) return `Top 5%`
                    if (value >= 0.9) return `Top 10%`
                    if (value >= 0.75) return `Top 25%`
                    if (value >= 0.5) return `Top 50%`
                    if (value >= 0.25) return `Bottom 50%`
                    if (value >= 0.1) return `Bottom 25%`
                    if (value >= 0.5) return `Bottom 10%`
                    if (value >= 0.01) return `Bottom 5%`
                    if (value >= 0) return `Bottom 1%`
                    return '---'
                },
                tooltip: {
                    label: 'CPM (Cost Per Mille) Ranking',
                    description: `Cost per thousand views of an influencer's post/video/stream containing your ad. It measures how much you pay for the influencer's reach to their audience, providing insight into exposure costs. The number we display here tells you how the creator places on average for campaigns run on our platform.`,
                },
            })

            // fields.push({
            //     name: 'conversion_ctr',
            //     group_id: 'conversion',
            //     title: 'CTR Ranking',
            //     value: _.get(this.campaignMetrics, 'conversion.ctr'),
            //     delta: () => null,
            //     formatter: (value) => {
            //         if (!_.isNumber(value)) return '---'

            //         if (value >= 0.99) return `Top 1%`
            //         if (value >= 0.95) return `Top 5%`
            //         if (value >= 0.9) return `Top 10%`
            //         if (value >= 0.75) return `Top 25%`
            //         if (value >= 0.50) return `Top 50%`
            //         if (value >= 0.25) return `Bottom 50%`
            //         if (value >= 0.10) return `Bottom 25%`
            //         if (value >= 0.5) return `Bottom 10%`
            //         if (value >= 0.01) return `Bottom 5%`
            //         if (value >= 0) return `Bottom 1%`
            //         return '---'
            //     },
            //     tooltip: {
            //         label: 'Click Through Rate Ranking',
            //         description: `The ratio of users who click on a specific link to the number of total users who view an influencer’s post. A crucial metric to evaluate the effectiveness of influencer content in driving audience actions.`,
            //     },
            // })

            // fields.push({
            //     name: 'outreach_all',
            //     group_id: 'outreach',
            //     title: 'All Outreach',
            //     value: _.get(this.campaignMetrics, 'outreachByType.all.response_rate'),
            //     delta: () => null,
            //     formatter: (value) => {
            //         if (value >= 0) return numeral(value / 100).format('0%')
            //         return '---'
            //     },
            //     tooltip: {
            //         label: 'Response Rate - All Outreach',
            //         description: ``,
            //     },
            // })

            fields.push({
                name: 'outreach_paid',
                group_id: 'outreach',
                title: 'Paid Outreach',
                value: _.get(this.campaignMetrics, 'outreachByType.game-sponsorship.response_rate'),
                delta: () => null,
                formatter: (value) => {
                    const formattedValue = numeral(value).format('0%')
                    if (value >= 0.5) return `${formattedValue} (High)`
                    if (value >= 0.25) return `${formattedValue} (Medium)`
                    if (value >= 0) return `${formattedValue} (Low)`
                    return '---'
                },
                tooltip: {
                    label: 'Paid Outreach Response Rate',
                    description: `Percentage of paid outreach invitations sent through our platform that this creator has responded to. Reflects engagement with financial incentives.`,
                },
            })

            fields.push({
                name: 'outreach_organic',
                group_id: 'outreach',
                title: 'Organic Outreach',
                value: _.get(this.campaignMetrics, 'outreachByType.key-distribution.response_rate'),
                delta: () => null,
                formatter: (value) => {
                    const formattedValue = numeral(value).format('0%')
                    if (value >= 0.5) return `${formattedValue} (High)`
                    if (value >= 0.25) return `${formattedValue} (Medium)`
                    if (value >= 0) return `${formattedValue} (Low)`
                    return '---'
                },
                tooltip: {
                    label: 'Organic Outreach Response Rate',
                    description: `Percentage of non-paid outreach invitations sent through our platform that this creator has responded to. Indicates their natural engagement without financial incentives.`,
                },
            })

            _.forEach(fields, (field) => {
                // eslint-disable-next-line no-param-reassign
                field.deltaValue = field.delta(field.value)
                // eslint-disable-next-line no-param-reassign
                field.formattedValue = field.formatter(field.value)
            })

            return {
                all: fields,
                byGroup: _.groupBy(fields, 'group_id'),
            }
        },

        mappedTasks() {
            if (!this.participant || !this.participant?.tasks?.length) return []

            const platformIdToTrueprice = {
                twitch: 'trueprice.hourly_rate',
                youtube: 'trueprice.rate_per_video',
                tiktok: 'trueprice.rate_per_video',
            }

            const trueprice = Math.max(_.get(this.superview, platformIdToTrueprice[this.selectedPlatformId]), 1)

            /* eslint-disable no-param-reassign */
            return _.reduce(
                this.participant.tasks,
                (results, task) => {
                    task.bid = {
                        quality: null,
                    }

                    task.commission = 0.25
                    task.adjusted_payout_amount = task.payout_amount / (1 - task.commission)

                    if (task.type === 'twitch-stream') {
                        task.bid.market_rate = 1.25

                        const hourlyRate =
                            task.payout_amount /
                            (task.payload.duration_seconds ? task.payload.duration_seconds / 3600 : 1)

                        const superviewWindow = this.superview.stream[this.state.metricWindow]
                        const avgKpi = superviewWindow.aggregation.viewers.avg

                        task.bid.typical_dropoff = 0.5

                        if (superviewWindow?.accumulation?.games?.avg_non_main_acv_performance) {
                            task.bid.typical_dropoff = superviewWindow.accumulation.games.avg_non_main_acv_performance
                        }

                        if (superviewWindow?.accumulation?.games?.performance?.non_main?.median) {
                            task.bid.typical_dropoff =
                                1 + superviewWindow.accumulation.games.performance.non_main.median
                        }

                        task.bid.expected_main_kpi = avgKpi * task.bid.typical_dropoff
                        task.bid.main_kpi_rate = hourlyRate / task.bid.expected_main_kpi

                        task.bid.market_bid =
                            task.bid.market_rate * task.bid.expected_main_kpi * (task.payload.duration_seconds / 3600)
                        task.bid.quality = task.payout_amount / task.bid.market_bid - 1

                        results.push(task)
                    }

                    if (task.type === 'youtube-video') {
                        task.bid.market_rate = 50
                        task.bid.quality = task.payout_amount / trueprice - 1

                        const superviewWindow = this.superview.video[this.state.metricWindow]
                        const avgKpi = superviewWindow.aggregation.views.avg

                        task.bid.typical_dropoff = 0.5

                        if (superviewWindow?.accumulation?.games?.performance?.non_main?.median) {
                            task.bid.typical_dropoff =
                                1 + superviewWindow.accumulation.games.performance.non_main.median
                        }

                        task.bid.expected_main_kpi = avgKpi * task.bid.typical_dropoff
                        task.bid.main_kpi_rate = task.payout_amount / (task.bid.expected_main_kpi / 1000)
                        task.bid.market_bid = trueprice

                        results.push(task)
                    }

                    if (task.type === 'tiktok-video') {
                        task.bid.market_rate = 5
                        task.bid.quality = task.payout_amount / trueprice - 1

                        const superviewWindow = this.superview.video[this.state.metricWindow]
                        const avgKpi = superviewWindow.aggregation.views.avg

                        // We don't assume any dropoff with TikTok for now
                        task.bid.typical_dropoff = 1

                        task.bid.expected_main_kpi = avgKpi * task.bid.typical_dropoff
                        task.bid.main_kpi_rate = task.payout_amount / (task.bid.expected_main_kpi / 1000)
                        task.bid.market_bid = trueprice

                        if (task?.payload?.video_count > 1) {
                            results.push({
                                ...task,
                                total_payout_amount: task.payout_amount * task.payload.video_count,
                            })
                        } else {
                            results.push({
                                ...task,
                            })
                        }
                    }

                    return results
                },
                [],
            )
            /* eslint-enable no-param-reassign */
        },

        mappedTargetingGroups() {
            const mappedGroups = _.reduce(
                this.platformTargetingGroups,
                (results, targetingGroup) => {
                    const gameIdsInTargetGroup = _.map(targetingGroup?.filters?.games?.values, 'id')

                    const inGroupGames = _.filter(
                        this.allGames,
                        (item) =>
                            gameIdsInTargetGroup.includes(item.game_id) &&
                            item.affinity >= targetingGroup?.filters?.affinity?.game?.min,
                    )

                    if (_.size(inGroupGames) > 0) {
                        results.push({
                            ...targetingGroup,
                            inGroup: {
                                games: _.slice(
                                    _.orderBy(
                                        inGroupGames,
                                        ['affinity', 'creator_accumulation.duration_seconds'],
                                        ['desc', 'desc'],
                                    ),
                                    this.state.games.inGroup.pageIndex * this.state.games.inGroup.itemsPerPage,
                                    this.state.games.inGroup.pageIndex + 1 * this.state.games.inGroup.itemsPerPage,
                                ),
                                games_count: _.size(inGroupGames),
                            },
                        })
                    }

                    return results
                },
                [],
            )

            return _.orderBy(mappedGroups, ['title'], ['asc'])
        },

        visibleRelatedCreators() {
            if (
                !this.state.relatedCreators ||
                !this.state.relatedCreators.records ||
                !this.state.relatedCreators.records.length
            )
                return []

            return _.slice(
                this.state.relatedCreators.records,
                (this.state.relatedCreators.paging.currentPage - 1) * this.state.relatedCreators.paging.perPage,
                this.state.relatedCreators.paging.currentPage * this.state.relatedCreators.paging.perPage,
            )
        },

        visiblePastParticipants() {
            if (
                !this.state.pastParticipants ||
                !this.state.pastParticipants.records ||
                !this.state.pastParticipants.records.length
            )
                return []
            return _.slice(
                this.state.pastParticipants.records,
                (this.state.pastParticipants.paging.currentPage - 1) * this.state.pastParticipants.paging.perPage,
                this.state.pastParticipants.paging.currentPage * this.state.pastParticipants.paging.perPage,
            )
        },

        allGamesFilteredBySearch() {
            if (this.gameSearch.text === '' || null) return this.allGames
            const filteredGames = _.filter(this.allGames, (item) => {
                const gameName = item.game_superview?._name
                const gameSearch = this.gameSearch.text.trim().toLowerCase()
                return gameName.includes(gameSearch)
            })
            return filteredGames
        },

        fullSocialLinks() {
            const validateUrl = (url) => {
                if (typeof url !== 'string') return url
                return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`
            }

            const uniqueLinks = _.cloneDeep(this.socialLinks).map((link) => ({
                ...link,
                link: validateUrl(link.link),
            }))

            if (this.superview?.normalized?.link) {
                const selfPlatformLink = _.find(
                    uniqueLinks,
                    (socialPointer) =>
                        socialPointer.tpid === this.selectedPlatformId &&
                        socialPointer.tuid === this.selectedPlatformUserId,
                )

                if (!selfPlatformLink) {
                    uniqueLinks.push({
                        tpid: this.selectedPlatformId,
                        tuid: this.selectedPlatformUserId,
                        link: validateUrl(this.superview.normalized.link),
                    })
                }
            }

            return _.orderBy(uniqueLinks, ['tpid'], ['asc'])
        },

        relatedNoteProfiles() {
            const related = []

            if (this.participant) {
                related.push({
                    entityType: this.platformId,
                    entityId: this.platformUserId,
                })
            }

            if (this.profileSelector?.others) {
                _.forEach(this.profileSelector.others, (profile) => {
                    if (profile.primary) {
                        related.push({
                            entityType: profile.primary.platform_id,
                            entityId: profile.primary.id,
                        })
                    }
                })
            }

            return related
        },
    },

    watch: {
        $route(to, from) {
            const hasSecondaryTabParam = !!to.query['profile.tabs.secondary']
            const isNewSecondaryTabParam =
                hasSecondaryTabParam && from.query['profile.tabs.secondary'] !== to.query['profile.tabs.secondary']

            if (hasSecondaryTabParam && isNewSecondaryTabParam) {
                this.state.tabs.secondary.activeTab = to.query['profile.tabs.secondary']
            }
        },

        selectedPlatformUserId(newPlatformUserId) {
            this.allGames = []
            this.allTags = []

            this.state.isMissing = false
            this.state.isFailed = false
            this.state.backfillAttempts = 0

            // Reset pagination
            this.state.games.inGroup.pageIndex = 0
            this.state.games.outGroup.pageIndex = 0

            const pagingReset = {
                currentPage: 1,
                totalPages: 1,
                totalRecords: 0,
            }

            _.assign(this.state.relatedCreators.paging, pagingReset)
            _.assign(this.state.pastParticipants.paging, pagingReset)

            this.state.relatedCreators.records = null
            this.state.pastParticipants.records = null

            this.superview = null
            this.superviewsByPlatformId = {}
            this.demographics = null
            this.contentAnalysis = null
            this.pastReportsByParticipantId = {}
            this.highestRelatedStrength = 1
            this.state.overlay.isActive = false
            this.contentTimeseries = null
            this.campaignMetrics = null
            this.socialLinks = []

            this.state.tabs.primary.activeTab = 'profile'
            this.state.tabs.secondary.activeTab = 'targeting'

            this.biography.text = null
            this.biography.animatedText = ''
            this.biography.cursor = null
            this.biography.features = {}

            if (this.biography.timeoutReference) clearTimeout(this.biography.timeoutReference)

            if (this.selectedPlatformId) {
                Promise.all([
                    this.getCreatorAffinity(this.selectedPlatformId, newPlatformUserId),
                    // this.getCreatorBiography(this.selectedPlatformId, newPlatformUserId),
                    this.getSocialLinks(this.selectedPlatformId, newPlatformUserId),
                ]).then(() => {
                    // this.clearCreatorProfileRequests(oldPlatformUserId)
                    this.syncContentType(this.selectedPlatformId)
                    this.getChartData(
                        this.selectedPlatformId,
                        this.selectedPlatformUserId,
                        this.state.contentType,
                        this.state.metricWindow,
                    )
                })
            }
        },

        platformUserId(newValue) {
            this.selectedPlatformUserId = newValue
        },

        platformId(newValue) {
            this.selectedPlatformId = newValue
        },

        selectedPlatformId(newValue) {
            if (this.state.metricWindow === 'day_1095' && !['twitch', 'youtube'].includes(newValue)) {
                this.state.metricWindow = 'day_365'
            }
        },

        targetingGroups() {
            if (this.selectedPlatformId && this.selectedPlatformUserId) {
                this.getCreatorAffinity(this.selectedPlatformId, this.selectedPlatformUserId)
            }
        },

        'state.tabs.primary.activeTab': {
            deep: true,
            handler(value) {
                if (value === 'contract' && this.isSfStaff) {
                    this.getParticipantStages()
                } else if (value === 'bids' || value === 'finance') {
                    this.getParticipantStages()
                }
            },
        },

        'state.tabs.secondary.activeTab': {
            deep: true,
            handler(value) {
                return this.handleSecondaryTabChange(value)
            },
        },

        'state.contentType': {
            deep: true,
            handler(value) {
                this.getChartData(this.selectedPlatformId, this.selectedPlatformUserId, value, this.state.metricWindow)
                this.setGamesList(this.selectedPlatformId)
                this.setHashtagsList()

                if (this.state.tabs.secondary.activeTab === 'targeting') {
                    this.state.tabs.secondary.activeTab = 'all_games'
                }
            },
        },

        'state.metricWindow': {
            deep: true,
            handler(value) {
                this.getChartData(this.selectedPlatformId, this.selectedPlatformUserId, this.state.contentType, value)
                this.setGamesList(this.selectedPlatformId)
                this.setHashtagsList()

                if (this.state.tabs.secondary.activeTab === 'targeting') {
                    this.state.tabs.secondary.activeTab = 'all_games'
                }
            },
        },

        participant: {
            deep: true,
            async handler(newParticipant, oldParticipant) {
                const isDifferentParticipant =
                    newParticipant && oldParticipant && newParticipant.id !== oldParticipant.id
                const isFirstParticipant = newParticipant && !oldParticipant

                // Only execute if we have a new participant, ignore object modifications
                if (isFirstParticipant || isDifferentParticipant) {
                    this.state.stages.is_loaded = false

                    await this.getParticipantStages()

                    if (!this.supercampaign || this.campaign.parent_id !== this.supercampaign.id) {
                        this.supercampaign = (
                            await supercampaignAPI.getSuperCampaign(this.$axios, this.campaign.parent_id, true, true)
                        ).value
                        await this.getLinkableUsers()
                    }

                    nextTick(() => {
                        this.state.stages.is_loaded = true
                    })
                }
            },
        },
    },

    created() {
        this.selectedPlatformUserId = this.platformUserId
        this.selectedPlatformId = this.platformId
        // this.getCreatorAffinity(this.platformId, this.platformUserId)
        // this.getCreatorBiography(this.platformId, this.platformUserId)

        // set participant.tasks if its null and we have items for bid page
        if (!this.participant?.tasks && this.items && this.items.length > 0) {
            // eslint-disable-next-line vue/no-mutating-props
            this.participant.tasks = this.items.flatMap((item) => item.tasks)
        }

        this.getPreviousCampaigns()

        if (this.participant) {
            if (!this.supercampaign || this.campaign.parent_id !== this.supercampaign.id) {
                supercampaignAPI
                    .getSuperCampaign(this.$axios, this.campaign.parent_id, true, true)
                    .then(({ value }) => {
                        this.supercampaign = value
                        this.getLinkableUsers()
                    })
            }
        }
    },

    // unmounted() {
    //     this.$router.replace({
    //         query: this.cleanQueryParameters(this.$route.query, 'profile.tabs.secondary')
    //     })
    // },

    methods: {
        setActivePlatform(newPlatformId, newPlatformUserId) {
            this.selectedPlatformId = newPlatformId
            this.selectedPlatformUserId = newPlatformUserId
            this.state.dropdowns.platformSelect.isOpen = false
        },

        cleanQueryParameters(queryParameters, path) {
            const strippedQueryParams = []

            // We want to reset any settings on the secondary tab's state
            const newQueryParams = _.reduce(
                queryParameters,
                (result, paramValue, paramKey) => {
                    if (!paramKey?.startsWith(path)) {
                        result[paramKey] = paramValue
                    } else {
                        strippedQueryParams.push([paramKey, paramValue])
                    }

                    return result
                },
                {},
            )

            return newQueryParams
        },

        handleTabClick(type, key) {
            if (this.$router.currentRoute.value.name === 'discovery') {
                const newQueryParams = this.cleanQueryParameters(this.$route.query, `profile.tabs.${type}.filters`)
                this.$router.replace({
                    query: {
                        ...newQueryParams,
                        [`profile.tabs.${type}`]: key,
                    },
                })
            }
            this.state.tabs[type].activeTab = key
        },

        handleSecondaryTabChange(value) {
            if (value === 'shared_audience' && !this.state.relatedCreators.records) {
                this.getRelatedCreators()
            }

            if (value === 'content_analysis' && !this.contentAnalysis) {
                this.getContentAnalysis()
            }

            if (value === 'demographics' && !this.demographics) {
                this.getDemographics()
            }

            if (value === 'audience_analysis' && !this.audienceAnalysis) {
                // this.getAudienceAnalysis()
            }

            if (value === 'past_participants' && !this.state.pastParticipants.records) {
                this.getPreviousCampaigns()
            }
        },

        syncContentType(platformId) {
            let contentType = 'video' // default content type

            if (platformId === 'twitch') {
                contentType = 'stream'
            } else if (platformId === 'youtube') {
                if (this.targetingGroups?.length) {
                    const firstTargetingGroup = _.find(
                        this.targetingGroups,
                        (item) => item.filters.platform === 'youtube',
                    )

                    if (firstTargetingGroup) {
                        contentType = firstTargetingGroup.filters.content_type || 'video'
                    }
                } else {
                    contentType = _.first(this.validContentTypes)
                }
            } else if (platformId === 'twitter') {
                contentType = 'post'
            } else if (platformId === 'instagram') {
                contentType = 'post'
            }

            this.state.contentType = contentType
            return this.state.contentType
        },

        getAudienceAnalysis() {
            this.audienceAnalysis = null
            this.state.audienceAnalysis.isLoaded = false
            this.state.audienceAnalysis.progress = 0

            const incrementProgress = () => {
                this.state.audienceAnalysis.progress += 1

                if (this.state.audienceAnalysis.progress < 6) {
                    setTimeout(incrementProgress, 10_000)
                }
            }

            incrementProgress()

            this.$axios
                .get(`/platforms/${this.selectedPlatformId}/${this.platformUserId}/analysis/audience`)
                .then(({ data }) => {
                    this.audienceAnalysis = data.payload

                    const flattenedInterests = []

                    if (data.payload) {
                        _.forEach(this.audienceAnalysis.interests, (item) => {
                            _.forEach(item.sub_interests, (subInterest) => {
                                subInterest.parent = _.omit(item, ['sub_interests'])
                                subInterest.score = (subInterest.strength_rating * 100) / 200 + 0.5
                                subInterest.affinity = getGradeAndColorsFromPercent(subInterest.score)
                                flattenedInterests.push(subInterest)
                            })
                        })

                        this.audienceAnalysis.flattenedInterests = _.orderBy(
                            flattenedInterests,
                            ['strength_rating'],
                            ['desc'],
                        )

                        _.forEach(this.audienceAnalysis.countries, (country) => {
                            if (country.name !== 'Other') country.key = this.getCodeFromCountry(country.name)
                            country.label = country.name
                            country.percent *= 100
                        })

                        _.forEach(this.audienceAnalysis.languages, (language) => {
                            language.key = this.getCodeFromLanguage(language.language)
                            language.label = language.language
                            language.percent *= 100
                        })

                        this.audienceAnalysis.age_ranges = _.orderBy(
                            this.audienceAnalysis.age_ranges,
                            ['range'],
                            ['asc'],
                        )
                        this.audienceAnalysis.age_ranges.unshift(this.audienceAnalysis.age_ranges.pop())
                    }

                    this.state.audienceAnalysis.isLoaded = true
                })
                .catch((error) => {
                    this.audienceAnalysis = false
                    this.state.audienceAnalysis.isLoaded = true
                    console.error(`Error fetching audience analysis`, error)
                })
        },

        animateBiographyText() {
            if (
                this.biography.text &&
                this.biography.cursor !== null &&
                this.biography.cursor < this.biography.text.length
            ) {
                this.biography.animatedText += this.biography.text.charAt(this.biography.cursor)
                this.biography.cursor += 1

                if (this.biography.text.charAt(this.biography.cursor) !== ' ') {
                    this.biography.timeoutReference = setTimeout(() => {
                        this.animateBiographyText()
                    }, this.biography.speed)
                } else {
                    this.animateBiographyText()
                }
            }
        },

        onSharedCreatorsPagingChange(updatedPaging) {
            _.assign(this.state.relatedCreators.paging, updatedPaging)
        },

        onPastParticipantsPagingChange(updatedPaging) {
            _.assign(this.state.pastParticipants.paging, updatedPaging)
        },

        getChartData(platformId, platformUserId, contentType = 'video', metricWindow = 'day_365') {
            this.state.timeseries.isLoaded = false
            this.contentTimeseries = null

            this.$axios
                .get(`/platforms/${platformId}/${platformUserId}/content/timeseries`, {
                    params: {
                        content_type: contentType,
                        metric_window: metricWindow,
                    },
                })
                .then(({ data }) => {
                    if (this.selectedPlatformId === 'twitch') {
                        this.state.timeseries.configuration = {
                            primary: {
                                key: 'avg_viewers',
                                label: 'Avg. Concurrent Viewers',
                            },
                            secondary: {
                                key: 'hours_streamed',
                                label: 'Hours Streamed',
                            },
                        }
                    } else if (this.selectedPlatformId === 'youtube') {
                        this.state.timeseries.configuration = {
                            primary: {
                                key: 'avg_views',
                                label: 'Avg. Views per Video',
                            },
                            secondary: {
                                key: 'videos_posted',
                                label: 'Videos Posted',
                            },
                        }
                    } else if (this.selectedPlatformId === 'tiktok') {
                        this.state.timeseries.configuration = {
                            primary: {
                                key: 'avg_views',
                                label: `Avg. Views per ${_.capitalize(contentType)}`,
                            },
                            secondary: {
                                key: 'videos_posted',
                                label: 'Videos Posted',
                            },
                        }
                    } else if (this.selectedPlatformId === 'twitter') {
                        this.state.timeseries.configuration = {
                            primary: {
                                key: 'avg_views',
                                label: `Avg. Views per ${_.capitalize(contentType)}`,
                            },
                            secondary: {
                                key: 'posts_created',
                                label: 'Posts Created',
                            },
                        }
                    } else if (this.selectedPlatformId === 'instagram') {
                        this.state.timeseries.configuration = {
                            primary: {
                                key: 'avg_views',
                                label: `Avg. Views per ${_.capitalize(contentType)}`,
                            },
                            secondary: {
                                key: 'posts_created',
                                label: 'Posts Created',
                            },
                        }
                    }

                    this.contentTimeseries = data.payload.timeseries
                    this.state.timeseries.isLoaded = true
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        setGamesList(platformId) {
            // We have all the game metadata we need from the superview lookup, but we need to key by ID not slug
            const gamesAccumulation = _.get(
                this.superview,
                `${this.state.contentType}.${this.state.metricWindow}.accumulation.games.lookup`,
            )

            this.allGames = _.reduce(
                gamesAccumulation,
                (results, creatorGameEntry, gamePlatformId) => {
                    let dynamicGamePlatformId = gamePlatformId

                    let gameSuperview = this.gameSuperviewsById[gamePlatformId]

                    if (gameSuperview?.parent_id) {
                        dynamicGamePlatformId = gameSuperview.parent_id
                        gameSuperview = this.gameSuperviewsById[dynamicGamePlatformId]
                    }

                    const affinityEntry = _.get(
                        this,
                        `discoverByContentType.${this.state.contentType}.game_affinity.${dynamicGamePlatformId}`,
                    )

                    let rawDifferencePercent
                    let audienceReception

                    if (platformId === 'twitch') {
                        const gameAvgSumDurationPerChannel = _.get(
                            gameSuperview,
                            `${this.state.contentType}.${this.state.metricWindow}.avg_sum_duration_per_channel`,
                        )

                        rawDifferencePercent =
                            creatorGameEntry.duration_seconds / (gameAvgSumDurationPerChannel || 60) - 1
                        audienceReception = creatorGameEntry.avg_acv_performance
                    } else if (platformId === 'youtube') {
                        const gameAvgVideosPerChannel = _.get(
                            gameSuperview,
                            `${this.state.contentType}.${this.state.metricWindow}.avg_videos_per_channel`,
                        )

                        rawDifferencePercent = creatorGameEntry.count / (gameAvgVideosPerChannel || 1) - 1
                        audienceReception = creatorGameEntry.avg_views_performance
                    }

                    results.push({
                        affinity: affinityEntry,
                        game_id: dynamicGamePlatformId,
                        game_superview: gameSuperview,
                        creator_accumulation: creatorGameEntry,
                        raw_difference_percent: rawDifferencePercent,
                        audience_reception: audienceReception,
                    })

                    return results
                },
                [],
            )

            this.allGames = _.orderBy(
                this.allGames,
                [(item) => item.affinity || '', 'creator_accumulation.duration_seconds'],
                ['desc', 'desc'],
            )

            return this.allGames
        },

        setHashtagsList() {
            // We have all the game metadata we need from the superview lookup, but we need to key by ID not slug
            const tagsAccumulation = _.get(
                this.superview,
                `${this.state.contentType}.${this.state.metricWindow}.accumulation.tags.lookup`,
            )

            this.allTags = _(tagsAccumulation)
                .map((item, tag) => {
                    return {
                        tag,
                        hashtag: `#${tag}`,
                        count: item.count ? item.count : item,
                    }
                })
                .orderBy(['count'], ['desc'])
                .value()

            return this.allTags
        },

        getCreatorBiography(platformId, platformUserId, isForceRefresh = false) {
            this.biography.text = null
            this.biography.animatedText = ''
            this.biography.cursor = 0
            this.biography.features = {}
            this.state.biography.isLoading = true

            this.$axios
                .get(`/platforms/${platformId}/${platformUserId}/biography`, {
                    params: {
                        refresh: isForceRefresh,
                    },
                })
                .then((response) => {
                    this.state.biography.isLoading = false

                    if (response.status === 200) {
                        this.biography.text = response.data.payload.biography
                        this.biography.features = _.omit(response.data.payload, ['bio'])

                        this.$nextTick(() => {
                            this.animateBiographyText()
                        })
                    }
                })
                .catch((error) => {
                    console.error(`Error fetching creator biography`, error)
                    this.state.biography.isLoading = false
                })
        },

        getSocialLinks(platformId, platformUserId) {
            this.$axios
                .get(`/platforms/${platformId}/${platformUserId}/socials`)
                .then(({ data }) => {
                    const uniqueLinks = _.uniqBy(data.payload, (item) => `${item.tpid}-${item.tpn}`)
                    this.socialLinks = uniqueLinks
                })
                .catch((error) => {
                    console.error(`Error fetching creator social links`, error)
                })
        },

        getCreatorAffinity(platformId, platformUserId) {
            this.superview = null
            this.discoverByContentType = null
            this.gameSuperviewsById = {}
            this.state.isFailed = false
            this.state.isMissing = false

            this.biography.text = null
            this.biography.animatedText = ''
            this.biography.cursor = 0
            this.biography.features = {}
            this.state.biography.isLoading = true

            return this.$axios
                .post(`/affinity/${platformId}`, {
                    channel_ids: [platformUserId],
                    targeting_groups: _.map(this.platformTargetingGroups, (targetingGroup) => ({
                        name: targetingGroup.name,
                        filters: transformFilters(targetingGroup.filters, platformId),
                    })),
                })
                .then(({ data }) => {
                    const firstCreator = _.first(data.payload.creators)

                    // This handles the case where we don't have a superview for the requested creator
                    if (firstCreator === false && this.state.backfillAttempts < 1) {
                        if (['youtube', 'tiktok', 'twitter', 'instagram'].includes(platformId)) {
                            this.postBackfillSuperview(platformId, platformUserId)
                        } else {
                            this.state.isMissing = true
                        }

                        return
                    }

                    this.superview = firstCreator.superview
                    this.superviewsByPlatformId[platformId] = firstCreator.superview
                    this.selectedPlatformId = platformId

                    this.discoverByContentType = firstCreator.discoverEntry
                    this.gameSuperviewsById = data.payload.gameSuperviewsById

                    this.campaignMetrics = data.payload.conversionByCreator[platformUserId]

                    this.creator = _.first(data.payload.pivots)

                    if (this.creator) {
                        this.creator.profiles = _.groupBy(this.creator?.profiles, 'platform_id')
                    }

                    const analysis = _.first(data.payload.analysis)

                    if (analysis) {
                        this.biography.text = analysis.biography
                        this.biography.features = _.omit(analysis, ['bio'])
                        this.state.biography.isLoading = false

                        this.$nextTick(() => {
                            this.animateBiographyText()
                        })
                    } else {
                        this.getCreatorBiography(platformId, platformUserId)
                    }

                    _.forEach(data.payload.targetingGroups, ({ codesByType }) => {
                        _.forEach(codesByType, (codes, type) => {
                            // We don't need these
                            if (type === 'games') return

                            _.forEach(codes, (code) => {
                                _.set(this.codesByType, `${type}.${code.id}`, code)
                            })
                        })
                    })

                    this.setGamesList(platformId)
                    this.setHashtagsList()

                    const isTargetedGamesTabShown =
                        this.mappedTargetingGroups.length > 0 &&
                        this.selectedGroup?.filters?.games?.values?.length > 0 &&
                        this.selectedGroup?.inGroup?.games &&
                        this.selectedGroup?.inGroup?.games?.length > 0

                    const isUrlParameterValidSecondaryTab =
                        this.$route.query['profile.tabs.secondary'] &&
                        !!_.find(this.visibleSecondaryTabs, (tab) => {
                            return tab.key === this.$route.query['profile.tabs.secondary']
                        })

                    // This will handle the case in which no targeting groups are set
                    if (isUrlParameterValidSecondaryTab) {
                        this.state.tabs.secondary.activeTab = this.$route.query['profile.tabs.secondary']
                    } else if (isTargetedGamesTabShown) {
                        this.state.tabs.secondary.activeTab = 'targeting'
                    } else {
                        const firstSecondaryTabOption = _.first(this.visibleSecondaryTabs)

                        if (firstSecondaryTabOption) {
                            this.state.tabs.secondary.activeTab = firstSecondaryTabOption?.key
                            // this.handleSecondaryTabChange(this.state.tabs.secondary.activeTab)
                        }
                    }

                    // Overwrite Logic
                    _.forEach(this.overwriteSchema, (group) => {
                        _.forEach(group.children, (field) => {
                            const valueComputed = _.get(this.superview, `${field.superviewKey}`)
                            const valueCustom = _.get(this.superview, `custom.${field.superviewKey}`)

                            let result = ''

                            if (valueComputed) result = valueComputed
                            if (valueCustom) result = valueCustom

                            this.overwriteData[field.name] = {
                                previous: result,
                                next: result,
                                schema: field,
                            }
                        })
                    })
                })
                .catch((error) => {
                    console.error(`Error fetching creator affinity`, error)
                    this.state.isFailed = true
                })
        },

        postBackfillSuperview(platformId, platformUserId, isRefresh = false) {
            this.state.isBackfilling = true
            this.state.backfillAttempts += 1

            this.$axios
                .get(`/platforms/${platformId}/${platformUserId}/superview/backfill`, {
                    params: {
                        // Determines if ETL should force refresh data for the creator
                        refresh: isRefresh,
                    },
                })
                .then(({ data }) => {
                    this.state.isBackfilling = false

                    if (data?.payload?.superview) {
                        this.state.isLoaded = false

                        Promise.all([
                            this.getCreatorAffinity(this.selectedPlatformId, platformUserId),
                            this.getSocialLinks(this.selectedPlatformId, platformUserId),
                            // this.getCreatorBiography(this.selectedPlatformId, platformUserId),
                        ]).then(() => {
                            // this.clearCreatorProfileRequests(oldPlatformUserId)
                            this.syncContentType(this.selectedPlatformId)
                            this.getChartData(
                                this.selectedPlatformId,
                                this.selectedPlatformUserId,
                                this.state.contentType,
                                this.state.metricWindow,
                            )
                        })
                    } else {
                        this.state.isMissing = true
                    }
                })
                .catch((error) => {
                    console.error(`Error backfilling superview`, error)
                })
        },

        getRelatedCreators() {
            this.state.relatedCreators.isLoading = true

            this.$axios
                .get(`/discovery/${this.selectedPlatformId}/${this.selectedPlatformUserId}/relations`)
                .then(({ data }) => {
                    this.state.relatedCreators.records = _.orderBy(data.payload.creators, ['relation.rank'], ['desc'])

                    // set totalPages from API response
                    // console.log(
                    //     data.payload.creators.length,
                    //     this.state.relatedCreators.paging.perPage,
                    //     Math.ceil(data.payload.creators.length / this.state.relatedCreators.paging.perPage),
                    // )
                    this.state.relatedCreators.paging.totalPages = Math.ceil(
                        data.payload.creators.length / this.state.relatedCreators.paging.perPage,
                    )
                    this.state.relatedCreators.paging.totalRecords = data.payload.creators.length

                    const highestCountRelation = _.maxBy(data.payload.creators, 'relation.rank')
                    this.highestRelatedStrength = highestCountRelation?.relation?.rank

                    this.state.relatedCreators.isLoading = false
                })
                .catch((error) => {
                    console.error(`Error fetching related creators`, error)
                })
        },

        getContentAnalysis() {
            this.state.contentAnalysis.isLoading = true

            this.$axios
                .get(`/platforms/${this.selectedPlatformId}/${this.selectedPlatformUserId}/analysis/content`)
                .then(({ data }) => {
                    this.contentAnalysis = data.payload
                    this.state.contentAnalysis.isLoading = false
                })
                .catch((error) => {
                    console.error(`Error fetching related creators`, error)
                })
        },

        getDemographics() {
            this.state.demographics.isLoading = true
            this.$axios
                .get(`/platforms/${this.selectedPlatformId}/${this.selectedPlatformUserId}/demographics`)
                .then(({ data }) => {
                    this.demographics = data.payload
                    this.state.demographics.isLoading = false
                })
                .catch((error) => {
                    console.error(`Error fetching related creators`, error)
                })
        },

        getPreviousCampaigns() {
            this.state.pastParticipants.isLoading = true

            this.$axios
                .get(`/platforms/${this.selectedPlatformId}/${this.selectedPlatformUserId}/participants`, {
                    params: {
                        relations: ['campaign', 'tasks', 'slots'],
                    },
                })
                .then(async ({ data }) => {
                    this.participants = _.orderBy(data.payload.participants, ['campaign.started_at'], ['desc'])

                    this.state.pastParticipants.records = _.filter(
                        data.payload.participants,
                        (participant) => participant?.campaign?.supercampaign?.payload?.is_demo !== true,
                    )

                    _.forEach(this.state.pastParticipants.records, (participant) => {
                        if (participant.campaign.type === 'steam-broadcast') {
                            // eslint-disable-next-line no-param-reassign
                            participant.total_payout = _.sumBy(participant.slots, 'payout_amount')
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            participant.total_payout = _.sumBy(participant.tasks, 'payout_amount')
                        }
                    })

                    await Promise.all(
                        _.map(this.state.pastParticipants.records, (participant) => {
                            if (!['delivery', 'preparation', 'contracting'].includes(participant.stage))
                                return undefined

                            const paging = { sortby: '', sortdir: '', perPage: 100, currentPage: 1 }

                            return reportsAPI
                                .getCampaignReport(
                                    this.$axios,
                                    participant.campaign.supercampaign.id,
                                    participant.campaign.id,
                                    participant.platform_id,
                                    paging,
                                    false,
                                    participant.platform_user_id,
                                )
                                .then((response) => {
                                    if (!response.success) return

                                    const report = response.value

                                    if (!report.items || report.items.length === 0) return

                                    const creator = report.items[0].data
                                    const summary = report.data.aggregates

                                    this.pastReportsByParticipantId[participant.id] = {
                                        rank_percentile: null,
                                        cpc: creator.summary.cpc,
                                        over_streamed: 1 + creator.summary.over_streamed,
                                        payout: creator.summary.cost,
                                    }

                                    if (summary.sum_creator_count && !Number.isNaN(creator.rank.cpc)) {
                                        const rankPercentile = Math.ceil(
                                            (1 - (creator.rank.cpc + 1) / summary.sum_creator_count) * 100,
                                        )
                                        this.pastReportsByParticipantId[participant.id].rank_percentile = rankPercentile
                                    }
                                })
                        }),
                    )

                    this.state.pastParticipants.paging.totalPages = Math.ceil(
                        this.state.pastParticipants.records.length / this.state.pastParticipants.paging.perPage,
                    )
                    this.state.pastParticipants.paging.totalRecords = this.state.pastParticipants.records.length

                    this.state.pastParticipants.isLoading = false
                })
        },

        getDelta(previousValue, currentValue) {
            // Don't forget this includes matching 0
            if (!currentValue || !previousValue) return null

            const delta = previousValue / currentValue - 1

            if (delta > 0.01) {
                return {
                    value: delta,
                    formattedValue: numeral(delta).format('+0,0.0%'),
                    absoluteValue: numeral(Math.abs(delta)).format('0,0%'),
                    textClass: 'text-emerald-500',
                    bgClass: 'bg-emerald-100',
                    icon: ['fas', 'arrow-up-right'],
                    currentValue,
                    previousValue,
                }
            }

            if (delta < -0.01) {
                return {
                    value: delta,
                    formattedValue: numeral(delta).format('-0,0.0%'),
                    absoluteValue: numeral(Math.abs(delta)).format('0,0%'),
                    textClass: 'text-rose-500',
                    bgClass: 'bg-rose-100',
                    icon: ['fas', 'arrow-down-right'],
                    currentValue,
                    previousValue,
                }
            }

            return null
        },

        formatMetric(metric, isAbbreviationEnabled, isDecimalEnabled, emptyLabel) {
            if (metric) {
                if (metric > 10) {
                    if (metric >= 1000000 && isAbbreviationEnabled === true) {
                        return numeral(metric).format('0,0.00a')
                    }

                    if (metric >= 10000 && isAbbreviationEnabled === true) {
                        return numeral(metric).format('0,0.0a')
                    }

                    if (metric >= 1000 && isAbbreviationEnabled === true) {
                        return numeral(metric).format('0,0.0a')
                    }

                    return numeral(metric).format('0,0')
                }
                if (isDecimalEnabled) {
                    return numeral(metric).format('0.0')
                }

                return numeral(metric).format('0')
            }

            return emptyLabel || ''
        },

        async getParticipantStages() {
            // Prevent re-fetching of stages if user navigates away and back
            if (!this.participant.stages) {
                await this.resetStages()
            }

            this.$nextTick(() => {
                this.state.stages.is_loaded = true
            })
        },

        async resetStages() {
            const stagesResp = await participantStagesAPI.getStages(this.$axios, this.participant.id)

            const currentStage = stagesResp.value.allStages.stage
            delete stagesResp.value.allStages.stage

            // eslint-disable-next-line vue/no-mutating-props
            this.participant.stages = stagesResp.value.allStages
            if (currentStage) {
                // eslint-disable-next-line vue/no-mutating-props
                this.participant.stage = currentStage
            }
        },

        onContractChange() {
            this.resetStages()
        },

        onBidChange() {
            this.resetStages()
        },

        handlePlatformIdTabClick(platformId, platformUserId) {
            this.selectedPlatformId = platformId
            this.selectedPlatformUserId = platformUserId
        },

        async impersonate() {
            this.$axios
                .get(`/oauth/impersonate/${this.selectedPlatformId}/${this.platformUserId}`)
                .then((response) => {
                    if (response.data && response.data.redirectUrl) {
                        window.open(response.data.redirectUrl, '_blank')
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error impersonating user ${this.platformUserId} on ${this.selectedPlatformId}`,
                        error,
                    )
                    this.$errorHandler(error, {
                        title: `Failed to impersonate user ${this.platformUserId} on ${this.selectedPlatformId}`,
                        message: 'Are you sure this creator has a valid Streamforge account?',
                    })
                })
        },

        async getLinkableUsers() {
            this.linkableUsers = null

            if (!this.isSfStaff || !this.supercampaign || !this.campaign) {
                return
            }

            const linkableUsersResponse = await participantAPI.getLinkableUsers(
                this.$axios,
                this.supercampaign.id,
                this.campaign.id,
                this.participant.id,
            )
            if (linkableUsersResponse.success) {
                this.linkableUsers = linkableUsersResponse.value.linkableUsers
            }
        },

        async onLinkUser(userId) {
            const linkResponse = await participantAPI.linkUserToParticipant(
                this.$axios,
                this.supercampaign.id,
                this.campaign.id,
                this.participant.id,
                userId,
            )
            if (linkResponse.success) {
                this.linkableUsers = linkResponse.value.linkableUsers
            }
        },

        async onUnlinkUser() {
            const linkResponse = await participantAPI.unlinkUserToParticipant(
                this.$axios,
                this.supercampaign.id,
                this.campaign.id,
                this.participant.id,
            )
            if (linkResponse.success) {
                this.linkableUsers = linkResponse.value.linkableUsers
            }
        },

        async onUpdateCreator(updates) {
            _.merge(this.superview, updates)
            this.state.overlay.isActive = false
        },
    },
}
</script>
