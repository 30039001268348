<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import voca from 'voca'
import numeral from 'numeral'
import { Download } from 'lucide-vue-next'
import { json2csvAsync } from 'json-2-csv'
import DiscoverTable from '@/partials/tables/DiscoverTable.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import Tooltip from '@/components/Tooltip.vue'
import TableCellPlatformLink from '@/partials/tables/creator-lists/TableCellPlatformLink.vue'
import { formatThousands, formatWholeNumberWithUnit } from '@/utils/Utils'

const store = useStore()

const props = defineProps({
    platformId: {
        type: String,
        required: true,
    },
    creators: {
        type: Array,
        required: true,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    paging: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: false,
        defaultValue: () => 'platform',
    },
})

const emit = defineEmits(['paging-change', 'enabled-column-change', 'export'])

const table = ref({
    enabledColumns: [],
    columns: [
        {
            id: 'metadata.name',
            key: 'metadata',
            header: {
                label: 'Creator',
                is_sortable: true,
                is_hideable: false,
                class: '',
            },
            row: {
                class: '',
                slot: 'creator',
                slotTransformer: (item) => ({
                    id: item.id,
                    name: item.metadata.name,
                    display_name: item.metadata.display_name,
                    logo: `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${props.platformId}/avatars/small/${item.id}`,
                }),
            },
            is_sticky: true,
            is_exportable: true,
            exportTransformer: (item) => ({
                ID: _.get(item, 'id'),
                Name: _.get(item, 'metadata.name'),
            }),
            enabled: () => ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId),
        },
        {
            id: 'summary.avg_viewers',
            key: `summary.avg_viewers`,
            header: {
                label: `Avg Viewers`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Average Concurrent Viewers`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The average number of viewers you would expect to see in this creator's stream at any point within the aggregation window.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => (field ? formatThousands(field) : '-'),
            },
            is_exportable: true,
            exportTransformer: (item) => ({
                'Avg Viewers': _.get(item, 'summary.avg_viewers', 0),
            }),
            enabled: () => ['twitch'].includes(props.platformId),
        },
        {
            id: 'summary.peak_viewers',
            key: `summary.peak_viewers.value`,
            header: {
                label: `Peak Viewers`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Peak Concurrent Viewers`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The maximum number of viewers that were in this creator's stream at any point within the aggregation window.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            exportTransformer: (item) => ({
                'Peak Viewers': _.get(item, 'summary.peak_viewers.value', 0),
            }),
            enabled: () => {
                return ['twitch'].includes(props.platformId)
            },
        },
        {
            id: 'summary.avg_chatters',
            key: `summary.avg_chatters`,
            header: {
                label: `Avg Chatters`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Average Concurrent Chatters`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The average number of chatters you would expect to see in this creator's stream at any point within the aggregation window.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            exportTransformer: (item) => ({
                'AVG Chatters': _.get(item, 'summary.avg_chatters', 0),
            }),
            enabled: () => {
                return ['twitch'].includes(props.platformId)
            },
        },
        {
            id: 'summary.content_count',
            key: `summary.content_count`,
            header: {
                label: `Content Count`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Content count`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The total number of pieces of content that were matched.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => true,
            exportTransformer: (item) => ({
                'Content Count': formatWholeNumberWithUnit(_.get(item, 'summary.content_count', 0)),
            }),
        },
        {
            id: 'summary.sum_duration_seconds',
            key: `summary.sum_duration_seconds`,
            header: {
                label: `Hours streamed`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Hours streamed`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The total number of hours streamed during the aggregation window.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field / 60 / 60)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return ['twitch'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                'Hours Streamed': formatWholeNumberWithUnit(_.get(item, 'summary.sum_duration_seconds', 0) / 60 / 60),
            }),
        },
        {
            id: 'metadata.followers',
            key: `metadata.followers`,
            header: {
                label: `Followers`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Followers`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The channel's current number of followers.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return true
            },
            exportTransformer: (item) => ({
                Followers: _.get(item, 'metadata.followers', 0),
            }),
        },
        {
            id: 'summary.avg_views',
            key: `summary.avg_views`,
            header: {
                label: `AVG Views`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Average views`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The average number of views per video during the period.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return ['youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                'Avg Views': parseInt(_.get(item, 'summary.avg_views', 0), 10),
            }),
        },
        {
            id: 'summary.sum_views',
            key: `summary.sum_views`,
            header: {
                label: `Total Views`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Total views`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The total number of views for videos during the period.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                'Total Views': _.get(item, 'summary.sum_views', 0),
            }),
        },
        {
            id: 'summary.peak_views',
            key: `summary.peak_views.value`,
            header: {
                label: `Peak Views`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Peak views`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The peak number of views for videos during the period.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return formatWholeNumberWithUnit(field)
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return ['youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                'Peak Views': _.get(item, 'summary.peak_views.value', 0),
            }),
        },
        {
            id: 'summary.sum_minutes_watched',
            key: `summary.sum_minutes_watched`,
            header: {
                label: `Total Hours Watched`,
                sub_label: ``,
                is_sortable: true,
                tooltip: {
                    title: `Total Hours Watched`,
                    sub_title: ``,
                    content: [
                        {
                            text: `The total number of hours of streaming content watched by the audience.`,
                        },
                    ],
                },
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return numeral(field ? field / 60 : 0).format('0,0')
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => {
                return ['twitch'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                'Total Hours Watched': _.get(item, 'summary.sum_minutes_watched', 0)
                    ? _.get(item, 'summary.sum_minutes_watched', 0) / 60
                    : 0,
            }),
        },
        {
            id: 'language',
            key: 'metadata.language',
            header: {
                label: 'Language',
                is_sortable: false,
            },
            row: {
                class: '',
                slot: 'language',
            },
            is_exportable: true,
            enabled: () => {
                return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                Language: _.get(item, 'metadata.language', ''),
            }),
        },
        {
            id: 'country',
            key: 'metadata.country',
            header: {
                label: 'Country',
                is_sortable: false,
            },
            row: {
                class: '',
                slot: 'country',
            },
            is_exportable: true,
            enabled: () => {
                return ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].includes(props.platformId)
            },
            exportTransformer: (item) => ({
                Country: _.get(item, 'metadata.country', ''),
            }),
        },
        {
            id: 'summary.unique_clicks',
            key: 'summary.unique_clicks',
            header: {
                label: 'Unique Clicks',
                is_sortable: true,
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return `${numeral(field).format('0,0')}`
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => props.type === 'campaign',
            exportTransformer: (item) => ({
                'Unique Clicks': numeral(_.get(item, 'summary.unique_clicks', 0)).format('0,0'),
            }),
        },
        {
            id: 'summary.cpc',
            key: 'summary.cpc',
            header: {
                label: 'CPC',
                is_sortable: true,
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return `$${numeral(field).format('0,0.00')}`
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => props.type === 'campaign',
            exportTransformer: (item) => ({
                CPC: `$${numeral(_.get(item, 'summary.cpc', 0)).format('0,0.00')}`,
            }),
        },
        {
            id: 'summary.cpm',
            key: 'summary.cpm',
            header: {
                label: 'CPM',
                is_sortable: true,
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return `$${numeral(field).format('0,0.00')}`
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => props.type === 'campaign',
            exportTransformer: (item) => ({
                CPM: `$${numeral(_.get(item, 'summary.cpm', 0)).format('0,0.00')}`,
            }),
        },
        {
            id: 'summary.ctr',
            key: 'summary.ctr',
            header: {
                label: 'CTR',
                is_sortable: true,
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return numeral(field).format('0.0%')
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => props.type === 'campaign',
            exportTransformer: (item) => ({
                CTR: numeral(_.get(item, 'summary.ctr', 0)).format('0.0%'),
            }),
        },
        {
            id: 'summary.cost',
            key: 'summary.cost',
            header: {
                label: 'Total Cost',
                is_sortable: true,
            },
            row: {
                class: '',
                formatter: ({ field }) => {
                    if (field) {
                        return `$${numeral(field).format('0,0.00')}`
                    }
                    return '-'
                },
            },
            is_exportable: true,
            enabled: () => props.type === 'campaign',
            exportTransformer: (item) => ({
                'Total Cost': `$${numeral(_.get(item, 'summary.cost', 0)).format('0,0.00')}`,
            }),
        },

        ...['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'].map((platformId) => ({
            id: `link_${platformId}`,
            key: `metadata.socials.${platformId}`,
            header: {
                label: `${voca.titleCase(platformId)} Link`,
                is_sortable: false,
            },
            row: {
                class: '',
                slot: 'platform-link',
            },
            is_exportable: true,
            enabled: () => true,
            exportTransformer: (item) => ({
                [`${voca.capitalize(platformId)} Link`]: _.get(item, `metadata.socials.${platformId}.link`, ''),
            }),
        })),
    ],
})

const platformColumns = computed(() => table.value.columns.filter((column) => column.enabled()))

function setEnabledColumnDefaults() {
    const preferences = store.getters.preferences
    if (preferences?.tables?.platformReport && preferences.tables.platformReport[props.platformId]?.columns) {
        table.value.enabledColumns = preferences.tables.platformReport[props.platformId].columns
    } else {
        table.value.enabledColumns = table.value.columns.filter((col) => col.enabled()).map((col) => col.id)
    }
}

function onEnabledColumnChange(newEnabledColumns) {
    table.value.enabledColumns = newEnabledColumns
    store.commit('preferences:set', {
        key: `tables.platformReport.${props.platformId}.columns`,
        value: table.value.enabledColumns,
    })
    emit('enabled-column-change', newEnabledColumns)
}

async function generateCSV(data) {
    try {
        const mappedFields = data.map((creator) => {
            const result = {}
            platformColumns.value.forEach((column) => {
                if (!column.is_exportable) return

                if (_.has(column, 'exportTransformer')) {
                    const exportValues = column.exportTransformer(creator)

                    _.forEach(exportValues, (exportValue, key) => {
                        if ([null, undefined].includes(exportValue)) {
                            exportValues[key] = ''
                        }
                    })

                    _.assign(result, exportValues)
                } else {
                    const value = _.get(creator, column.key)
                    result[column.header.label] = ![null, undefined].includes(value) ? value : ''
                }
            })
            return result
        })

        const csvResults = await json2csvAsync(mappedFields)
        const blob = new Blob([csvResults], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'PlatformWideReport.csv'
        link.click()
        URL.revokeObjectURL(link.href)
    } catch (error) {
        console.error('Error exporting creators', error)
        // Show error notification
    }
}

const copyToClipboard = async (event, textToCopy) => {
    try {
        await navigator.clipboard.writeText(textToCopy)

        const overlayMessage = event.target

        if (overlayMessage) {
            overlayMessage.classList.add('opacity-100')

            setTimeout(() => {
                overlayMessage.classList.remove('opacity-100')
            }, 2500)
        } else {
            console.error('Overlay message element not found.')
        }
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}

// Initialize enabled columns
setEnabledColumnDefaults()

defineExpose({
    generateCSV,
})
</script>

<template>
    <div class="mt-4">
        <DiscoverTable
            :paging="paging"
            :creators="creators"
            :is-loading="isLoading"
            :columns="platformColumns"
            :enabled-columns="table.enabledColumns"
            :platform-id="platformId"
            :is-selectable="true"
            id-prop="id"
            superview-path="partialview"
            @enabled-column-change="onEnabledColumnChange"
            @paging-change="
                (item) => {
                    console.log(item)
                    $emit('paging-change', item)
                }
            "
            @filter-change="(item) => $emit('paging-change', item)"
            @export-creators="generateCSV(props.creators)"
        >
            <template #header-toolbar>
                <div>
                    <button
                        class="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-input bg-background px-4 py-1.5 text-sm font-medium ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                        @click="$emit('export')"
                    >
                        <Download class="mr-2 h-4 w-4" />Export
                    </button>
                </div>
            </template>

            <template #creator="{ item }">
                <div class="relative flex w-full min-w-64 items-center justify-between">
                    <router-link
                        :to="{
                            path: $route.path,
                            query: {
                                ...$route.query,
                                modal: 'creator',
                                platform_id: platformId,
                                platform_user_id: item.partialview._id,
                            },
                        }"
                        class="align-center flex"
                    >
                        <NamePlate
                            class="my-auto"
                            :logo="item.partialview.normalized.avatar_url"
                            :name="item.partialview.normalized.name"
                            :display-name="item.partialview.normalized.display_name"
                            :platform-id="platformId"
                            :platform-user-id="item.partialview._id"
                            :is-drawer-enabled="false"
                        />
                        <div
                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                        >
                            <span class="my-auto flex uppercase">
                                <font-awesome-icon :icon="['fas', 'rectangle-list']" class="my-auto mr-1 h-4 w-4" />
                                <span class="my-auto leading-none">Open</span>
                            </span>
                        </div>
                    </router-link>
                </div>
            </template>

            <template #language="{ field }">
                <Tooltip v-if="field" class="inline-block">
                    <template #trigger>
                        <div class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold">
                            <span class="uppercase">{{ field }}</span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">{{ store.getters.getLanguageFromCode(field) }}</div>
                    </template>
                </Tooltip>
            </template>

            <template #country="{ field }">
                <Tooltip v-if="field" class="inline-block">
                    <template #trigger>
                        <div class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold">
                            <span class="uppercase">{{ field }}</span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">{{ store.getters.getCountryFromCode(field) }}</div>
                    </template>
                </Tooltip>
            </template>

            <template #email="{ field }">
                <div
                    v-if="field"
                    class="group relative flex cursor-pointer items-center rounded bg-slate-100 px-2 py-1.5 text-xs font-medium hover:bg-slate-200"
                    @click="copyToClipboard($event, field)"
                >
                    <span class="leading-none text-slate-500 group-hover:text-slate-700">{{ field }}</span>
                    <font-awesome-icon
                        :icon="['fas', 'copy']"
                        class="mb-0.5 ml-1 h-3 w-3 text-slate-500 group-hover:text-slate-700"
                    />
                    <span
                        class="overlay-message absolute inset-0 flex items-center justify-center rounded bg-black bg-opacity-80 text-white opacity-0 transition-opacity duration-300"
                    >
                        <span class="pointer-events-none">Copied to clipboard!</span>
                    </span>
                </div>
            </template>

            <template #platform-link="{ field }">
                <TableCellPlatformLink
                    v-if="field"
                    :platform-id="field?.platform_id"
                    :platform-user-id="field?.platform_user_id"
                    :platform-user-name="field?.platform_user_name"
                    :link="field?.link"
                />
            </template>
        </DiscoverTable>
    </div>
</template>
