<template>
    <div class="flex w-full items-center justify-between space-x-2 overflow-auto p-1" :class="className">
        <div class="flex space-x-2">
            <DataTableViewOptions :table="table" />
            <!-- Additional filterable columns -->
            <template v-for="column in filterableColumns" :key="`filter-${column.value}`">
                <DataTableFacetedFilter
                    :class="{
                        'pointer-events-none opacity-50': !column?.options?.length,
                    }"
                    :column="getColumn(column.value)"
                    :title="column.label"
                    :options="column.options"
                ></DataTableFacetedFilter>
            </template>
            <Button
                v-if="isFiltered"
                aria-label="Reset filters"
                variant="ghost"
                class="h-8 px-2 lg:px-3"
                @click="resetFilters"
            >
                Reset
                <X class="ml-2 size-4" aria-hidden="true" />
            </Button>
        </div>

        <div class="flex items-center space-x-2">
            <!-- Search input for specific column -->
            <div class="relative w-full max-w-sm">
                <Input
                    id="search"
                    type="text"
                    :placeholder="placeholder"
                    :model-value="searchFilterValue"
                    @input="handleTextFilterChange"
                    class="h-9 pl-9"
                />
                <span class="absolute inset-y-0 start-0 flex items-center justify-center px-2">
                    <Search class="size-5 text-muted-foreground" />
                </span>
            </div>

            <slot name="toolbar-end" />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Search, X } from 'lucide-vue-next'
import Input from '@/components/ui/input/Input.vue'
import Button from '@/components/ui/button/Button.vue'
import DataTableFacetedFilter from '@/components/ui/data-table/DataTableFacetedFilter.vue'
import DataTableViewOptions from '@/components/ui/data-table/DataTableViewOptions.vue'

const props = defineProps({
    table: Object,
    filterFields: Array,
    placeholder: String,
    className: String,
    columnToFilter: String,
    isServerSide: Boolean,
})

const emit = defineEmits(['update:input'])

const searchableColumns = computed(() => {
    if (!props.filterFields) return []
    return props.filterFields.filter((field) => !field.options)
})

const filterableColumns = computed(() => {
    if (!props.filterFields) return []
    return props.filterFields.filter((field) => field.options)
})

const isFiltered = computed(() => props.table.getState().columnFilters.length > 0)

const searchFilterValue = computed(() => props.table.getColumn(props.columnToFilter)?.getFilterValue() || '')

function getColumn(value) {
    return props.table.getColumn(value ? String(value) : '')
}

function resetFilters() {
    props.table.resetColumnFilters()
}

function handleTextFilterChange(event) {
    if (!props.isServerSide) {
        getColumn(props.columnToFilter)?.setFilterValue(event.target.value)
    }
    emit('update:input', event.target.value)
}
</script>
