<script setup>
import { computed } from 'vue'
import { DialogClose, DialogContent, DialogOverlay, DialogPortal, useForwardPropsEmits } from 'radix-vue'
import { X } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps({
    forceMount: { type: Boolean, required: false },
    trapFocus: { type: Boolean, required: false },
    disableOutsidePointerEvents: { type: Boolean, required: false },
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
    noMaxWidth: { type: Boolean, required: false },
    noPadding: { type: Boolean, required: false },
})
const emits = defineEmits([
    'escapeKeyDown',
    'pointerDownOutside',
    'focusOutside',
    'interactOutside',
    'openAutoFocus',
    'closeAutoFocus',
])

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
    <DialogPortal>
        <DialogOverlay
            class="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/80"
        >
            <DialogContent
                :class="
                    cn(
                        'relative z-50 my-8 grid w-full max-w-lg gap-4 border border-border bg-background p-6 shadow-lg duration-200 sm:rounded-lg md:w-full',
                        props.class,
                    )
                "
                v-bind="forwarded"
                @pointer-down-outside="
                    (event) => {
                        const target = event?.detail?.originalEvent?.target
                        if (!target) return

                        if (target.closest('.productfruits--container')) {
                            event.preventDefault()
                            return
                        }

                        if (
                            event.detail.originalEvent.offsetX > target.clientWidth ||
                            event.detail.originalEvent.offsetY > target.clientHeight
                        ) {
                            event.preventDefault()
                        }
                    }
                "
            >
                <slot />

                <DialogClose
                    class="absolute right-2.5 top-2.5 rounded-md bg-white/5 p-1.5 text-slate-500 transition-colors duration-200 hover:bg-white/10 hover:text-slate-700"
                >
                    <X class="h-4 w-4" />
                    <span class="sr-only">Close</span>
                </DialogClose>
            </DialogContent>
        </DialogOverlay>
    </DialogPortal>
</template>
