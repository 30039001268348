<script setup>
import _ from 'lodash'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { axios } from '@/utils/axios'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import ReportFilters from '@/partials/options/ReportFilters.vue'
import ReportTable from '@/partials/tables/ReportTable.vue'
import CreatorAggregationChart from '@/partials/charts/CreatorAggregationChart.vue'
import ContentTable from '@/partials/tables/ContentTable.vue'

const platformId = ref('youtube')
const searchText = ref('')
const activeTab = ref('overview')

const store = useStore()

const creatorsComponent = ref(null)
const contentComponent = ref(null)

const report = ref(null)
const creators = ref([])
const isLoading = ref(false)
const defaultPaging = {
    perPage: 25,
    currentPage: 1,
    totalPages: 1,
    sort: [
        {
            field: 'summary.content_count',
            direction: 'desc',
            source: 'default',
        },
    ],
    filters: [],
    totalRecords: 0,
}
const paging = ref(_.cloneDeep(defaultPaging))
const filters = ref({})

watch(platformId, () => {
    filters.value.game = null
})

async function generateReport() {
    store.dispatch('CANCEL_PENDING_REQUESTS_BY_REGEX', '^/platforms/[^/]+/report$')
    store.dispatch('CANCEL_PENDING_REQUESTS_BY_REGEX', '^/platforms/[^/]+/reports/aggregation$')

    try {
        const response = await axios.post(`/platforms/${platformId.value}/report`, {
            platformId: platformId.value,
            paging: paging.value,
            options: {
                contentType: filters.value.contentType,
                tags: _.map(filters.value.tags, 'id'),
                gameId: filters.value.game?.id,
                gameName: filters.value.game?.name,
                startAt: filters.value.startAt,
                endAt: filters.value.endAt,
            },
            filters: searchText.value ? [{ id: 'name', value: searchText.value }] : [],
            isExport: false,
        })

        const reportData = response.data.payload
        report.value = reportData
        creators.value = reportData.items
        paging.value = reportData.paging
    } catch (error) {
        console.error('Error fetching report:', error)
        // Handle error (e.g., show notification)
    } finally {
        isLoading.value = false
    }
}

async function exportCreatorsReport() {
    try {
        const response = await axios.post(`/platforms/${platformId.value}/report`, {
            platformId: platformId.value,
            paging: {
                ...paging.value,
                perPage: 10_000,
                currentPage: 1,
            },
            options: {
                contentType: filters.value.contentType,
                tags: _.map(filters.value.tags, 'id'),
                gameId: filters.value.game?.id,
                gameName: filters.value.game?.name,
                startAt: filters.value.startAt,
                endAt: filters.value.endAt,
            },
            filters: searchText.value ? [{ id: 'name', value: searchText.value }] : [],
            isExport: true,
        })

        const reportData = response.data.payload
        creatorsComponent.value.generateCSV(reportData.items)
    } catch (error) {
        console.error('Error fetching report:', error)
        // Handle error (e.g., show notification)
    } finally {
        isLoading.value = false
    }
}

async function onFilterChange(newFilters) {
    isLoading.value = true
    report.value = null
    creators.value = []

    filters.value = newFilters
    paging.value = _.cloneDeep(defaultPaging)
    await generateReport()
}

async function onPagingChange(newPaging) {
    paging.value = { ...paging.value, ...newPaging }
    await generateReport()
}
</script>

<template>
    <div class="mx-auto w-full max-w-9xl p-3 md:p-4 lg:p-8">
        <ReportFilters
            :is-loading="isLoading"
            :platform-id="platformId"
            @filter-change="onFilterChange"
            @platform-change="(item) => (platformId = item)"
        />

        <template v-if="report">
            <Tabs v-model="activeTab" class="mt-4 w-full">
                <TabsList class="inline-flex grid grid-cols-3 bg-slate-200">
                    <TabsTrigger value="overview">Overview</TabsTrigger>
                    <TabsTrigger value="creators">Creators</TabsTrigger>
                    <TabsTrigger value="content">Content</TabsTrigger>
                </TabsList>

                <TabsContent value="overview">
                    <div>
                        <CreatorAggregationChart
                            :aggregation-data="report?.summary?.demographics"
                            :timeseries-data="report?.summary?.timeseries"
                            :summary="report?.summary?.aggregates"
                            :is-loading="!report"
                        />
                    </div>
                </TabsContent>

                <TabsContent value="creators">
                    <div>
                        <ReportTable
                            ref="creatorsComponent"
                            :platform-id="platformId"
                            :creators="creators"
                            :is-loading="isLoading"
                            :paging="paging"
                            @paging-change="onPagingChange"
                            @export="exportCreatorsReport"
                        >
                        </ReportTable>
                    </div>
                </TabsContent>

                <TabsContent value="content">
                    <div class="rounded bg-white px-2 py-3">
                        <ContentTable
                            ref="contentComponent"
                            :report-id="report?._id"
                            :platform-id="platformId"
                            :content-type="filters.contentType"
                            :day-range="'day_30'"
                        />
                    </div>
                </TabsContent>
            </Tabs>
        </template>
    </div>
</template>
