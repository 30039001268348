<!--
    Customized version of DataTable component w/ ShadCn aimed at being resuable and flexible within the app.
    See: https://www.shadcn-vue.com/docs/components/data-table
 -->

<script setup>
import {
    FlexRender,
    getCoreRowModel,
    useVueTable,
    getSortedRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
} from '@tanstack/vue-table'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { valueUpdater } from '@/lib/utils'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import DataTableToolbar from './DataTableToolbar.vue'
import DataTablePagination from './DataTablePagination.vue'
import _ from 'lodash'
const emit = defineEmits(['update:pagination', 'update:sorting', 'update:text-filter', 'update:filtering'])

const props = defineProps({
    columns: {
        type: Array,
        default: () => [],
    },
    data: {
        type: Array,
        default: () => [],
    },
    toolbar: {
        type: Object,
        required: false,
        validator(toolbar) {
            if (typeof toolbar.placeholder !== 'string') {
                return false
            }
            if (typeof toolbar.columnToFilter !== 'string') {
                return false
            }
            return true
        },
    },
    tableHeaderCellClass: {
        type: String,
        required: false,
    },
    tableCellClass: {
        type: String,
        required: false,
    },
    tableRowClass: {
        type: String,
        required: false,
    },
    isLoaded: {
        type: Boolean,
        default: () => true,
    },
    isServerSide: {
        type: Boolean,
        default: () => true,
    },
    rowCount: {
        type: Number,
        default: () => -1,
    },
    pagination: {
        type: Object,
        default: () => ({
            pageIndex: 0,
            pageSize: 10,
        }),
    },
    sorting: {
        type: Object,
        default: () => [
            {
                id: 0,
                desc: true,
            },
        ],
    },
    filterFields: {
        type: Array,
        default: () => [],
    },
    filtering: {
        type: Array,
        default: () => [],
    },
    preferencesId: {
        type: String,
        required: false,
    },
})

const expanded = ref({})
const store = useStore()

const getPreferences = () => {
    if (props.preferencesId) {
        return _.get(store, `state.preferences.tables.${props.preferencesId}`)
    }

    return null
}

const savePerferences = (propType, change) => {
    if (!propType || !props.preferencesId) {
        return
    }
    if (propType === 'sorting') {
        savePeference(
            'sorting',
            _.map(change, (column) => {
                return { id: column.id, desc: column.desc }
            }),
        )
    } else if (propType === 'paging') {
        savePeference('paging.pageSize', change.pageSize)
    } else if (propType === 'columns') {
        let columnOptions = {}
        _.forEach(change, (column) => {
            columnOptions[column.id] = column.visible
        })

        savePeference(
            'columns',
            _.map(change, (column) => {
                return {
                    id: column.id,
                    visible: column.visible,
                }
            }),
        )
    }
}

const savePeference = (key, value) => {
    store.commit('preferences:set', {
        key: `tables.${props.preferencesId}.${key}`,
        value: value,
    })
}

const table = useVueTable({
    manualPagination: props.isServerSide,
    manualSorting: props.isServerSide,
    manualFiltering: props.isServerSide,
    get rowCount() {
        return props.rowCount
    },
    get data() {
        return props.data
    },
    get columns() {
        return props.columns
    },
    get pageCount() {
        return Math.ceil(props.rowCount / props.pagination.pageSize) ?? -1
    },
    getSubRows: (row, rowIndex) => row.chapters,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: (updater) => {
        let newFiltering

        if (typeof updater === 'function') {
            newFiltering = updater(props.filtering)
        } else {
            newFiltering = updater
        }

        table.toggleAllRowsExpanded(false)

        emit('update:filtering', newFiltering)
    },
    // getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
        get sorting() {
            return props.sorting
        },
        get columnFilters() {
            return props.filtering
        },
        get pagination() {
            return props.pagination
        },
        get expanded() {
            return expanded.value
        },
    },
    onPaginationChange: (updater) => {
        let newPagination

        if (typeof updater === 'function') {
            newPagination = updater({
                pageIndex: props.pagination.pageIndex,
                pageSize: props.pagination.pageSize,
            })
        } else {
            newPagination = updater
        }

        table.toggleAllRowsExpanded(false)
        savePerferences('paging', newPagination)
        emit('update:pagination', newPagination)
    },
    onSortingChange: (updater) => {
        let newSorting

        if (typeof updater === 'function') {
            newSorting = updater(props.sorting)
        } else {
            newSorting = updater
        }
        savePerferences('sorting', newSorting)
        emit('update:sorting', newSorting)
    },
    onExpandedChange: (updaterOrValue) => valueUpdater(updaterOrValue, expanded),
    initialState: {
        columnVisibility: (() => {
            const defaultVisible = getPreferences()?.columns ? getPreferences().columns : []
            let visibilityState = {}
            _.forEach(defaultVisible, (col) => {
                visibilityState[col.id] = col.visible
            })
            return visibilityState
        })(),
    },
})

const columnVisibility = computed(() => {
    return _.map(table.getAllColumns(), (col) => {
        return {
            id: col.id,
            visible: col.getIsVisible(),
        }
    })
})

watch(
    () => columnVisibility,
    () => {
        savePerferences('columns', columnVisibility.value)
    },
    { deep: true },
)
</script>

<template>
    <div>
        <div v-if="props.toolbar" class="pb-3">
            <DataTableToolbar
                :table="table"
                :placeholder="props.toolbar.placeholder"
                :columnToFilter="props.toolbar.columnToFilter"
                :is-server-side="true"
                :filter-fields="props.filterFields"
                @update:input="(value) => emit('update:text-filter', value)"
            >
                <template #toolbar-end>
                    <slot name="toolbar-actions"></slot>
                </template>
            </DataTableToolbar>
        </div>
        <div>
            <Table>
                <TableHeader>
                    <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                        <TableHead
                            v-for="header in headerGroup.headers"
                            :key="header.id"
                            :class="[header.column.columnDef.class, props.tableHeaderCellClass]"
                        >
                            <FlexRender
                                v-if="!header.isPlaceholder"
                                :render="header.column.columnDef.header"
                                :props="header.getContext()"
                            />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <template v-if="isLoaded === false">
                        <slot name="loading-skeleton"></slot>
                    </template>
                    <template v-else>
                        <template v-if="table.getRowModel().rows?.length">
                            <template v-for="row in table.getRowModel().rows" :key="row.id">
                                <TableRow
                                    :data-state="row.getIsSelected() ? 'selected' : undefined"
                                    @click="row.getCanExpand() ? row.toggleExpanded() : null"
                                    :class="[
                                        props.tableRowClass,
                                        {
                                            'cursor-pointer': row.getCanExpand(),
                                        },
                                    ]"
                                >
                                    <TableCell
                                        v-for="cell in row.getVisibleCells()"
                                        :key="cell.id"
                                        v-bind="{
                                            'data-subrow': !$lodash.isUndefined(row.parentId),
                                        }"
                                        :class="props.tableCellClass"
                                    >
                                        <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                    </TableCell>
                                </TableRow>
                            </template>
                        </template>
                        <template v-else>
                            <TableRow>
                                <TableCell :colspan="columns.length" class="h-24 text-center text-sm"
                                    >No results to show</TableCell
                                >
                            </TableRow>
                        </template>
                    </template>
                </TableBody>
            </Table>
        </div>
        <div class="flex justify-center py-3">
            <DataTablePagination :table="table" />
        </div>
    </div>
</template>
